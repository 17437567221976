import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { UseUser } from '../../services/user/UserContext';
import Box from '@mui/material/Box';
import MuiDrawer from "@mui/material/Drawer";
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import { useIsAppPage } from '../../App';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LanguageSwitcher from '../navbar/LanguageSwitcher';
import { getLanguagePrefix } from '../translation/LanguageUrlHandler';

import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import AppsIcon from '@mui/icons-material/Apps';
import KeyIcon from '@mui/icons-material/Key';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const drawerWidth = 240;

function openedMixin(theme) {
  return {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: "hidden"
  };
}

function closedMixin(theme) {
  return {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`
    }
  };
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

export default function AppDrawer() {
  const { t } = useTranslation();
  const location = useLocation();
  const languagePrefix = getLanguagePrefix(location.pathname);

  const isAppPage = useIsAppPage();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const desktopMode = useMediaQuery(theme.breakpoints.up('sm'));
  const { currentUser } = UseUser();

  const pagesGroup1 = [
    { text: 'Home', route: `${languagePrefix}/`, icon: <HomeIcon /> },
    currentUser?.company && { text: 'Company', route: `${languagePrefix}/company`, icon: <BusinessIcon /> },
    { text: 'Products', route: `${languagePrefix}/products`, icon: <AppsIcon /> },
    { text: 'Licenses', route: `${languagePrefix}/licenses`, icon: <KeyIcon /> },
    currentUser?.role == "Root" && { text: 'Root', route: `${languagePrefix}/root`, icon: <AdminPanelSettingsIcon /> },
  ].filter(Boolean);

  React.useEffect(() => {
    setMobileOpen(desktopMode);
  }, [desktopMode]);

  const handleDrawerOpen = () => {
    setMobileOpen(true);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  if (!isAppPage || currentUser == null) {
    return;
  }

  const drawerContent = (
    <div>
      <Box sx={{ overflow: 'auto', overflowX: 'Hidden' }}>
        <List>
          {pagesGroup1.map((page) => (
            <ListItem key={page.text} disablePadding sx={{ display: "block" }}>
              <NavLink to={page.route} style={{ textDecoration: 'none', color: 'inherit' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: mobileOpen ? "initial" : "center",
                    px: 2.5,
                    '& .MuiListItemText-primary': {
                      color: theme.palette.secondary.main,
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: theme.palette.secondary.main,
                      minWidth: 0,
                      mr: mobileOpen ? 3 : "auto",
                      justifyContent: "center"
                    }}
                  >
                    {page.icon}
                  </ListItemIcon>
                  {mobileOpen && <ListItemText primary={page.text} />}
                </ListItemButton>
              </NavLink>
            </ListItem>
          ))}
        </List>

        <Box sx={{ display: 'flex', justifyContent: 'left', ml: 2 }}>
          {!desktopMode && mobileOpen && <LanguageSwitcher />}
        </Box>
      </Box>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        variant="permanent"
        open={mobileOpen}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', },
        }}
      >
        <Toolbar />
        <DrawerHeader>
          {mobileOpen ? (
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen}>
              {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          )}
        </DrawerHeader>
        <Divider />
        {drawerContent}
      </Drawer>

      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        <Toolbar />
        {drawerContent}
      </Drawer>
    </Box>
  );
}
