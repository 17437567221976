const awsConfig = {
    cognito: {
        userPoolId: 'eu-west-3_Xh1583ju3',
        userPoolClientId: '60umlk1bneeghequptf9bfmpjd',
        region: 'eu-west-3'
    },
    api: {
        invokeUrl: 'https://api.ingeloop.com/v1',
    }
};
export default awsConfig;