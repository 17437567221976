import * as React from 'react';
import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogContent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { UseUser } from '../../../services/user/UserContext';
import { stringAvatar } from '../../../utilities/AvatarUtilities';
import AppDrawer from '../../drawer/AppDrawer';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLanguagePrefix } from '../../translation/LanguageUrlHandler';
import { InitiateForgotPassword } from '../../../services/aws/cognito/Authentication';

export default function AppPage() {
    const theme = useTheme();
    const navigate = useNavigate();
    const { currentUser } = UseUser();
    const [changePasswordLoading, setChangePasswordLoading] = useState(false);
    const [changePasswordError, setChangePasswordError] = useState("");
    const location = useLocation();
    const languagePrefix = getLanguagePrefix(location.pathname);

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const handleChangePassword = async () => {
        setChangePasswordLoading(true);
        setChangePasswordError("");

        try {
            await InitiateForgotPassword(currentUser.email);
            navigate(`${languagePrefix}/auth/verify-account`, { state: { email: currentUser.email, otpLength: 6, mode: 'LostPassword' } });
        } catch (error) {
            setChangePasswordError(error.message);
        }

        setChangePasswordLoading(false);
    };

    if (currentUser == null) {
        return;
    }

    return (
        <Box sx={{ display: "flex" }}>
            <AppDrawer></AppDrawer>
            <Box sx={{ overflow: "auto", bgcolor: '#f2f5f9', minHeight: 'calc(100vh - 64px)', padding: 2 }}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <Typography variant="h4" sx={{ mb: 2 }}>Profile</Typography>
                    <Box >
                        <Paper sx={{ padding: 2, marginBottom: 2, borderRadius: 2 }}>

                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                <Avatar
                                    {...stringAvatar(currentUser.fullName)}
                                    sx={{
                                        width: 42,
                                        height: 42,
                                        fontSize: "1rem",
                                        ...stringAvatar(currentUser.fullName).sx
                                    }}
                                />
                                <Typography variant="h4" sx={{ ml: 2 }}>
                                    {currentUser.firstName + " " + currentUser.lastName}
                                </Typography>
                            </Box>

                            <Box sx={{ borderBottom: '1px solid', borderColor: 'divider', marginBottom: 2 }}></Box>

                            <Box sx={{ width: { md: 'fit-content' }, mb: 1 }}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="body2" color="text.secondary" gutterBottom sx={{ marginBottom: 1 }}>
                                            First name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="body1" gutterBottom sx={{ marginBottom: 1 }}>
                                            {currentUser.firstName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="body2" color="text.secondary" gutterBottom sx={{ marginBottom: 1 }}>
                                            Last name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="body1" gutterBottom sx={{ marginBottom: 1 }}>
                                            {currentUser.lastName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="body2" color="text.secondary" gutterBottom sx={{ marginBottom: 1 }}>
                                            Email
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="body1" gutterBottom sx={{ marginBottom: 1 }}>
                                            {currentUser.email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            {changePasswordError && (
                                <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                                    {changePasswordError}
                                </Typography>
                            )}
                            {changePasswordLoading && (
                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
                                    <CircularProgress size={24} />
                                </Box>
                            )}

                            <Button variant="contained" color="primary" onClick={handleChangePassword}>
                                Change Password
                            </Button>
                        </Paper>
                    </Box>
                </Box>
            </Box>
        </Box >
    );
}