import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ReCAPTCHA from "react-google-recaptcha";
import { ReactComponent as IngeloopLogo } from '../../images/logo/Logo.svg';
import Copyright from './Copyright'
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { RegisterUser } from '../../../services/aws/cognito/Authentication';
import {
    validateFirstName, validateLastName, validateEmail,
    validatePassword, validateConfirmPassword
} from '../../../utilities/InputCheckUtilities';
import { UseAxiosInstance } from '../../../services/aws/api/AxiosInstance';
import googleConfig from '../../../services/google/googleConfig'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLanguagePrefix } from '../../translation/LanguageUrlHandler';

export default function Register() {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const axiosInstance = UseAxiosInstance();
    const recaptchaRef = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [backendError, setBackendError] = React.useState("");
    const location = useLocation();
    const languagePrefix = getLanguagePrefix(location.pathname);

    const [errors, setErrors] = React.useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: ""
    });

    const handleInputChange = (field, value) => {
        let fieldError = '';

        switch (field) {
            case 'firstName':
                fieldError = validateFirstName(value);
                break;
            case 'lastName':
                fieldError = validateLastName(value);
                break;
            case 'email':
                fieldError = validateEmail(value);
                break;
            case 'password':
                fieldError = validatePassword(value);
                break;
            case 'confirmPassword':
                const passwordValue = document.getElementById("password").value;
                fieldError = validateConfirmPassword(value, passwordValue);
                break;
            default:
                break;
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            [field]: fieldError
        }));
    }

    const validate = (data) => {
        let tempErrors = {
            firstName: validateFirstName(data.firstName),
            lastName: validateLastName(data.lastName),
            email: validateEmail(data.email),
            password: validatePassword(data.password),
            confirmPassword: validateConfirmPassword(data.confirmPassword, data.password),
        };

        setErrors(tempErrors);

        return Object.values(tempErrors).every(error => !error); // return true if no errors
    };

    const handleSubmit = async (event) => {
        if (loading) return;

        setLoading(true);
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        var formData = {
            firstName: data.get('firstName'),
            lastName: data.get('lastName'),
            email: data.get('email'),
            password: data.get('password'),
            confirmPassword: data.get('confirmPassword'),
        };

        if (!validate(formData)) {
            setLoading(false);
            return;
        }

        try {

            //Verify Recaptcha
            const recaptchaToken = recaptchaRef.current.getValue();
            if (recaptchaToken === '') {
                setBackendError(t("Forms.Validation.RecaptchaConfirm"));
                return;
            }
            else {
                try {
                    await axiosInstance.post('/recaptcha', { token: recaptchaToken });
                } catch (error) {
                    setBackendError(t("Forms.Validation.RecaptchaVerificationFailed"));
                    return;
                }
            }

            //Create Cognito User
            const cognitoData = await RegisterUser(formData.firstName, formData.lastName, formData.email, formData.password);

            //Create DynamoDB User
            const userData = { username: cognitoData.userSub, firstName: formData.firstName, lastName: formData.lastName, email: formData.email };
            await axiosInstance.post("/users", userData);

            //Navigate to account verification page
            navigate(`${languagePrefix}/auth/verify-account`, { state: { email: formData.email, otpLength: 6, mode: 'Register' } });
        } catch (err) {
            setBackendError(err.message);
        } finally {
            recaptchaRef?.current?.reset();
            setLoading(false);
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>

                    <IngeloopLogo width={120}></IngeloopLogo>

                    <Typography component="h1" variant="h4" color="secondary.main" sx={{ mt: 2 }}>
                        {t("Forms.Register")}
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label={t("Forms.FirstName")}
                                    autoFocus
                                    onChange={(e) => handleInputChange('firstName', e.target.value)}
                                    error={Boolean(errors.firstName)}
                                    helperText={errors.firstName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label={t("Forms.LastName")}
                                    name="lastName"
                                    autoComplete="family-name"
                                    onChange={(e) => handleInputChange('lastName', e.target.value)}
                                    error={Boolean(errors.lastName)}
                                    helperText={errors.lastName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label={t("Forms.Email")}
                                    name="email"
                                    autoComplete="email"
                                    onChange={(e) => handleInputChange('email', e.target.value)}
                                    error={Boolean(errors.email)}
                                    helperText={errors.email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label={t("Forms.Password")}
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    onChange={(e) => handleInputChange('password', e.target.value)}
                                    error={Boolean(errors.password)}
                                    helperText={errors.password}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="confirmPassword"
                                    label={t("Forms.ConfirmPassword")}
                                    type="password"
                                    id="confirmPassword"
                                    autoComplete="new-password"
                                    onChange={(e) => handleInputChange('confirmPassword', e.target.value)}
                                    error={Boolean(errors.confirmPassword)}
                                    helperText={errors.confirmPassword}
                                />
                            </Grid>
                        </Grid>

                        <Box display="flex" justifyContent="center" mt={1.5}>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={googleConfig.recaptcha.siteKey} />
                        </Box>

                        {backendError && (
                            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                                {backendError}
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={loading}
                            sx={{ mt: 1.5, mb: 2 }}
                        >
                            {t("Forms.Submit")}
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={36}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}

                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href={`${languagePrefix}/auth/login`} variant="body2">
                                    {t("Forms.AlreadyHaveAnAccount")}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </ThemeProvider>
    );
}