// LanguageSwitcher.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useLocation } from 'react-router-dom';
import { getLanguagePath } from '../translation/LanguageUrlHandler';

const LanguageSwitcher = ({darkMode = false }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const changeLanguage = (event) => {
    const targetLanguage = event.target.value;
    const newPath = getLanguagePath(location.pathname, targetLanguage);
    navigate(newPath);
  };

  return (
    <Select
      value={i18n.language}
      onChange={changeLanguage}
      displayEmpty
      sx={{
        height: '42px',
        color: darkMode ? 'white' : 'black',
        '& .MuiSelect-icon': {
          color: darkMode ? 'white' : 'black',
        },
      }}
    >
      <MenuItem value="en">English</MenuItem>
      <MenuItem value="fr">Français</MenuItem>
    </Select>
  );
};

export default LanguageSwitcher;
