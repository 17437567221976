import React, { useEffect, useState, useCallback, useMemo } from 'react';

const DynamicCodeBackground = ({ codeLines }) => {
  const [code, setCode] = useState('');
  const [charIndex, setCharIndex] = useState(0);
  const [lineIndex, setLineIndex] = useState(0);
  const [fontSize, setFontSize] = useState('1em');

  const maxLines = 20;

  const updateFontSize = useCallback(() => {
    setFontSize(window.innerWidth < 600 ? '0.5em' : '1em');
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateFontSize);
    updateFontSize();

    return () => window.removeEventListener('resize', updateFontSize);
  }, [updateFontSize]);

  useEffect(() => {
    const timer = setInterval(() => {
      let nextCharIndex = charIndex;
      let nextLineIndex = lineIndex;
      let newCode = code;

      if (charIndex < codeLines[lineIndex].length) {
        newCode += codeLines[lineIndex][charIndex];
        nextCharIndex++;
      } else {
        newCode += '\n';
        nextCharIndex = 0;
        nextLineIndex = (lineIndex + 1) % codeLines.length;

        let lines = newCode.split('\n');
        if (lines.length > maxLines) {
          lines.shift();
          newCode = lines.join('\n');
        }
      }

      setCode(newCode);
      setCharIndex(nextCharIndex);
      setLineIndex(nextLineIndex);
    }, 10);

    return () => clearInterval(timer);
  }, [charIndex, lineIndex, codeLines]);

  return <pre style={{ lineHeight: '1.5em', height: '30em', color: '#e3e7e8', userSelect: 'none', overflow: 'hidden', fontSize }}>{code}</pre>;
};

export default DynamicCodeBackground;
