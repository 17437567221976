
export const addUserAction = (action, content) => {

    const userActionsJSON = localStorage.getItem("userActions");
    let userActions = userActionsJSON ? JSON.parse(userActionsJSON) : {};

    //Cancel if key already exists
    if (userActions[action]) return; 

    userActions[action] = content;
    localStorage.setItem("userActions", JSON.stringify(userActions));
}

export const executeUserActions = () => {
    const userActions = localStorage.getItem("userActions");
    if (!userActions) return;

    const actions = JSON.parse(userActions);
}