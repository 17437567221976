import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

const urlLanguageRegex = /^\/(en|fr)/;

export const matchUrlLanguage = (path) => {
    return path.match(urlLanguageRegex);
};

export const getLanguagePath = (path, targetLanguage) => {

    const currentLanguageMatch = matchUrlLanguage(path);
    let newPath;
    if (currentLanguageMatch) {
        const targetLangPath = targetLanguage == "en" ? "" : `/${targetLanguage}`;
        newPath = path.replace(urlLanguageRegex, targetLangPath);
    } else {
        newPath = `/${targetLanguage}${path}`;
    }

    return newPath;
};

export const isPathHomePage = (path) => {
    const currentLanguageMatch = matchUrlLanguage(path);
    if (currentLanguageMatch) {
        const restingUrl = path.replace(currentLanguageMatch[0], "").replace("/", "");
        return restingUrl.length == 0;
    }
    else {
        return path == "/"
    }
}

export const getLanguagePrefix = (path) => {
    const currentLanguageMatch = matchUrlLanguage(path);
    if (currentLanguageMatch) {
        return currentLanguageMatch[0];
    }
    else {
        return "";
    }
}
