import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { UseUser } from '../../../services/user/UserContext';
import { stringAvatar } from '../../../utilities/AvatarUtilities';
import Avatar from '@mui/material/Avatar';
import AppDrawer from '../../drawer/AppDrawer';
import { UseAxiosInstance } from '../../../services/aws/api/AxiosInstance';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';
import AppsIcon from '@mui/icons-material/Apps';
import KeyIcon from '@mui/icons-material/Key';
import { BarChart, Bar, Pie, PieChart, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import fetchScopeLicenses from '../../../utilities/LicensesUtilities';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLanguagePrefix } from '../../translation/LanguageUrlHandler';

export default function AppPage() {
    const theme = useTheme();
    const { currentUser } = UseUser();
    const axiosInstance = UseAxiosInstance(currentUser);
    const navigate = useNavigate();
    const location = useLocation();
    const languagePrefix = getLanguagePrefix(location.pathname);

    const [licenses, setLicenses] = useState([]);
    const [licensesLoading, setLicensesLoading] = useState(false);

    const [products, setProducts] = useState([]);
    const [productsLoading, setProductsLoading] = useState(false);

    useEffect(() => {
        setLicensesLoading(true);
        setProductsLoading(true);
        if (!currentUser || !axiosInstance) return;

        const fetchLicenses = async () => {
            try {
                const licenses = await fetchScopeLicenses(axiosInstance, currentUser);
                setLicenses(licenses);
                setLicensesLoading(false);
            } catch (err) {
                console.error("Failed to fetch licenses:", err);
                setLicensesLoading(false);
            }
        };

        const fetchProducts = async () => {
            try {
                const response = await axiosInstance.get(`/products`);
                const products = JSON.parse(response.data);
                setProducts(products);
                setProductsLoading(false);
            } catch (err) {
                console.error("Failed to fetch products:", err);
                setProductsLoading(false);
            }
        };

        fetchLicenses();
        fetchProducts();
    }, [currentUser, axiosInstance]);

    const sections = [
        { name: "Profile", description: "View and edit your personal details", icon: <AccountCircleIcon fontSize="large" />, path: `${languagePrefix}/userprofile` },
        currentUser?.company && { name: "Company", description: "Manage company users", icon: <BusinessIcon fontSize="large" />, path: `${languagePrefix}/company` },
        { name: "Products", description: "Browse through available products", icon: <AppsIcon fontSize="large" />, path: `${languagePrefix}/products` },
        { name: "Licenses", description: "Check your licenses and permissions", icon: <KeyIcon fontSize="large" />, path: `${languagePrefix}/licenses` },
    ].filter(Boolean);

    //Active/Expired licenses Pie
    const activeLicenses = licenses.filter(license => license.status === "Active").length;
    const expiredLicenses = licenses.filter(license => license.status === "Expired").length;
    const data = [
        { name: 'Active', value: activeLicenses },
        { name: 'Expired', value: expiredLicenses }
    ];
    const pieColors = ['#A8E6CF', '#FF8A80'];

    //Licenses usage Bar
    const computeLicenseUsage = licenses => {
        return licenses.map(license => {
            if (license.isCompanyLicense) {
                return {
                    name: license.product,
                    usage: 100
                };
            } else {
                let usagePercentage = license.maxUses > 0 ? (license.userLicenses.length / license.maxUses) * 100 : 100;
                return {
                    name: license.product,
                    usage: usagePercentage,
                    remaining: 100 - usagePercentage,
                };
            }
        });
    };
    const usageData = computeLicenseUsage(licenses);

    if (currentUser == null) {
        return null;
    }

    return (
        <Box sx={{ display: "flex" }}>
            <AppDrawer></AppDrawer>
            <Box sx={{ overflow: "auto", bgcolor: '#f2f5f9', minHeight: 'calc(100vh - 64px)', padding: 2 }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 2,
                        borderRadius: 1,
                        marginBottom: 2
                    }}>
                    <Typography variant="h3" color='text.primary'>Welcome {currentUser.fullName}</Typography>
                </Box>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <Typography variant="h4" color='text.primary' sx={{ mb: 2 }}>Manage</Typography>
                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: 2 }}>
                        {sections.map((section) => (
                            <Card key={section.name} sx={{ mb: 2, transition: '0.3s', '&:hover': { transform: 'scale(1.05)' } }} onClick={() => navigate(section.path)}>
                                <CardActionArea sx={{ textAlign: 'center', padding: 3 }}>
                                    <Box sx={{ color: theme.palette.secondary.main }}>
                                        {section.icon}
                                    </Box>
                                    <CardContent>
                                        <Typography variant="h5" sx={{ mb: 2 }}>
                                            {section.name}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {section.description}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        ))}
                    </Box>
                </Box>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <Typography variant="h4" color='text.primary' sx={{ mb: 2 }}>Dashboard</Typography>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: {
                            xs: '1fr',
                            md: 'repeat(auto-fill, minmax(300px, 1fr))'
                        },
                        gap: {xs: 0, md: 2} }}>
                        {/* Licenses statuses */}
                        <Card sx={{ mb: 2, transition: '0.3s', '&:hover': { transform: 'scale(1.01)' } }} onClick={() => navigate(`${languagePrefix}/licenses`)}>
                            <CardActionArea sx={{ textAlign: 'center', padding: 3 }}>
                                <Typography variant="h5" color='text.primary' sx={{ mb: 2 }}>
                                    Licenses statuses
                                </Typography>
                                {licensesLoading ?
                                    <CircularProgress size={24} />
                                    :
                                    <>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                            <PieChart width={200} height={200}>
                                                <Pie
                                                    data={licenses.length === 0 ? [{ name: 'No licenses', value: 1 }] : data}
                                                    cx={100}
                                                    cy={100}
                                                    innerRadius={40}
                                                    outerRadius={80}
                                                    fill="#8884d8"
                                                    dataKey="value">
                                                    {
                                                        licenses.length === 0 ?
                                                            <Cell key="none" fill="#f2f2f2"></Cell>
                                                            :
                                                            data.map((entry, index) => <Cell key={index} fill={pieColors[index % pieColors.length]} />)
                                                    }
                                                </Pie>
                                                <Tooltip />
                                            </PieChart>
                                        </Box>
                                        <CardContent>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                                {data.map((entry, index) => (
                                                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                        <Box sx={{ width: 16, height: 16, borderRadius: '50%', bgcolor: pieColors[index % pieColors.length], mr: 1 }}></Box>
                                                        <Typography variant="body1" color='text.primary'>
                                                            {entry.name}: {entry.value}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </CardContent>
                                    </>
                                }
                            </CardActionArea>
                        </Card>

                        {/* Licenses usage */}
                        {currentUser.role !== 'User' &&
                            <Card sx={{ gridColumn: 'span 2', mb: 2, transition: '0.3s', '&:hover': { transform: 'scale(1.01)' } }} onClick={() => navigate(`${languagePrefix}/licenses`)}>
                                <CardActionArea sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 3 }}>
                                    <Typography variant="h5" color='text.primary' sx={{ mb: 2 }}>
                                        Licenses usage
                                    </Typography>
                                    {licensesLoading ?
                                        <CircularProgress size={24} />
                                        :
                                        <ResponsiveContainer width="95%" height={300}>
                                            <BarChart
                                                data={usageData}
                                                margin={{ top: 5, right: 5, left: -25, bottom: 5 }}
                                                barSize={30}
                                            >
                                                <CartesianGrid strokeDasharray="5 5" verticalFill={['#f5f5f5', '#fff']} fillOpacity={0.2} />
                                                <XAxis dataKey="name" stroke={['#1D699D', '#fff']} tick={{ fontSize: 12, fill: theme.palette.text.secondary}}/>
                                                <YAxis domain={[0, 100]} stroke={['#f5f5f5', '#fff']}  tick={{ fontSize: 12, fill: theme.palette.text.secondary}}/>
                                                <Tooltip cursor={{fill: 'transparent'}}/>
                                                <Bar dataKey="usage" fill="#1D699D" background={{ fill: '#f2f2f2' }} />
                                            </BarChart>
                                            </ResponsiveContainer>
                                    }
                                </CardActionArea>
                            </Card>
                        }

                        {/* Products access */}
                        <Card
                            sx={{ mb: 2, transition: '0.3s', '&:hover': { transform: 'scale(1.01)' }, display: 'flex', flexDirection: 'column' }} onClick={() => navigate(`${languagePrefix}/products`)}>
                            <CardActionArea sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', padding: 3 }} >
                                <Typography variant="h5" color='text.primary' sx={{ mb: 2 }}>
                                    Products
                                </Typography>
                                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                    {productsLoading ?
                                        <CircularProgress size={24} />
                                        :
                                        <Typography variant="h1" color='text.primary' sx={{ mb: 2, fontWeight: 600 }} >
                                            {products.length}
                                        </Typography>
                                    }
                                </Box>
                            </CardActionArea>
                        </Card>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}