import * as React from 'react';
import { Helmet } from "react-helmet";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import ReCAPTCHA from "react-google-recaptcha";
import googleConfig from '../../../services/google/googleConfig';
import { UseAxiosInstance } from '../../../services/aws/api/AxiosInstance';
import {
  validateName, validateEmail, validateCompany, validateMessage
} from '../../../utilities/InputCheckUtilities';
import emailjs from 'emailjs-com'
import emailjsKey from '../../../services/emailjs/emailkey'
import { useTranslation } from 'react-i18next';

const initialFormData = { name: '', email: '', company: '', message: '' };
const initialFormErrors = { name: '', email: '', company: '', message: '' };

export default function Contact() {
  const theme = useTheme();
  const { t } = useTranslation();
  const axiosInstance = UseAxiosInstance();
  const [backendError, setBackendError] = React.useState("");
  const [formData, setFormData] = React.useState(initialFormData);
  const [formErrors, setFormErrors] = React.useState(initialFormErrors);
  const [loading, setLoading] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);
  const recaptchaRef = React.useRef(null);

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    let fieldError = '';
    switch (field) {
      case 'name':
        fieldError = validateName(value);
        break;
      case 'email':
        fieldError = validateEmail(value);
        break;
      case 'company':
          fieldError = validateCompany(value);
          break;
      case 'message':
        fieldError = validateMessage(value);
        break;
      default:
        break;
    }
    setFormErrors({ ...formErrors, [field]: fieldError });
  }

  const validateForm = () => {
    const errors = {
      name: validateName(formData.name),
      email: validateEmail(formData.email),
      company: validateCompany(formData.company),
      message: validateMessage(formData.message),
    };
    setFormErrors(errors);
    return Object.values(errors).every(error => !error);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (loading || !validateForm()) return;

    setLoading(true);

    try {

      //Verify Recaptcha
      const recaptchaToken = recaptchaRef.current.getValue();
      if (recaptchaToken === '') {
        setBackendError(t("Forms.Validation.RecaptchaConfirm"));
        return;
      }
      else {
        try {
          await axiosInstance.post('/recaptcha', { token: recaptchaToken });
        } catch (error) {
          setBackendError(t("Forms.Validation.RecaptchaVerificationFailed"));
          return;
        }
      }

      await emailjs.send(emailjsKey.SERVICE_ID, emailjsKey.TEMPLATE_ID, formData, emailjsKey.USER_ID);
      setSnackBarMessage(t("Contact.SuccessMessage"));
      setIsSnackbarOpen(true);

      setFormData(initialFormData);
    } catch (error) {
      setBackendError(error);
    } finally {
      setLoading(false);
      recaptchaRef.current?.reset();
    }
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackbarOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{t("Meta.Contact.Title")}</title>
        <meta name="description" content={t("Meta.Contact.Description")} />
      </Helmet>

      <Container component="main" maxWidth="md">
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          <Typography variant="h1" color="text.primary" align="center" gutterBottom>
            {t("Contact.Title")}
          </Typography>
          <Typography component="h2" variant="h5" align="center" color="text.secondary" paragraph sx={{ lineHeight: 1.4, whiteSpace: 'pre-line' }}>
            {t("Contact.Description")}
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="name"
              label={t("Forms.Name")}
              type="name"
              id="name"
              value={formData.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
              error={Boolean(formErrors.name)}
              helperText={formErrors.name}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("Forms.Email")}
              name="email"
              autoComplete="email"
              value={formData.email}
              onChange={(e) => handleInputChange('email', e.target.value)}
              error={Boolean(formErrors.email)}
              helperText={formErrors.email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="company"
              label={t("Forms.Company")}
              name="company"
              value={formData.company}
              onChange={(e) => handleInputChange('company', e.target.value)}
              error={Boolean(formErrors.company)}
              helperText={formErrors.company}
            />
            <TextField
              margin="normal"
              multiline
              rows={6}
              required
              fullWidth
              id="message"
              label={t("Forms.Message")}
              name="message"
              value={formData.message}
              onChange={(e) => handleInputChange('message', e.target.value)}
              error={Boolean(formErrors.message)}
              helperText={formErrors.message}
            />

            <Box display="flex" justifyContent="center" mt={1.5}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={googleConfig.recaptcha.siteKey} />
            </Box>

            {backendError && (
              <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                {backendError}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
              sx={{ mt: 2, mb: 2 }}>
              {loading ? <CircularProgress size={24} /> : t("Forms.Submit")}
            </Button>
          </Box>
        </Box>

        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
          message={snackBarMessage}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}>
          <SnackbarContent
            message={snackBarMessage}
            style={{ backgroundColor: theme.palette.secondary.main }}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            } />
        </Snackbar>

      </Container>
    </ThemeProvider>
  );
}