import * as React from 'react';
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import UploadIcon from '@mui/icons-material/Upload';
import EditIcon from '@mui/icons-material/Edit';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { json, useNavigate } from "react-router-dom";
import { UseUser } from '../../../services/user/UserContext';
import AppDrawer from '../../drawer/AppDrawer';
import { UseAxiosInstance } from '../../../services/aws/api/AxiosInstance';
import fetchScopeLicenses from '../../../utilities/LicensesUtilities';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { saveAs } from 'file-saver';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLanguagePrefix } from '../../translation/LanguageUrlHandler';

export default function LicensesPage() {
    const theme = useTheme();
    const { currentUser } = UseUser();
    const axiosInstance = UseAxiosInstance(currentUser);
    const [licenses, setLicenses] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const languagePrefix = getLanguagePrefix(location.pathname);

    const [expandedRows, setExpandedRows] = useState({});
    const [snackBarMessage, setSnackBarMessage] = React.useState("");
    const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);

    // License creation dialog States
    const [licenseDialogOpen, setLicenseDialogOpen] = useState(false);
    const [dialogLicense, setDialogLicense] = useState("");
    const [company, setCompany] = useState('');
    const [companyUsers, setCompanyUsers] = useState([]);
    const [product, setProduct] = useState('');
    const [plan, setPlan] = useState('');
    const [maxUses, setMaxUses] = useState(1);
    const [tokens, setTokens] = useState(0);
    const [expiry, setExpiry] = useState(new Date());
    const [isCompanyLicense, setIsCompanyLicense] = useState(false);
    const [refreshKey, setRefreshKey] = useState(false);

    //License export dialog
    const [exportDialogOpen, setexportDialogOpen] = useState(false);
    const [licenseToExport, setLicenseToExport] = useState(null);
    const [fingerPrintFile, setFingerPrintFile] = useState(null);

    // Company filter state
    const [companyFilter, setCompanyFilter] = useState('All');

    useEffect(() => {
        setLoading(true);
        if (!currentUser || !axiosInstance) return;

        const fetchLicenses = async () => {
            try {
                const licenses = await fetchScopeLicenses(axiosInstance, currentUser);
                setLicenses(licenses);
                setLoading(false);
            } catch (err) {
                console.error("Failed to fetch licenses:", err);
                setLoading(false);
            }
        };

        const fetchCompanyUsers = async () => {
            try {
                const companyUsersResponse = await axiosInstance.get(`/companies/${currentUser.company}/users`);
                const companyUsersData = JSON.parse(companyUsersResponse.data);
                setCompanyUsers(companyUsersData)
            } catch (err) {
                console.error("Failed to fetch company users:", err);
            }
        };

        fetchLicenses();
        if (currentUser.role != "User") {
            fetchCompanyUsers();
        }
    }, [currentUser, axiosInstance, refreshKey]);

    const allCompanies = ['All', ...new Set(licenses.map(license => license.company).sort())];;

    const handleCreateOrUpdateLicense = async () => {
        try {
            const payload = {
                company: company,
                product: product,
                plan: plan,
                expiry: new Date(expiry).toISOString(),
                maxUses: maxUses,
                tokens: tokens
            };

            if (dialogLicense) {
                //Update
                await axiosInstance.put(`/licenses/${dialogLicense.PK?.split('#')[1]}`, payload);
            }
            else {
                //Create
                payload.createCompanyLicense = isCompanyLicense;
                await axiosInstance.post('/licenses', payload);
            }

            setRefreshKey(refreshKey => !refreshKey);
        } catch (error) {
            setSnackBarMessage(`Failed to create or update license: ${error.message}`);
            setIsSnackbarOpen(true);
        } finally {
            setLicenseDialogOpen(false);
        }
    };

    const handleDeleteLicense = async (license) => {
        const confirmed = window.confirm("Are you sure you want to delete this license?");
        if (!confirmed) return;
        try {
            await axiosInstance.delete(`/licenses/${license.PK?.split('#')[1]}`);
            setRefreshKey(refreshKey => !refreshKey);
        } catch (error) {
            setSnackBarMessage(`Failed to delete license: ${error.message}`);
            setIsSnackbarOpen(true);
        } finally {
            setLicenseDialogOpen(false);
        }
    };

    const handleDialogOpen = (license = null) => {
        setCompany(license?.company ?? '');
        setProduct(license?.product ?? '');
        setPlan(license?.plan ?? '');
        setMaxUses(license?.maxUses ?? 1);
        setTokens(license?.tokens ?? 0);
        setExpiry(license?.expiry ? new Date(license.expiry) : new Date());
        setIsCompanyLicense(false);

        setDialogLicense(license)
        setLicenseDialogOpen(true);
    };

    const handleDialogClose = () => {
        setLicenseDialogOpen(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsSnackbarOpen(false);
    };

    const handleDownloadLicense = async (license) => {
        setLicenseToExport(license)
        setexportDialogOpen(true);
    };

    const handleExportDialogClose = () => {
        setexportDialogOpen(false);
    }

    const handleFingerPrintFileChange = (e) => {
        setFingerPrintFile(e.target.files[0]);
    }

    const handleExportLicense = async () => {
        if (!fingerPrintFile || !licenseToExport) return;

        try {
            const licenseId = licenseToExport.SK.split('#')[1];
            const fingerprint = await readFileAsText(fingerPrintFile);
            const licenseExportResponse = await axiosInstance.get(`/licenses/${licenseId}/export?fingerprint=${fingerprint}`);

            const xmlContent = licenseExportResponse.data.xml;
            const signatureBlock = `\n<signature>${licenseExportResponse.data.signature}</signature>`;

            const blob = new Blob([xmlContent, signatureBlock], { type: 'text/xml' });
            saveAs(blob, `${licenseId}.lic`);

            setFingerPrintFile(null);
            setLicenseToExport(null);
            handleExportDialogClose();
        } catch (error) {
            setSnackBarMessage(`Failed to export license: ${error.message}`);
            setIsSnackbarOpen(true);
        }
    }

    const readFileAsText = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => resolve(event.target.result);
            reader.onerror = (error) => reject(error);
            reader.readAsText(file);
        });
    };

    function Row(props) {
        const { license, userRole, isOpen, toggleOpen } = props;
        const [selectedUser, setSelectedUser] = useState(false);
        const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
        const [licenseAssignationLoading, setLicenseAssignationLoading] = useState(false);
        const [licenseAssignationError, setLicenseAssignationError] = useState("");
        const [deletingLicenses, setDeletingLicenses] = useState([]);

        if (!license) return;

        const licenseId = license.PK?.split('#')[1];
        const licenseCompany = license.company;
        const licenseProduct = license.product;
        const licensePlan = license.plan;
        const licenseStatus = license.status;
        const licenseType = license.isCompanyLicense ? "Company" : "User based";
        const canAddUser = license.maxUses && license.userLicenses.length < license.maxUses;
        const licenseTokens = license.tokens == 0 ? '-' : license.tokens;
        const expiryDate = license.expiry ? new Date(license.expiry) : null;
        const licenseExpiry = expiryDate && expiryDate.getFullYear() - new Date().getFullYear() > 100 ? "∞" : expiryDate ? expiryDate.toLocaleDateString() : "-";

        let licenseUsage;
        if (license.isCompanyLicense) {
            licenseUsage = "∞";
        }
        else if (!license.maxUses || userRole == "User") {
            licenseUsage = "-";
        }
        else {
            licenseUsage = `${license.userLicenses.length} / ${license.maxUses}`;
        }

        const getChipStyle = (status) => {
            switch (status) {
                case 'Active':
                    return { background: '#c9f9d9', color: '#287e46' };
                case 'Expired':
                    return { background: '#fed2d2', color: '#aa2c28' };
                default:
                    return {};
            }
        };

        const handleAssignButtonClick = async () => {
            const success = await handleAssignLicense(licenseId, selectedUser);
            if (success) {
                setAddUserDialogOpen(false);
            }
        };

        const handleAssignLicense = async (licenseId, userLicense) => {
            setLicenseAssignationLoading(true);
            setLicenseAssignationError("");

            try {
                const username = userLicense.SK?.split('#')[1];
                if (license.userLicenses.some(lic => lic.SK?.split('#')[1] === username)) {
                    setLicenseAssignationError("User already assigned to this license.");
                    return false;
                }

                await axiosInstance.post(`/licenses/${licenseId}/assignations`, {
                    username: username
                });

                // Optimistic update 
                setLicenses(prevLicenses => {
                    return prevLicenses.map(lic => {
                        if (lic.PK === license.PK) {
                            return {
                                ...lic,
                                userLicenses: [
                                    ...lic.userLicenses,
                                    {
                                        ...userLicense,
                                        creationDate: new Date().toISOString()
                                    }
                                ]
                            };
                        }
                        return lic;
                    });
                });

                return true;
            } catch (error) {
                setLicenseAssignationError(`Fail to assign license: ${error.message}`);
                return false;
            } finally {
                setLicenseAssignationLoading(false);
            }
        };

        const handleDeleteUserLicense = async (licenseId, userLicense) => {
            const confirmed = window.confirm("Are you sure you want to delete this user license?");
            if (!confirmed) return;

            try {
                const username = userLicense.SK?.split('#')[1];
                setDeletingLicenses(prev => [...prev, userLicense]);

                await axiosInstance.delete(`/licenses/${licenseId}/assignations/${username}`);

                // Optimistic update 
                setLicenses(prevLicenses => {
                    return prevLicenses.map(lic => {

                        if (lic.PK === license.PK) {
                            return {
                                ...lic,
                                userLicenses: lic.userLicenses.filter(userLicense => userLicense.SK.split('#')[1] !== username)
                            };
                        }
                        return lic;
                    });
                });
            } catch (error) {
                props.setSnackBarMessage(`Failed to delete license: ${error.message}`);
                props.setIsSnackbarOpen(true);
            }

            setDeletingLicenses(prev => prev.filter(x => x !== userLicense));
        };

        return (
            <React.Fragment>
                <TableRow
                    key={license.PK + ";" + license.SK}
                    onClick={userRole == "User" || license.isCompanyLicense ? null : toggleOpen}
                    style={userRole == "User" || license.isCompanyLicense ? {} : { cursor: 'pointer' }}>
                    <TableCell>
                        {(userRole != "User" && !license.isCompanyLicense) && (
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleOpen();
                                }}>
                                {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        )}
                    </TableCell>
                    <TableCell component="th" scope="row">{licenseId}</TableCell>
                    {userRole == "Root" && <TableCell>{licenseCompany}</TableCell>}
                    <TableCell>{licenseProduct}</TableCell>
                    <TableCell>
                        {licensePlan.split(';').map((plan, index) => (
                            <Chip
                                key={index}
                                label={plan.trim()}
                                style={{ background: '#daeff7', color: '#4e575c', marginRight: '4px' }}
                            />
                        ))}
                    </TableCell>
                    <TableCell>{licenseType}</TableCell>
                    <TableCell>
                        <Chip
                            label={licenseStatus}
                            style={getChipStyle(licenseStatus)}
                        />
                    </TableCell>
                    {userRole != "User" && <TableCell>{licenseUsage}</TableCell>}
                    <TableCell>{licenseTokens}</TableCell>
                    <TableCell>{licenseExpiry}</TableCell>
                    {userRole == "Root" &&
                        <TableCell>
                            <IconButton onClick={(e) => {
                                e.stopPropagation();
                                handleDialogOpen(license);
                            }}>
                                <EditIcon />
                            </IconButton>
                            <IconButton onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteLicense(license);
                            }}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton onClick={(e) => {
                                e.stopPropagation();
                                handleDownloadLicense(license);
                            }}>
                                <DownloadIcon />
                            </IconButton>
                        </TableCell>}
                </TableRow>

                <TableRow key="UsersLicenses">
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 'none' }} colSpan={5}>
                        <Collapse in={isOpen} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h5" color="text.primary" gutterBottom component="div">
                                    License Users
                                </Typography>
                                <Button
                                    disabled={!canAddUser}
                                    sx={{ my: 1 }}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setLicenseAssignationError("");
                                        setAddUserDialogOpen(true);
                                    }} >
                                    Add new User
                                </Button>
                                <Table size="small" aria-label="purchases" sx={{ mb: 3 }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Email</TableCell>
                                            <TableCell>First Name</TableCell>
                                            <TableCell>Last Name</TableCell>
                                            <TableCell>Creation Date</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {license.userLicenses.map((userLicense) => {
                                            const userLicenseCreationDate = userLicense.creationDate == null ? "-" : new Date(userLicense.creationDate).toLocaleDateString();
                                            const isDeleting = deletingLicenses.includes(userLicense);
                                            return (
                                                <TableRow key={userLicense.email}>
                                                    <TableCell>{userLicense.email}</TableCell>
                                                    <TableCell>{userLicense.firstName}</TableCell>
                                                    <TableCell>{userLicense.lastName}</TableCell>
                                                    <TableCell>{userLicenseCreationDate}</TableCell>
                                                    <TableCell>
                                                        <Tooltip title="Delete User License">
                                                            <IconButton
                                                                onClick={() => handleDeleteUserLicense(licenseId, userLicense)}
                                                                disabled={isDeleting}>
                                                                {isDeleting ? <CircularProgress size={24} /> : <DeleteIcon />}
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>

                <Dialog open={addUserDialogOpen} onClose={() => setAddUserDialogOpen(false)} fullWidth={true}>
                    <DialogTitle>Select a User to Add</DialogTitle>
                    <DialogContent>
                        <Autocomplete
                            sx={{ mt: 1, mb: 1 }}
                            options={companyUsers}
                            getOptionLabel={(option) =>
                                (option.firstName && option.lastName)
                                    ? `${option.firstName} ${option.lastName} (${option.email})`
                                    : `${option.email} (${option.email})`
                            }
                            onChange={(event, newValue) => setSelectedUser(newValue)}
                            renderInput={(params) => <TextField {...params} label="Select a user" fullWidth />}
                        />
                        {licenseAssignationError && (
                            <Typography color="error" variant="body2" style={{ margin: '0 0 10px' }}>
                                {licenseAssignationError}
                            </Typography>
                        )}
                        {licenseAssignationLoading && (
                            <CircularProgress size={24} style={{ margin: '10px' }} />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setAddUserDialogOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={handleAssignButtonClick}
                            color="primary"
                            disabled={!selectedUser}>
                            Add User
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    if (currentUser == null) {
        return;
    }

    return (
        <Box sx={{ display: "flex" }}>
            <AppDrawer></AppDrawer>
            <Box sx={{ overflow: "auto", bgcolor: '#f2f5f9', minHeight: 'calc(100vh - 64px)', padding: 2 }}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <Typography variant="h4" color='text.primary' sx={{ mb: 2 }}>Licenses</Typography>
                    <Box sx={{ overflow: "auto" }}>
                        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                            {loading ? (
                                <CircularProgress
                                    size={36}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            ) : (
                                <Paper elevation={1} sx={{ padding: 2, marginBottom: 2, borderRadius: 2 }}>
                                    {currentUser.role == "Root" || (licenses && licenses.length > 0) ? (
                                        <Box sx={{ overflow: "auto" }}>
                                            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>

                                                {/* Company filter for Root only */}
                                                {currentUser.role == "Root" && (
                                                    <Autocomplete
                                                        options={allCompanies}
                                                        value={companyFilter}
                                                        onChange={(event, newFilter) => setCompanyFilter(newFilter)}
                                                        sx={{ mt: 1, mb: 2, width: 400 }}
                                                        renderInput={(params) => <TextField {...params} label="Select Company" variant="outlined" />}
                                                    />
                                                )}

                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell />
                                                            <TableCell>License key</TableCell>
                                                            {currentUser.role == "Root" && <TableCell>Company</TableCell>}
                                                            <TableCell>Product</TableCell>
                                                            <TableCell>Plan</TableCell>
                                                            <TableCell>Type</TableCell>
                                                            <TableCell>Status</TableCell>
                                                            {currentUser.role != "User" && <TableCell>Uses</TableCell>}
                                                            <TableCell>Tokens</TableCell>
                                                            <TableCell>Expiry</TableCell>
                                                            {currentUser.role == "Root" && <TableCell />}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {licenses.filter(license =>
                                                            currentUser.role !== "Root" ||
                                                            !companyFilter ||
                                                            companyFilter === 'All' ||
                                                            license.company.includes(companyFilter)
                                                        ).map((license) => {
                                                            return (
                                                                <Row
                                                                    key={license.PK}
                                                                    license={license}
                                                                    userRole={currentUser.role}
                                                                    isOpen={!!expandedRows[license.PK]}
                                                                    setSnackBarMessage={setSnackBarMessage}
                                                                    setIsSnackbarOpen={setIsSnackbarOpen}
                                                                    toggleOpen={() => {
                                                                        setExpandedRows(prev => ({
                                                                            ...prev,
                                                                            [license.PK]: !prev[license.PK]
                                                                        }));
                                                                    }}
                                                                />
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Box>
                                    ) :
                                        <Box sx={{ padding: 3, borderRadius: 2, textAlign: 'center' }}>
                                            <Typography variant="h4" gutterBottom>
                                                Need a License?
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 2 }}>
                                                It seems you don't have a license yet.<br />Please fill out the contact form to obtain one.
                                            </Typography>
                                            <Button variant="contained" color="primary" size="large" href={`https://www.ingeloop.com${languagePrefix}/contact`} target="_blank">
                                                Contact
                                            </Button>
                                        </Box>
                                    }

                                    {currentUser?.role == "Root" && (
                                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'left' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleDialogOpen()}>
                                                Create License
                                            </Button>
                                        </Box>
                                    )}
                                </Paper>
                            )}
                        </Box>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Dialog open={licenseDialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title">New License</DialogTitle>
                                <DialogContent>
                                    <TextField
                                        sx={{ mb: 1 }}
                                        margin="dense"
                                        label="Company name"
                                        type="text"
                                        fullWidth
                                        value={company}
                                        onChange={(e) => setCompany(e.target.value)}
                                    />
                                    {!dialogLicense && <FormControlLabel
                                        sx={{ ml: 0 }}
                                        control={
                                            <Checkbox
                                                checked={isCompanyLicense}
                                                onChange={(e) => setIsCompanyLicense(e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label="Company License"
                                    />}
                                    <TextField
                                        sx={{ mb: 1 }}
                                        margin="dense"
                                        label="Product name"
                                        type="text"
                                        fullWidth
                                        value={product}
                                        onChange={(e) => setProduct(e.target.value)}
                                    />
                                    <TextField
                                        sx={{ mb: 1 }}
                                        margin="dense"
                                        label="Plan"
                                        type="text"
                                        fullWidth
                                        value={plan}
                                        onChange={(e) => setPlan(e.target.value)}
                                    />
                                    <TextField
                                        sx={{ mb: 2 }}
                                        margin="dense"
                                        label="Max Uses"
                                        type="number"
                                        fullWidth
                                        value={maxUses}
                                        onChange={(e) => setMaxUses(e.target.value)}
                                    />
                                    <TextField
                                        sx={{ mb: 2 }}
                                        margin="dense"
                                        label="Tokens"
                                        type="number"
                                        fullWidth
                                        value={tokens}
                                        onChange={(e) => setTokens(e.target.value)}
                                    />
                                    <DesktopDatePicker
                                        label="Expiry Date"
                                        value={expiry}
                                        onChange={(newDate) => setExpiry(newDate)}
                                        slots={{ Input: TextField }}
                                        fullWidth
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleDialogClose} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={handleCreateOrUpdateLicense} color="primary">
                                        OK
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </LocalizationProvider>

                        <Dialog open={exportDialogOpen} onClose={handleExportDialogClose}>
                            <DialogTitle>Export License</DialogTitle>
                            <DialogContent>
                                <>
                                    <Button variant="contained" component="label" color="primary">
                                        <UploadIcon /> Upload FingerPrint
                                        <input
                                            type="file"
                                            hidden
                                            onChange={handleFingerPrintFileChange} />
                                    </Button>

                                    {fingerPrintFile &&
                                        <Typography variant="body1" sx={{ mt: 1 }}>
                                            Uploaded file: {fingerPrintFile.name}
                                        </Typography>
                                    }
                                </>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleExportDialogClose}>Cancel</Button>
                                <Button onClick={handleExportLicense}>Export</Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                </Box>
            </Box>

            <Snackbar
                open={isSnackbarOpen}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                message={snackBarMessage}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}>
                <SnackbarContent
                    message={snackBarMessage}
                    style={{ backgroundColor: theme.palette.secondary.main }}
                    action={
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleSnackbarClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    } />
            </Snackbar>
        </Box>
    );
}