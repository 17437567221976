import i18next from 'i18next';

export const validateName = (value) => {
    if (!value) return i18next.t("Forms.Validation.NameRequired");
    return "";
}

export const validateFirstName = (value) => {
    if (!value) return i18next.t('Forms.Validation.FirstNameRequired');
    return "";
}

export const validateLastName = (value) => {
    if (!value) return i18next.t('Forms.Validation.LastNameRequired');
    return "";
}

export const validateCompany = (value) => {
    if (!value) return i18next.t("Forms.Validation.CompanyRequired");
    return "";
}

export const validateEmail = (value) => {
    if (!value) return i18next.t('Forms.Validation.EmailRequired');
    else if (!/\S+@\S+\.\S+/.test(value)) return i18next.t('Forms.Validation.EmailBadFormat');
    return "";
}

export const validatePassword = (value) => {
    if (!value) return i18next.t('Forms.Validation.PasswordRequired');
    else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s:]).{8,}$/.test(value)) return i18next.t('Forms.Validation.PasswordBadFormat');
    return "";
}

export const validateConfirmPassword = (value, originalPassword) => {
    if (!value) return i18next.t('Forms.Validation.PasswordConfirmRequired');
    else if (originalPassword !== value) return i18next.t('Forms.Validation.PasswordConfirmBadFormat');
    return "";
}

export const validateMessage = (value) => {
    if (!value) return i18next.t('Forms.Validation.MessageRequired');
    return "";
}