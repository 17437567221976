import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Footer from '../../../footer/Footer';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigationType, useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import { getLanguagePrefix } from '../../../translation/LanguageUrlHandler';
import { addUserAction } from '../../../../services/user/UserActions';
import { ImgComparisonSlider } from '@img-comparison-slider/react';

import ProductsData from './../Products.json'
import { default as ModelSwapImage } from '../../../images/products/ModelSwap/ModelSwap.png';
import { default as RevitModel } from '../../../images/products/ModelSwap/RevitModel.png';
import { default as AvevaModel } from '../../../images/products/ModelSwap/AvevaModel.png';
import { default as ModelSwapConfiguration } from '../../../images/products/ModelSwap/ModelSwapConfiguration.png';
import { Padding } from '@mui/icons-material';

function ModelSwap() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigationType = useNavigationType();
  const location = useLocation();
  const navigate = useNavigate()
  const languagePrefix = getLanguagePrefix(location.pathname);
  const productData = ProductsData.find(item => item.key === 'ModelSwap');
  const youtubeUrl = productData.youtubeUrl;
  const targetOrigin = window.location.origin.replace("localhost", "app.localhost").replace("ingeloop", "app.ingeloop");
  const targetPage = targetOrigin + "";
  const [isYearly, setIsYearly] = useState(true);

  useEffect(() => {
    if (navigationType === "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [navigationType]);

  const handleSwitchChange = () => {
    setIsYearly(!isYearly);
  };

  const tiers = [
    {
      title: t("ModelSwap.Tiers.Core.Title"),
      monthlyPrice: '',
      yearlyPrice: '',
      description: [
        t("ModelSwap.Tiers.Core.Feature1"),
        t("ModelSwap.Tiers.Core.Feature2"),
        t("ModelSwap.Tiers.Core.Feature3"),
        t("ModelSwap.Tiers.Core.Feature4"),
      ],
      buttonText: t("ModelSwap.TiersContact"),
      buttonVariant: 'contained',
      link: '/contact'
    },
    {
      title: t("ModelSwap.Tiers.Native.Title"),
      monthlyPrice: '',
      yearlyPrice: '',
      description: [
        t("ModelSwap.Tiers.Native.Feature1"),
        t("ModelSwap.Tiers.Native.Feature2"),
        t("ModelSwap.Tiers.Native.Feature3"),
        t("ModelSwap.Tiers.Native.Feature4"),
      ],
      buttonText: t("ModelSwap.TiersContact"),
      buttonVariant: 'contained',
      createTrial: false,
      link: '/contact'
    },
  ];

  const useCases = [
    {
      useCase: t("ModelSwap.UseCases.UseCase1.UseCase"),
      result: t("ModelSwap.UseCases.UseCase1.Result"),
      plan: 'Native',
    },
    {
      useCase: t("ModelSwap.UseCases.UseCase2.UseCase"),
      result: t("ModelSwap.UseCases.UseCase2.Result"),
      plan: 'Core',
    },
    {
      useCase: t("ModelSwap.UseCases.UseCase3.UseCase"),
      result: t("ModelSwap.UseCases.UseCase3.Result"),
      plan: 'Native',
    },
    {
      useCase: t("ModelSwap.UseCases.UseCase4.UseCase"),
      result: t("ModelSwap.UseCases.UseCase4.Result"),
      plan: 'Core',
    },
  ];

  const getChipStyle = (status) => {
    switch (status) {
      case 'Core':
        return { background: '#FF7A3C', color: '#FFFFFF' };
      case 'Native':
        return { background: '#029dd9', color: '#FFFFFF' };
      default:
        return {};
    }
  };

  return (
    <main>
      <Helmet>
        <title>{t("Meta.Products.ModelSwap.Title")}</title>
        <meta name="description" content={t("Meta.Products.ModelSwap.Description")} />
      </Helmet>

      <Grid container justifyContent="center">
        <Grid container justifyContent="center">
          <Box
            color="secondary.main"
            sx={{ backgroundColor: 'white', height: 'calc(100vh - 54px)', maxWidth: "xl", display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, justifyContent: 'center' }}>
            <Box sx={{
              maxWidth: { xs: '100%', lg: '50%' },
              flexShrink: 0, p: 2, m: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: { xs: 6, md: 0 } }}>
                <img src={ModelSwapImage} alt="ModelSwap" style={{ marginRight: '8px', width: 'auto', height: '100px' }} />
                <Typography
                  variant="h1"
                  color="text.primary"
                  align="center"
                  gutterBottom
                  sx={{ textAlign: 'center', margin: 0, verticalAlign: 'middle' }}>
                  {t("ModelSwap.Title")}
                </Typography>
              </Box>
              <Typography component="h2" variant="h5" align="center" color="text.secondary" paragraph sx={{ lineHeight: 1.4 }}>
                {t("ModelSwap.Subtitle")}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, p: 2, m: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
              <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <ImgComparisonSlider style={{ width: '80%', height: 'auto' }}>
                  <img slot="first" src={RevitModel} alt="Revit Model" style={{ width: '100%', height: 'auto' }} />
                  <img slot="second" src={AvevaModel} alt="AVEVA Model" style={{ width: '100%', height: 'auto' }} />
                </ImgComparisonSlider>
              </Box>
            </Box>
          </Box>

          {/* Presentation */}
          <Grid item xs={12} sx={{ mt: 2, bgcolor: '#fafafc' }}>
            <Container maxWidth="xl" sx={{ mt: 6 }}>
              <Grid container>
                <Grid item xs={12} md={6} sx={{ p: [2, 6], pl: [2, 0], pt: [2, 0], order: { xs: 1, md: 1 } }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      position: 'relative',
                      width: '100%',
                      paddingTop: '56.25%'
                    }}
                  >
                    <iframe
                      title="ModelSwap"
                      src={youtubeUrl}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                      }}
                      allowFullScreen
                    ></iframe>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 2 } }}>
                  <Box textAlign="left">
                    <Typography variant="h2" color="text.primary" sx={{ mb: [2, 3] }}>
                      {t("ModelSwap.Presentation1Title")}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }} style={{ whiteSpace: 'pre-line' }}>
                      {t("ModelSwap.Presentation1Content")}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>

          {/* Configuration */}
          <Grid item xs={12} sx={{ mt: 2, bgcolor: '#ffffff' }}>
            <Container maxWidth="xl" sx={{ mt: 3, mb: 3 }}>
              <Grid container>
                <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
                  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ p: [2, 4] }}>
                    <img src={ModelSwapConfiguration} alt="ModelSwap Configuration" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
                  <Box textAlign="left">
                    <Typography variant="h2" color="text.primary" sx={{ mb: [2, 3] }}>
                      {t("ModelSwap.Presentation2Title")}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }} style={{ whiteSpace: 'pre-line' }}>
                      {t("ModelSwap.Presentation2Content")}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>

          {/* Pricing */}
          <Grid item xs={12} sx={{ pb: { xs: 4, md: 6 }, bgcolor: '#fafafc' }}>
            <Container disableGutters maxWidth="md" component="main" sx={{ pt: 4, pb: 6 }}>
              <Typography variant="h2" color="text.primary" sx={{ mb: 2, textAlign: "center" }}>{t("ModelSwap.TiersTitle")}</Typography>
              <Typography variant="body1" align="center" sx={{ px: 2, whiteSpace: 'pre-line' }}>{t("ModelSwap.TiersSubtitle")}</Typography>
            </Container>

            <Container maxWidth="md" component="main">
              <Grid container spacing={5} alignItems="flex-end">
                {tiers.map((tier) => (
                  <Grid
                    item
                    key={tier.title}
                    xs={12}
                    md={6}
                  >
                    <Card>
                      <CardHeader
                        title={tier.title}
                        subheader={tier.subheader}
                        titleTypographyProps={{ align: 'center', variant: "h3", color: "text.primary" }}
                        subheaderTypographyProps={{
                          align: 'center',
                        }}
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                              ? theme.palette.grey[200]
                              : theme.palette.grey[700],
                        }}
                      />
                      <CardContent>
                        <ul style={{ listStyleType: 'none', paddingInlineStart: '0px', textAlign: 'left', width: '100%' }}>
                          {tier.description.map((line) => (
                            <li key={line} >
                              <Typography variant="subtitle1" align="left" sx={{ display: 'flex', alignItems: 'center' }}>
                                <CheckIcon fontSize='small' sx={{ mr: 2 }} />
                                {line}
                              </Typography>
                            </li>
                          ))}
                        </ul>
                      </CardContent>
                      <CardActions>
                        <Button
                          href={tier.link}
                          fullWidth
                          variant={tier.buttonVariant}
                          component="button"
                          sx={{  mx: 2, mb: 2, mt: 1 }}>
                          {tier.buttonText}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Grid>

          {/* Use cases */}
          <Grid item xs={12} sx={{ pb: { xs: 4, md: 6 }, bgcolor: '#fafafc' }}>
            <Container disableGutters maxWidth="md" component="main" sx={{ pt: 4, pb: 6 }}>
              <Typography component="h2" variant="h4" color="text.primary" align='center' sx={{ pb: 2 }}>
                {t("ModelSwap.UseCases.Title")}
              </Typography>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("ModelSwap.UseCases.Columns.UseCase")}</TableCell>
                    <TableCell>{t("ModelSwap.UseCases.Columns.Plan")}</TableCell>
                    <TableCell>{t("ModelSwap.UseCases.Columns.Result")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {useCases.map((useCase) => {
                    return (
                      <TableRow key={useCase.useCase}>
                        <TableCell>{useCase.useCase}</TableCell>
                        <TableCell>
                          <Chip
                            label={useCase.plan}
                            style={getChipStyle(useCase.plan)}
                          />
                        </TableCell>
                        <TableCell>{useCase.result}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Container>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </main>
  );
}

export default ModelSwap;
