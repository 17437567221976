import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { matchUrlLanguage } from './LanguageUrlHandler';

export default function LanguageWrapper({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  
  useEffect(() => {
    const languageMatch = matchUrlLanguage(location.pathname);
    const targetLanguage = languageMatch ? languageMatch[1] : "en";

    i18n.changeLanguage(targetLanguage);

  }, [location.pathname, navigate, i18n]);

  return children;
}
