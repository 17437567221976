import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { UseUser } from '../../../services/user/UserContext';
import AppDrawer from '../../drawer/AppDrawer';
import { UseAxiosInstance } from '../../../services/aws/api/AxiosInstance';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { Table, TableHead, TableBody, TableContainer, TableRow, TableCell } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { BarChart, Bar, LineChart, Line, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLanguagePrefix } from '../../translation/LanguageUrlHandler';
import { compareAsc, format, subDays, subMonths, subYears, parseISO } from 'date-fns';

export default function UsageDashboardPage() {
    const theme = useTheme();
    const { currentUser } = UseUser();
    const axiosInstance = UseAxiosInstance(currentUser);
    const navigate = useNavigate();
    const location = useLocation();
    const languagePrefix = getLanguagePrefix(location.pathname);

    const [sessions, setSessions] = useState([]);
    const [sessionsLoading, setSessionsLoading] = useState(false);

    const [companyUsageViewData, setCompanyUsageViewData] = useState([]);
    const [usersUsageViewData, setUsersUsageViewData] = useState([]);
    const [userMachinesViewData, setUserMachinesViewData] = useState([]);

    const [companyUsageTimeFilter, setCompanyUsageTimeFilter] = useState('year');

    const [usersSessionsTimeFilter, setUsersSessionsTimeFilter] = useState('year');
    const [allCompanies, setAllCompanies] = useState([]);
    const [usersSessionsCompanyFilter, setUsersSessionsCompanyFilter] = useState('All');

    const [allUsers, setAllUsers] = useState([]);
    const [userMachinesUserFilter, setUserMachinesUserFilter] = useState('');
    const [userMachinesTimeFilter, setUserMachinesTimeFilter] = useState('year');
    const [filteredUserSessions, setFilteredUserSessions] = useState([]);

    useEffect(() => {
        setSessionsLoading(true);
        if (!currentUser || !axiosInstance) return;

        const fetchSessions = async () => {
            try {
                const response = await axiosInstance.get(`/sessions`);
                const sessions = JSON.parse(response.data);
                setSessions(sessions);

                const uniqueUsers = Array.from(new Set(sessions.map(session => session.Email)));
                setAllUsers(uniqueUsers);

                const uniqueCompanies = Array.from(new Set(sessions.map(session => session.Company)));
                uniqueCompanies.unshift('All');
                setAllCompanies(uniqueCompanies);

                setSessionsLoading(false);
            } catch (err) {
                console.error("Failed to fetch sessions:", err);
                setSessionsLoading(false);
            }
        };

        fetchSessions();
    }, [currentUser, axiosInstance]);

    useEffect(() => {
        if (sessions.length > 0) {
            const companyUsageData = computeChartData(sessions, companyUsageTimeFilter, 'Company');
            setCompanyUsageViewData(companyUsageData);

            const usersUsageViewData = computeUsersUsageData(sessions, usersSessionsTimeFilter, usersSessionsCompanyFilter);
            setUsersUsageViewData(usersUsageViewData);

            const userMachinesViewData = computeChartData(sessions, userMachinesTimeFilter, 'ClientMachine', userMachinesUserFilter);
            setUserMachinesViewData(userMachinesViewData);

            const startDate = calculateStartDate(userMachinesTimeFilter);
            const endDate = new Date();
            const filteredSessions = sessions.filter(session => {
                const sessionDate = parseISO(session.Date);
                return userMachinesUserFilter && sessionDate >= startDate &&
                    sessionDate <= endDate && session.Email === userMachinesUserFilter;
            }
            );
            const sortedSessions = filteredSessions.sort((a, b) => compareAsc(parseISO(b.Date), parseISO(a.Date)));
            setFilteredUserSessions(sortedSessions);
        }

    }, [sessions, companyUsageTimeFilter, usersSessionsCompanyFilter, usersSessionsTimeFilter, userMachinesTimeFilter, userMachinesUserFilter]);

    //Compute chart data
    const computeChartData = (sessions, timeFilter, property, userFilter) => {
        const startDate = calculateStartDate(timeFilter);
        const endDate = new Date();

        const filteredSessions = sessions.filter(session => {
            const sessionDate = parseISO(session.Date);
            return sessionDate >= startDate && sessionDate <= endDate && (!userFilter || session.Email == userFilter);
        });

        const groupedData = filteredSessions.reduce((acc, session) => {
            const date = format(parseISO(session.Date), timeFilter === 'day' ? 'yyyy-MM-dd-HH-mm' : 'yyyy-MM-dd'); // Grouping format
            const displayDate = format(parseISO(session.Date), timeFilter === 'day' ? 'dd/MM HH:mm' : 'dd/MM'); // Display format
            if (!acc[date]) {
                acc[date] = { displayDate };
            }
            if (!acc[date][session[property]]) {
                acc[date][session[property]] = 0;
            }
            acc[date][session[property]] += 1;
            return acc;
        }, {});

        const formattedData = Object.keys(groupedData).sort((a, b) => {
            return new Date(a) - new Date(b); // Correctly sort the date strings
        }).map(date => {
            return {
                ...groupedData[date],
                date: groupedData[date].displayDate, // Use displayDate for the chart
            };
        });

        return formattedData;
    };

    // Compute users usage data
    const computeUsersUsageData = (sessions, timeFilter, companyFilter) => {
        const startDate = calculateStartDate(timeFilter);
        const endDate = new Date();

        const filteredSessions = sessions.filter(session => {
            const sessionDate = parseISO(session.Date);
            return sessionDate >= startDate && sessionDate <= endDate
                && (!companyFilter || companyFilter === 'All' || session.Company === companyFilter);
        });

        const groupedData = filteredSessions.reduce((acc, session) => {
            const user = session.Email;
            if (!acc[user]) {
                acc[user] = { user };
            }
            if (!acc[user][session.ClientMachine]) {
                acc[user][session.ClientMachine] = 0;
            }
            acc[user][session.ClientMachine] += 1;
            return acc;
        }, {});

        return Object.values(groupedData);
    };

    const hashStringToColor = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xFF;
            const darkValue = Math.floor(value * 0.75);
            color += ('00' + darkValue.toString(16)).slice(-2);
        }
        return color;
    };

    const calculateStartDate = (timeFilter) => {
        const endDate = new Date();
        let startDate;

        if (timeFilter === 'day') {
            startDate = subDays(endDate, 1);
        } else if (timeFilter === 'week') {
            startDate = subDays(endDate, 7);
        } else if (timeFilter === 'month') {
            startDate = subMonths(endDate, 1);
        } else {
            startDate = subYears(endDate, 1);
        }

        return startDate;
    };

    if (currentUser == null) {
        return null;
    }

    return (
        <Box sx={{ display: "flex" }}>
            <AppDrawer></AppDrawer>
            {currentUser?.role == "Root" && (
                <Box sx={{ overflow: "auto", bgcolor: '#f2f5f9', minHeight: 'calc(100vh - 64px)', padding: 2 }}>
                    <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                        <Typography variant="h4" color='text.primary' sx={{ mb: 2 }}>Usage Dashboard</Typography>
                        <Box>
                            {/* Company usage */}
                            <Card sx={{ mb: 2, padding: 3, transition: '0.3s' }}>
                                <Typography variant="h5" color='text.primary' sx={{ mb: 3 }}>
                                    Company usage
                                </Typography>
                                {sessionsLoading ?
                                    <CircularProgress size={24} />
                                    :
                                    <Box sx={{ display: 'flex' }}>
                                        <ResponsiveContainer height={300}>
                                            <LineChart data={companyUsageViewData} margin="5">
                                                <CartesianGrid strokeDasharray="5 5" verticalFill={['#f5f5f5', '#fff']} fillOpacity={0.2} />
                                                <XAxis dataKey="date" stroke={['#1D699D', '#fff']} tick={{ fontSize: 12, fill: theme.palette.text.secondary }} />
                                                <YAxis stroke={['#f5f5f5', '#fff']} tick={{ fontSize: 12, fill: theme.palette.text.secondary }} />
                                                <Tooltip cursor={{ fill: 'transparent' }} />
                                                <Legend />
                                                {sessions.length > 0 &&
                                                    Object.keys(sessions.reduce((acc, session) => ({ ...acc, [session.Company]: 0 }), {})).map(company => (
                                                        <Line type="monotone" key={company} dataKey={company} stroke={hashStringToColor(company)} />
                                                    ))}
                                            </LineChart>
                                        </ResponsiveContainer>
                                        <ToggleButtonGroup
                                            value={companyUsageTimeFilter}
                                            exclusive
                                            onChange={(event, newFilter) => setCompanyUsageTimeFilter(newFilter)}
                                            aria-label="Time Filter"
                                            orientation="vertical"
                                            sx={{ ml: 2 }}
                                        >
                                            <ToggleButton value="day" aria-label="Today">
                                                Today
                                            </ToggleButton>
                                            <ToggleButton value="week" aria-label="Last Week">
                                                Last Week
                                            </ToggleButton>
                                            <ToggleButton value="month" aria-label="Last Month">
                                                Last Month
                                            </ToggleButton>
                                            <ToggleButton value="year" aria-label="Last Year">
                                                Last Year
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Box>
                                }
                            </Card>

                            {/* Users usage */}
                            <Card sx={{ mb: 2, padding: 3, transition: '0.3s' }}>
                                <Typography variant="h5" color='text.primary' sx={{ mb: 3 }}>
                                    Users sessions
                                </Typography>
                                <Autocomplete
                                    options={allCompanies}
                                    value={usersSessionsCompanyFilter}
                                    onChange={(event, newFilter) => setUsersSessionsCompanyFilter(newFilter)}
                                    sx={{ mt: 1, mb: 2, width: 400 }}
                                    renderInput={(params) => <TextField {...params} label="Select Company" variant="outlined" />}
                                />
                                {sessionsLoading ?
                                    <CircularProgress size={24} />
                                    :
                                    <Box sx={{ display: 'flex' }}>
                                        <ResponsiveContainer height={300}>
                                            <BarChart
                                                data={usersUsageViewData} margin="5"
                                                onClick={(data) => {
                                                    if (data && data.activePayload && data.activePayload.length > 0) {
                                                        const clickedUser = data.activePayload[0].payload.user;
                                                        setUserMachinesUserFilter(clickedUser);
                                                    }
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="5 5" verticalFill={['#f5f5f5', '#fff']} fillOpacity={0.2} />
                                                <XAxis dataKey="user" stroke={['#1D699D', '#fff']} tick={{ fontSize: 12, fill: theme.palette.text.secondary }} />
                                                <YAxis stroke={['#f5f5f5', '#fff']} tick={{ fontSize: 12, fill: theme.palette.text.secondary }} />
                                                <Tooltip cursor={{ fill: 'transparent' }} />
                                                {usersUsageViewData.length > 0 &&
                                                    Array.from(new Set(usersUsageViewData.flatMap(Object.keys)))
                                                        .filter(key => key !== 'user' && key !== 'displayUser')
                                                        .map(key => (
                                                            <Bar key={key} dataKey={key} stackId="a" fill={hashStringToColor(key)} />
                                                        ))
                                                }
                                            </BarChart>
                                        </ResponsiveContainer>
                                        <ToggleButtonGroup
                                            value={usersSessionsTimeFilter}
                                            exclusive
                                            onChange={(event, newFilter) => setUsersSessionsTimeFilter(newFilter)}
                                            aria-label="Time Filter"
                                            orientation="vertical"
                                            sx={{ ml: 2 }}
                                        >
                                            <ToggleButton value="day" aria-label="Today">
                                                Today
                                            </ToggleButton>
                                            <ToggleButton value="week" aria-label="Last Week">
                                                Last Week
                                            </ToggleButton>
                                            <ToggleButton value="month" aria-label="Last Month">
                                                Last Month
                                            </ToggleButton>
                                            <ToggleButton value="year" aria-label="Last Year">
                                                Last Year
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Box>
                                }
                            </Card>

                            {/* User session */}
                            <Card sx={{ mb: 2, padding: 3, transition: '0.3s' }}>
                                <Typography variant="h5" color='text.primary' sx={{ mb: 3 }}>
                                    User machines
                                </Typography>
                                <Autocomplete
                                    options={allUsers}
                                    value={userMachinesUserFilter}
                                    onChange={(event, newFilter) => setUserMachinesUserFilter(newFilter)}
                                    sx={{ mt: 1, mb: 2, width: 400 }}
                                    renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
                                />
                                {sessionsLoading ?
                                    <CircularProgress size={24} />
                                    :
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Box sx={{ display: 'flex' }}>
                                            <ResponsiveContainer height={300}>
                                                <BarChart data={userMachinesViewData} margin="5">
                                                    <CartesianGrid strokeDasharray="5 5" verticalFill={['#f5f5f5', '#fff']} fillOpacity={0.2} />
                                                    <XAxis dataKey="date" stroke={['#1D699D', '#fff']} tick={{ fontSize: 12, fill: theme.palette.text.secondary }} />
                                                    <YAxis stroke={['#f5f5f5', '#fff']} tick={{ fontSize: 12, fill: theme.palette.text.secondary }} />
                                                    <Tooltip cursor={{ fill: 'transparent' }} />
                                                    <Legend />
                                                    {userMachinesViewData.length > 0 && userMachinesUserFilter &&
                                                        Array.from(new Set(userMachinesViewData.flatMap(Object.keys)))
                                                            .filter(key => key !== 'date' && key !== 'displayDate')
                                                            .map(key => (
                                                                <Bar key={key} dataKey={key} stackId="a" fill={hashStringToColor(key)} />
                                                            ))
                                                    }
                                                </BarChart>
                                            </ResponsiveContainer>
                                            <ToggleButtonGroup
                                                value={userMachinesTimeFilter}
                                                exclusive
                                                onChange={(event, newFilter) => setUserMachinesTimeFilter(newFilter)}
                                                aria-label="Time Filter"
                                                orientation="vertical"
                                                sx={{ ml: 2 }}
                                            >
                                                <ToggleButton value="day" aria-label="Today">
                                                    Today
                                                </ToggleButton>
                                                <ToggleButton value="week" aria-label="Last Week">
                                                    Last Week
                                                </ToggleButton>
                                                <ToggleButton value="month" aria-label="Last Month">
                                                    Last Month
                                                </ToggleButton>
                                                <ToggleButton value="year" aria-label="Last Year">
                                                    Last Year
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Box>

                                        {userMachinesUserFilter && (
                                            <TableContainer component={Paper} sx={{ mt: 3 }}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Date</TableCell>
                                                            <TableCell>Client Machine</TableCell>
                                                            <TableCell>Client Domain</TableCell>
                                                            <TableCell>IP</TableCell>
                                                            <TableCell>Username</TableCell>
                                                            <TableCell>Product</TableCell>
                                                            <TableCell>Version</TableCell>
                                                            <TableCell>Context</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {filteredUserSessions.map((session) => (
                                                            <TableRow key={session.SK}>
                                                                <TableCell>{format(parseISO(session.Date), 'd MMM yyyy HH:mm')}</TableCell>
                                                                <TableCell>{session.ClientMachine}</TableCell>
                                                                <TableCell>{session.ClientDomain}</TableCell>
                                                                <TableCell>{session.Ip}</TableCell>
                                                                <TableCell>{session.ClientName}</TableCell>
                                                                <TableCell>{session.Product}</TableCell>
                                                                <TableCell>{session.Version}</TableCell>
                                                                <TableCell>{session.Context}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        )}
                                    </Box>
                                }
                            </Card>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
}