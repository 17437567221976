import React, { useEffect } from 'react';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material';
import Navbar from './components/navbar/Navbar';
import AppNavbar from './components/navbar/AppNavBar';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate, Switch } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import { UserProvider } from './services/user/UserContext';
import { GoogleAnalyticsHandler } from './services/google/googleAnalytics';
import LanguageWrapper from './components/translation/LanguageWrapper';

import Home from './components/pages/home/Home';
import Services from './components/pages/services/Services';
import Products from './components/pages/products/Products';
import BCFNode from './components/pages/products/bcfnode/BCFNode';
import ModelSwap from './components/pages/products/modelswap/ModelSwap';
import NavisworksConnector from './components/pages/products/navisworksconnector/NavisworksConnector';
import SupportManager from './components/pages/products/supportmanager/SupportManager';
import LegalNotice from './components/pages/legalnotice/LegalNotice';
import Training from './components/pages/training/Training';
import Pml from './components/pages/training/pml/Pml';
import DotNet from './components/pages/training/dotnet/DotNet';
import Contact from './components/pages/contact/contact';
import About from './components/pages/about/about';
import AuthCallback from './components/pages/login/AuthCallback';

import AppPage from './components/pages/app/AppPage';
import AppDrawer from './components/drawer/AppDrawer';
import CompanyPage from './components/pages/app/CompanyPage';
import LicensesPage from './components/pages/app/LicensesPage';
import ProductsPage from './components/pages/app/ProductsPage';
import ProductPage from './components/pages/app/ProductPage';
import RootPage from './components/pages/app/RootPage';
import UsageDashboardPage from './components/pages/app/UsageDashboardPage';
import Login from './components/pages/login/Login';
import Register from './components/pages/login/Register';
import LostPassword from './components/pages/login/LostPassword';
import VerifyAccount from './components/pages/login/VerifyAccount';
import ChangePassword from './components/pages/login/ChangePassword';
import ProfilePage from './components/pages/app/ProfilePage';

let theme = createTheme({
  mode: 'light',
  palette: {
    primary: {
      main: '#1c79be',
    },
    secondary: {
      main: '#44546A',
    },
    text: {
      primary: '#000000',
      secondary: '#757575'
    }
  },
  typography: {
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
    allVariants: {
      color: '#2e2e2e'
    },
    h1: {
      fontWeight: 400,
      lineHeight: 1.2,
      letterSpacing: '0.0075em',
    },
    h2: {
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: '0.0075em',
    },
    h3: {
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: '0.0075em',
    },
    h4: {
      fontWeight: 400,
      lineHeight: 1.4,
      letterSpacing: '0.0075em',
    },
    h5: {
      fontWeight: 400,
      lineHeight: 1.4,
      letterSpacing: '0.0075em',
    },
    h6: {
      fontWeight: 400,
      lineHeight: 1.4,
      letterSpacing: '0.0075em',
    },
    body1: {
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontWeight: 400,
      lineHeight: 1.43,
    },
    p: {
      fontWeight: 400,
      lineHeight: 1.5,
      color: '#757575'
    },
  },
  components: {
    MuiMenu: {
      defaultProps: {
        elevation: 2,
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
  }
});

theme = responsiveFontSizes(theme);

function App() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("App.Title")}</title>
        <meta name="description" content={t("App.Description")} />
        <meta property="og:title" content={t("App.Description")} />
        <meta property="og:description" content={t("App.Description")} />
      </Helmet>

      <ThemeProvider theme={responsiveFontSizes(theme)}>
        <CssBaseline />
        {GetRouterByDomain()}
      </ThemeProvider>
    </>
  );
}

function mainRoutes() {
  return [
    <Route key="Home" path="" element={<Home />} />,
    <Route key="Services" path="services" element={<Services />} />,
    <Route key="Products" path="products" element={<Products />} />,
    <Route key="Products/BCFNode" path="products/bcfnode" element={<BCFNode />} />,
    <Route key="Products/ModelSwap" path="products/modelswap" element={<ModelSwap />} />,
    <Route key="Products/NavisworksConnector" path="products/navisworksconnector" element={<NavisworksConnector />} />,
    <Route key="Products/SupportManager" path="products/supportmanager" element={<SupportManager />} />,
    <Route key="Training" path="training" element={<Training />} />,
    <Route key="Training/Pml" path="training/pml" element={<Pml/>} />,
    <Route key="Training/DotNet" path="training/dotnet" element={<DotNet/>} />,
    <Route key="About" path="about" element={<About />} />,
    <Route key="Contact" path="contact" element={<Contact />} />,
    <Route key="LegalNotice" path="legalnotice" element={<LegalNotice />} />
  ];
}

function appRoutes() {
  return [
    <Route key="Home" path='' element={<AppPage />} />,
    <Route key="Company" path='company' element={<CompanyPage />} />,
    <Route key="Licenses" path='licenses' element={<LicensesPage />} />,
    <Route key="Products" path='products' element={<ProductsPage />} />,
    <Route key="Product" path='products/product' element={<ProductPage />} />,
    <Route key="Root" path='root' element={<RootPage />} />,
    <Route key="Root/Dashboard/Usage" path='root/dashboard/usage' element={<UsageDashboardPage />} />,
    <Route key="Auth/Register" path='auth/register' element={<Register />} />,
    <Route key="Auth/Verify" path='auth/verify-account' element={<VerifyAccount />} />,
    <Route key="Auth/Login" path='auth/login' element={<Login />} />,
    <Route key="Auth/LostPassword" path='auth/lost-password' element={<LostPassword />} />,
    <Route key="Auth/ChangePassword" path='auth/change-password' element={<ChangePassword />} />,
    <Route key="Auth/Callback" path="auth/callback" element={<AuthCallback />} />,
    <Route key="Profile" path='userprofile' element={<ProfilePage />} />
  ];
}

function GetRouterByDomain() {
  const hostname = window.location.hostname;
  const ismain = !hostname.toLocaleLowerCase().startsWith("app.");

  if (ismain) {
    return (
      <>
        <Router>
          <GoogleAnalyticsHandler />
          <LanguageWrapper>
            <Navbar />
            <Routes>
              {mainRoutes()}
              <Route path="/:lang/*" element={<Routes>{mainRoutes()}</Routes>} />
            </Routes>
          </LanguageWrapper>
        </Router>
      </>
    );
  } else {
    return (
      <>
        <Router>
          <GoogleAnalyticsHandler />
          <LanguageWrapper>
            <UserProvider>
              <AppNavbar />
              <Routes>
                {appRoutes()}
                <Route path="/:lang/*" element={<Routes>{appRoutes()}</Routes>} />
              </Routes>
            </UserProvider>
          </LanguageWrapper>
        </Router>
      </>
    );
  }
}

export const useIsAuthPage = () => {
  const authPageRegex = /(^\/[a-zA-Z]+|^)\/auth\//;
  const location = useLocation();
  const isAuthPage = authPageRegex.test(location.pathname);
  return isAuthPage;
}

export const useIsAppPage = () => {
  return !useIsAuthPage();
}

export default App;
