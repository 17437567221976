import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IngeloopLogo } from '../../images/logo/Logo.svg';
import { Card } from '@mui/material';

export default function AuthCallback() {
    const { t } = useTranslation();
    const theme = useTheme();
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '80vh'
                    }}>
                    <IngeloopLogo width={160}></IngeloopLogo>
                    <Typography component="h1" variant="h2" color="secondary.main" sx={{ mt: 2 }}>
                        Operation successful
                    </Typography>
                    <Typography component="h2" variant="h5" color="secondary.main" sx={{ mt: 2 }}>
                        You can close this page.
                    </Typography>
                </Box>
            </Container>
        </ThemeProvider>
    );
}