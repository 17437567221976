import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { VerifyUser, ResendOTP, ConfirmPassword, InitiateForgotPassword } from '../../../services/aws/cognito/Authentication';
import { useTranslation } from 'react-i18next';
import { getLanguagePrefix } from '../../translation/LanguageUrlHandler';

export default function VerifyAccount() {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const mode = location.state.mode;
    const email = location.state.email;
    const otpLength = location.state.otpLength;
    const session = location.state.session;
    const [loading, setLoading] = React.useState(false);
    const [backendError, setBackendError] = React.useState("");
    const [snackBarMessage, setSnackBarMessage] = React.useState("");
    const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);
    const [isVerified, setIsVerified] = React.useState(false);
    const [countdown, setCountdown] = React.useState(5);
    const [otp, setOtp] = React.useState('');
    const languagePrefix = getLanguagePrefix(location.pathname);
    const testPassword = "K$~RIGHdQmtdyFy";

    const theme = useTheme();
    const handleChange = (newValue) => {
        setOtp(newValue);
        if (newValue.length == otpLength) {
            if (loading) return;
            setLoading(true);

            if (mode == 'Register') {
                VerifyUser(email, newValue).then(() => {
                    setIsVerified(true);
                    const interval = setInterval(() => {
                        setCountdown(prevCount => prevCount - 1);
                    }, 1000);
                    setTimeout(() => {
                        clearInterval(interval);
                        navigate(`${languagePrefix}/auth/login`);
                    }, 5000);
                })
                    .catch(err => {
                        setBackendError(err.message);
                        setOtp("");
                    }).finally(() =>
                        setLoading(false))
            }
            else if (mode == 'LostPassword') {
                ConfirmPassword(email, newValue, testPassword)
                    .then((data) => {
                    })
                    .catch(err => {
                        // Expected Error
                        if (err.code === "InvalidPasswordException") {

                            navigate(`${languagePrefix}/auth/change-password`, { state: { email: email, otp: newValue, changePasswordMode: 'UserRequired' } });
                        }
                        else {
                            setBackendError(err.message);
                            setOtp("");
                        }
                    }).finally(() =>
                        setLoading(false))
            }
        }
    };

    const handleSendCodeAgain = () => {
        if (mode == 'Register') {
            ResendOTP(email).then((data) => {
                setSnackBarMessage(t("Forms.OTPNewCodeSent"));
                setIsSnackbarOpen(true);
            })
                .catch(err => {
                    setSnackBarMessage(err.message);
                    setIsSnackbarOpen(true);
                })
        }
        else if (mode == 'LostPassword') {
            InitiateForgotPassword(email).then((data) => {
                setSnackBarMessage(t("Forms.OTPNewCodeSent"));
                setIsSnackbarOpen(true);
            })
                .catch(err => {
                    setSnackBarMessage(err.message);
                    setIsSnackbarOpen(true);
                })
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsSnackbarOpen(false);
    };

    return (
        <ThemeProvider theme={theme}>
            {isVerified ? (
                <Container component="main" maxWidth="xs">
                    <Box sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <Typography variant="h6" color="secondary">
                            {t("Forms.OTPVerified")}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 2 }}>
                            {t("Forms.LoginRedirection", { countdown })}
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ mt: 4 }}
                            onClick={() => navigate(`${languagePrefix}/auth/login`)}>
                            {t("Forms.GotoLogin")}
                        </Button>
                    </Box>
                </Container>
            ) : (
                <Container component="main" maxWidth="xs">
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>

                        <Typography component="h1" variant="h4" color="secondary.main">
                            {t("Forms.EnterOTP")}
                        </Typography>

                        <Typography component="body" variant="body" sx={{ mt: 2 }} align="left">
                            {mode === 'Register' && t("Forms.OTPCompleteRegistration")}
                            {mode === 'LostPassword' && t("Forms.OTPResetPassword")}
                        </Typography>

                        {backendError && (
                            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                                {backendError}
                            </Typography>
                        )}

                        <MuiOtpInput
                            length={otpLength}
                            value={otp}
                            onChange={handleChange}
                            sx={{ mt: 2 }} />

                        {loading && (
                            <CircularProgress
                                size={36}
                                sx={{ mt: 4 }}
                            />
                        )}

                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link
                                    sx={{ mt: 2 }}
                                    component="button"
                                    variant="body2"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        handleSendCodeAgain();
                                    }}>
                                    {t("Forms.ResendOTP")}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>

                    <Snackbar
                        open={isSnackbarOpen}
                        autoHideDuration={4000}
                        onClose={handleSnackbarClose}
                        message={snackBarMessage}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}>
                        <SnackbarContent
                            message={snackBarMessage}
                            style={{ backgroundColor: theme.palette.secondary.main }}
                            action={
                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={handleSnackbarClose}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            } />
                    </Snackbar>
                </Container>)}
        </ThemeProvider>
    );
}