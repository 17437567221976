import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetCurrentValidUser, LogoutCurrentUser } from '../aws/cognito/Authentication';
import { createContext, useContext } from 'react';
import { useIsAuthPage } from '../../App';
import { UseAxiosInstance } from '../aws/api/AxiosInstance';
import { executeUserActions } from '../user/UserActions.js';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLanguagePrefix } from '../../components/translation/LanguageUrlHandler';

export const UserContext = createContext();

export const UseUser = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('UseUser must be used within a UserProvider');
    }
    return context;
};

export const UserProvider = ({ children }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null);
    const axiosInstance = UseAxiosInstance(currentUser);
    const isAuthPage = useIsAuthPage();
    const location = useLocation();
    const languagePrefix = getLanguagePrefix(location.pathname);

    useEffect(() => {
        if (!isAuthPage && currentUser == null) {
            GetCurrentValidUser()
                .then(user => {
                    if (user) {
                        setCurrentUser(user);
                        executeUserActions();
                    }
                    else {
                        navigate(`${languagePrefix}/auth/login`);
                    }
                })
                .catch(error => {
                    console.error("Signing error:", error);
                });
        }
    }, [navigate, currentUser]);

    const logout = () => {
        LogoutCurrentUser();
        setCurrentUser(null);
    };

    return (
        <UserContext.Provider value={{ currentUser, logout }}>
            {children}
        </UserContext.Provider>
    );
};