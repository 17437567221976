import React, {useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import './LogoConstruction.css';
import { event } from "jquery";
import { Timeline } from "gsap/gsap-core";

function LogoConstruction(param) {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        var mode = param.Mode;
        var windowHeight = window.innerHeight;
        var cubesOrder = [6,7,8,5,9,10,4,11,12,13,3,14,2,15,16,1,17,18];
        var stepPercent = windowHeight / 50 * cubesOrder.length / 16;
        var ease = "none";
        var markers = false;
        
        if (mode == "Scroll")
        {
          var percentStart = 0;
          var percentEnd = 0;
          for (let i = 0; i < cubesOrder.length; i++) {
            var leftCubeName = ".cube-left-" + cubesOrder[i];
            var rightCubeName = ".cube-right-" + cubesOrder[i];
            percentStart = 20 + (i * stepPercent);
            percentEnd = percentStart + 40;
            
            gsap.from(leftCubeName, {
            scrollTrigger: {
              trigger: ".logo-full",
              start: "top+=" + percentStart +"% bottom",
              end: "top+=" + percentEnd + "% bottom",
              scrub: true,
              markers: markers
            }, x: 2000, y: -100, ease: ease});
            
            gsap.to(leftCubeName, {
            scrollTrigger: {
              trigger: ".logo-full",
              start: "top+=" + percentStart +"% bottom",
              end: "top+=" + percentStart + "% bottom",
              scrub: true,
              markers: markers
            }, ease: ease, autoAlpha: 1});
            
            gsap.from(rightCubeName, {
            scrollTrigger: {
              trigger: ".logo-full",
              start: "top+=" + percentStart +"% bottom",
              end: "top+=" + percentEnd + "% bottom",
              scrub: true,
              markers: markers
            }, x: -2300, y: -100, ease: ease});
            
              gsap.to(rightCubeName, {
            scrollTrigger: {
              trigger: ".logo-full",
              start: "top+=" + percentStart +"% bottom",
              end: "top+=" + percentStart + "% bottom",
              scrub: true,
              markers: markers
            }, ease: ease, autoAlpha: 1});
          }
          
          percentStart = percentEnd -2;
          percentEnd+=5;
          gsap.to(".logo-full", {
            scrollTrigger: {
              trigger: ".logo-full",
              start: "top+=" + percentStart +"% bottom",
              end: "top+=" + percentEnd + "% bottom",
              scrub: true,
              markers: markers
            }, ease: ease, autoAlpha: 1});  
        }
        else if (mode == "Loop")
        {
          var timeLine = new Timeline({ repeat: -1 });
          cubesOrder = [18,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17];
          var duration = 0.05;
          var percentStart = 0;
          var percentEnd = 0;

          // const cubes = document.getElementsByClassName("cube");
          // for(var i = 0; i < cubes.length; i++)
          // {
          //   cubes[i].style.visibility = "visible";
          // }

          for (let i = 0; i < cubesOrder.length; i++) {
            var leftCubeName = ".cube-left-" + cubesOrder[i];
            var rightCubeName = ".cube-right-" + cubesOrder[i];

              timeLine.to(leftCubeName, {
                  delay: 0,
                  duration: duration,
                  ease: ease,
                  autoAlpha: 1});

                timeLine.to(rightCubeName, {
                  delay: 0,
                  duration: duration,
                  ease: ease,
                  autoAlpha: 1});
          }

          for (let i = 0; i < cubesOrder.length; i++) {
            var leftCubeName = ".cube-left-" + cubesOrder[i];
            var rightCubeName = ".cube-right-" + cubesOrder[i];

              timeLine.to(leftCubeName, {
                delay: 0,
                duration: duration,
                ease: ease,
                autoAlpha: 0});

              timeLine.to(rightCubeName, {
                delay: 0,
                duration: duration,
                ease: ease,
                autoAlpha: 0});
          }

          timeLine.play();
        }
      }, []);

    return (
        <>
        <div className="logo-container">
            <svg className="container" xmlns="http://www.w3.org/2000/svg" viewBox="-866.25 -500 1558.845 800">
                <g className="cube-left-18 cube left-start" transform="translate(0,100)">
                  <polygon fill='#0c3c7a' points="0,150 0,50 86.6025,100 86.6025,200"/>
                  <polygon fill='#7ad6fa' points="0,50 86.6025,0 173.2051,50 86.6025,100"/>
                  <polygon fill='#1c79be' points="173.2051,50 173.2051,150 86.6025,200 86.6025,100"/>
                </g>
                <g className="cube-left-1 cube left-start" transform="translate(0,0)">
                  <polygon fill='#0c3c7a' points="0,150 0,50 86.6025,100 86.6025,200"/>
                  <polygon fill='#7ad6fa' points="0,50 86.6025,0 173.2051,50 86.6025,100"/>
                  <polygon fill='#1c79be' points="173.2051,50 173.2051,150 86.6025,200 86.6025,100"/>
                </g>
                <g className="cube-left-2 cube left-start" transform="translate(0,-100)">
                  <polygon fill='#0c3c7a' points="0,150 0,50 86.6025,100 86.6025,200"/>
                  <polygon fill='#7ad6fa' points="0,50 86.6025,0 173.2051,50 86.6025,100"/>
                  <polygon fill='#1c79be' points="173.2051,50 173.2051,150 86.6025,200 86.6025,100"/>
                </g>
                <g className="cube-left-3 cube left-start" transform="translate(0,-200)">
                  <polygon fill='#0c3c7a' points="0,150 0,50 86.6025,100 86.6025,200"/>
                  <polygon fill='#7ad6fa' points="0,50 86.6025,0 173.2051,50 86.6025,100"/>
                  <polygon fill='#1c79be' points="173.2051,50 173.2051,150 86.6025,200 86.6025,100"/>
                </g>
                <g className="cube-left-4 cube left-start" transform="translate(0,-300)">
                  <polygon fill='#0c3c7a' points="0,150 0,50 86.6025,100 86.6025,200"/>
                  <polygon fill='#7ad6fa' points="0,50 86.6025,0 173.2051,50 86.6025,100"/>
                  <polygon fill='#1c79be' points="173.2051,50 173.2051,150 86.6025,200 86.6025,100"/>
                </g>
                  <g className="cube-left-5 cube left-start" transform="translate(0,-400)">
                  <polygon fill='#0c3c7a' points="0,150 0,50 86.6025,100 86.6025,200"/>
                  <polygon fill='#7ad6fa' points="0,50 86.6025,0 173.2051,50 86.6025,100"/>
                  <polygon fill='#1c79be' points="173.2051,50 173.2051,150 86.6025,200 86.6025,100"/>
                </g>
                <g className="cube-left-6 cube left-start" transform="translate(0,-500)">
                  <polygon fill='#0c3c7a' points="0,150 0,50 86.6025,100 86.6025,200"/>
                  <polygon fill='#7ad6fa' points="0,50 86.6025,0 173.2051,50 86.6025,100"/>
                  <polygon fill='#1c79be' points="173.2051,50 173.2051,150 86.6025,200 86.6025,100"/>
                </g>

                <g className="cube-left-7 cube left-start" transform="translate(86.6025,-450)">
                  <polygon fill='#0c3c7a' points="0,150 0,50 86.6025,100 86.6025,200"/>
                  <polygon fill='#7ad6fa' points="0,50 86.6025,0 173.2051,50 86.6025,100"/>
                  <polygon fill='#1c79be' points="173.2051,50 173.2051,150 86.6025,200 86.6025,100"/>
                </g>
                <g className="cube-left-8 cube left-start" transform="translate(173.205,-400)">
                  <polygon fill='#0c3c7a' points="0,150 0,50 86.6025,100 86.6025,200"/>
                  <polygon fill='#7ad6fa' points="0,50 86.6025,0 173.2051,50 86.6025,100"/>
                  <polygon fill='#1c79be' points="173.2051,50 173.2051,150 86.6025,200 86.6025,100"/>
                </g>
                <g className="cube-left-9 cube left-start" transform="translate(259.8075,-350)">
                  <polygon fill='#0c3c7a' points="0,150 0,50 86.6025,100 86.6025,200"/>
                  <polygon fill='#7ad6fa' points="0,50 86.6025,0 173.2051,50 86.6025,100"/>
                  <polygon fill='#1c79be' points="173.2051,50 173.2051,150 86.6025,200 86.6025,100"/>
                </g>
                <g className="cube-left-10 cube left-start" transform="translate(346.41,-300)">
                  <polygon fill='#0c3c7a' points="0,150 0,50 86.6025,100 86.6025,200"/>
                  <polygon fill='#7ad6fa' points="0,50 86.6025,0 173.2051,50 86.6025,100"/>
                  <polygon fill='#1c79be' points="173.2051,50 173.2051,150 86.6025,200 86.6025,100"/>
                </g>
                <g className="cube-left-11 cube left-start" transform="translate(433.0125,-250)">
                  <polygon fill='#0c3c7a' points="0,150 0,50 86.6025,100 86.6025,200"/>
                  <polygon fill='#7ad6fa' points="0,50 86.6025,0 173.2051,50 86.6025,100"/>
                  <polygon fill='#1c79be' points="173.2051,50 173.2051,150 86.6025,200 86.6025,100"/>
                </g>
                <g className="cube-left-12 cube left-start" transform="translate(519.615,-200)">
                  <polygon fill='#0c3c7a' points="0,150 0,50 86.6025,100 86.6025,200"/>
                  <polygon fill='#7ad6fa' points="0,50 86.6025,0 173.2051,50 86.6025,100"/>
                  <polygon fill='#1c79be' points="173.2051,50 173.2051,150 86.6025,200 86.6025,100"/>
                </g>

                <g className="cube-left-13 cube left-start" transform="translate(433.0125,-150)">
                  <polygon fill='#0c3c7a' points="0,150 0,50 86.6025,100 86.6025,200"/>
                  <polygon fill='#7ad6fa' points="0,50 86.6025,0 173.2051,50 86.6025,100"/>
                  <polygon fill='#1c79be' points="173.2051,50 173.2051,150 86.6025,200 86.6025,100"/>
                </g>
                <g className="cube-left-14 cube left-start" transform="translate(346.41,-100)">
                  <polygon fill='#0c3c7a' points="0,150 0,50 86.6025,100 86.6025,200"/>
                  <polygon fill='#7ad6fa' points="0,50 86.6025,0 173.2051,50 86.6025,100"/>
                  <polygon fill='#1c79be' points="173.2051,50 173.2051,150 86.6025,200 86.6025,100"/>
                </g>
                <g className="cube-left-15 cube left-start" transform="translate(259.8075,-50)">
                  <polygon fill='#0c3c7a' points="0,150 0,50 86.6025,100 86.6025,200"/>
                  <polygon fill='#7ad6fa' points="0,50 86.6025,0 173.2051,50 86.6025,100"/>
                  <polygon fill='#1c79be' points="173.2051,50 173.2051,150 86.6025,200 86.6025,100"/>
                </g>
                <g className="cube-left-16 cube left-start" transform="translate(173.205,0)">
                  <polygon fill='#0c3c7a' points="0,150 0,50 86.6025,100 86.6025,200"/>
                  <polygon fill='#7ad6fa' points="0,50 86.6025,0 173.2051,50 86.6025,100"/>
                  <polygon fill='#1c79be' points="173.2051,50 173.2051,150 86.6025,200 86.6025,100"/>
                </g>
                <g className="cube-left-17 cube left-start" transform="translate(86.6025,50)">
                  <polygon fill='#7ad6fa' points="86.6025,0 173.2051,50 86.6025,100"/>
                  <polygon fill='#1c79be' points="173.2051,50 173.2051,150 86.6025,200 86.6025,100"/>
                </g>

                 <g className="cube-right-16 cube right-start" transform="translate(-519.615,0)">
                  <polygon fill='#1c79be' points="0,150 0,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#7ad6fa' points="173.2051,150 173.2051,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#0c3c7a' points="0,150 86.6025,200 173.2051,150 86.6025,100"/>
                 </g>   
                 <g className="cube-right-15 cube right-start" transform="translate(-606.2175,-50)">
                  <polygon fill='#1c79be' points="0,150 0,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#7ad6fa' points="173.2051,150 173.2051,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#0c3c7a' points="0,150 86.6025,200 173.2051,150 86.6025,100"/>
                 </g> 
                 <g className="cube-right-14 cube right-start" transform="translate(-692.82,-100)">
                  <polygon fill='#1c79be' points="0,150 0,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#7ad6fa' points="173.2051,150 173.2051,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#0c3c7a' points="0,150 86.6025,200 173.2051,150 86.6025,100"/>
                 </g> 
                 <g className="cube-right-13 cube right-start" transform="translate(-779.4225,-150)">
                  <polygon fill='#1c79be' points="0,150 0,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#7ad6fa' points="173.2051,150 173.2051,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#0c3c7a' points="0,150 86.6025,200 173.2051,150 86.6025,100"/>
                 </g>  

                 <g className="cube-right-12 cube right-start" transform="translate(-866.025,-200)">
                  <polygon fill='#1c79be' points="0,150 0,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#7ad6fa' points="173.2051,150 173.2051,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#0c3c7a' points="0,150 86.6025,200 173.2051,150 86.6025,100"/>
                </g>  
                 <g className="cube-right-11 cube right-start" transform="translate(-779.4225,-250)">
                  <polygon fill='#1c79be' points="0,150 0,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#7ad6fa' points="173.2051,150 173.2051,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#0c3c7a' points="0,150 86.6025,200 173.2051,150 86.6025,100"/>
                </g> 
                 <g className="cube-right-10 cube right-start" transform="translate(-692.82,-300)">
                  <polygon fill='#1c79be' points="0,150 0,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#7ad6fa' points="173.2051,150 173.2051,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#0c3c7a' points="0,150 86.6025,200 173.2051,150 86.6025,100"/>
                </g>
                 <g className="cube-right-9 cube right-start" transform="translate(-606.2175,-350)">
                  <polygon fill='#1c79be' points="0,150 0,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#7ad6fa' points="173.2051,150 173.2051,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#0c3c7a' points="0,150 86.6025,200 173.2051,150 86.6025,100"/>
                </g>
                 <g className="cube-right-8 cube right-start" transform="translate(-519.615,-400)">
                  <polygon fill='#1c79be' points="0,150 0,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#7ad6fa' points="173.2051,150 173.2051,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#0c3c7a' points="0,150 86.6025,200 173.2051,150 86.6025,100"/>
                </g>
                <g className="cube-right-7 cube right-start" transform="translate(-433.0125,-450)">
                  <polygon fill='#1c79be' points="0,150 0,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#7ad6fa' points="173.2051,150 173.2051,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#0c3c7a' points="0,150 86.6025,200 173.2051,150 86.6025,100"/>
                </g>

                <g className="cube-right-6 cube right-start" transform="translate(-346.41,-500)">
                  <polygon fill='#1c79be' points="0,150 0,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#7ad6fa' points="173.2051,150 173.2051,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#0c3c7a' points="0,150 86.6025,200 173.2051,150 86.6025,100"/>
                </g>
                <g className="cube-right-5 cube right-start" transform="translate(-346.41,-400)">
                  <polygon fill='#1c79be' points="0,150 0,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#7ad6fa' points="173.2051,150 173.2051,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#0c3c7a' points="0,150 86.6025,200 173.2051,150 86.6025,100"/>
                </g>
                <g className="cube-right-4 cube right-start" transform="translate(-346.41,-300)">
                  <polygon fill='#1c79be' points="0,150 0,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#7ad6fa' points="173.2051,150 173.2051,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#0c3c7a' points="0,150 86.6025,200 173.2051,150 86.6025,100"/>
                </g>
                <g className="cube-right-3 cube right-start" transform="translate(-346.41,-200)">
                  <polygon fill='#1c79be' points="0,150 0,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#7ad6fa' points="173.2051,150 173.2051,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#0c3c7a' points="0,150 86.6025,200 173.2051,150 86.6025,100"/>
                </g>
                <g className="cube-right-2 cube right-start" transform="translate(-346.41,-100)">
                  <polygon fill='#1c79be' points="0,150 0,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#7ad6fa' points="173.2051,150 173.2051,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#0c3c7a' points="0,150 86.6025,200 173.2051,150 86.6025,100"/>
                </g>
                <g className="cube-right-1 cube right-start" transform="translate(-346.41,0)">
                  <polygon fill='#1c79be' points="0,150 0,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#7ad6fa' points="173.2051,150 173.2051,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#0c3c7a' points="0,150 86.6025,200 173.2051,150 86.6025,100"/>
                </g>



                <g className="cube-right-18 cube right-start" transform="translate(-346.41,100)">
                  <polygon fill='#1c79be' points="0,150 0,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#7ad6fa' points="173.2051,150 173.2051,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#0c3c7a' points="0,150 86.6025,200 173.2051,150 86.6025,100"/>
                 </g>
                 <g className="cube-right-17 cube right-start" transform="translate(-433.0125,50)">
                  <polygon fill='#7ad6fa' points="173.2051,150 173.2051,50 86.6025,0 86.6025,100"/>
                  <polygon fill='#0c3c7a' points="0,150 86.6025,200 173.2051,150 86.6025,100"/>
                 </g>

                <g className="logo-full">
                    <g>
                      <polygon fill='#0c3c7a' points="0,250 0,-450 519.615,-150 433.0125,-100 86.6025,-300 86.6025,300"/>
                      <polygon fill='#7ad6fa' points="0,-450 86.6025,-500 692.82,-150 173.2051,150 173.2051,50 519.615,-150"/>
                      <polygon fill='#1c79be' points="173.2051,-250 173.2051,150 692.82,-150 692.82,-50 86.6025,300 86.6025,-300"/>
                    </g>
                    <g>
                      <polygon fill='#0c3c7a' points="-173.205,250 -259.8075,300 -866.025,-50 -346.41,-350 -346.41,-250 -692.82,-50"/>
                      <polygon fill='#7ad6fa' points="-173.205,150 -173.205,-450 -259.8075,-500 -259.8075,100 -606.2175,-100 -692.82,-50 -173.205,250"/>
                      <polygon fill='#1c79be' points="-259.8075,100 -259.8075,-500 -866.025,-150 -866.025,-50 -346.41,-350 -346.41,50"/>
                    </g>
                </g>
            </svg>
        </div>
        </>
    );
}

export default LogoConstruction