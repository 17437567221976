import * as React from 'react';
import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogContent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { json, useNavigate } from "react-router-dom";
import { UseUser } from '../../../services/user/UserContext';
import AppDrawer from '../../drawer/AppDrawer';
import { UseAxiosInstance } from '../../../services/aws/api/AxiosInstance';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLanguagePrefix } from '../../translation/LanguageUrlHandler';
import { base64ToByteArray } from '../../../utilities/TypeConversion';

export default function RootPage() {
    const theme = useTheme();
    const { t } = useTranslation();
    const { currentUser } = UseUser();
    const axiosInstance = UseAxiosInstance(currentUser);
    const navigate = useNavigate();

    const [snackBarMessage, setSnackBarMessage] = React.useState("");
    const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);

    const [createCompanyPopupOpen, setCreateCompanyPopupOpen] = useState(false);
    const [createCompanyLoading, setCreateCompanyLoading] = useState(false);
    const [createCompanyError, setCreateCompanyError] = useState("");
    const [companyId, setCompanyId] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyRegex, setCompanyRegex] = useState('@(.*)(company\.com|ingeloop\.com)');
    const [companyImage, setCompanyImage] = useState('https://logo.clearbit.com/company.com');

    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [loadingCompanies, setLoadingCompanies] = useState(false);

    const [inviteUserPopupOpen, setInviteUserPopupOpen] = useState(false);
    const [inviteLoading, setInviteLoading] = useState(false);
    const [inviteError, setInviteError] = useState("");
    const [selectedRole, setSelectedRole] = React.useState('User');

    const location = useLocation();
    const languagePrefix = getLanguagePrefix(location.pathname);

    useEffect(() => {
        if (!currentUser || !axiosInstance) return;
        const fetchCompanies = async () => {
            setLoadingCompanies(true);
            try {
                const response = await axiosInstance.get(`/companies`);
                const companies = JSON.parse(response.data);
                setCompanies(companies);
            } catch (error) {
                console.error('Failed to fetch companies:', error);
            }
            setSelectedCompany(currentUser.company)
            setLoadingCompanies(false);
        };
        fetchCompanies();

    }, [currentUser, axiosInstance]);

    const handleCreateCompanyPopupOpen = () => {
        setCreateCompanyPopupOpen(true);
    };

    const handleCreateCompanyPopupClose = () => {
        setCreateCompanyPopupOpen(false);
    };

    const handleCreateCompany = async (id, name, validationRegex, imageUrl) => {
        setCreateCompanyLoading(true);
        setCreateCompanyError("");

        try {
            await axiosInstance.post(`/companies`, {
                id,
                name,
                validationRegex,
                imageUrl
            });

            handleCreateCompanyPopupClose();

            setCompanyName('');
            setCompanyRegex('');
            setCompanyImage('');
        } catch (error) {
            console.log(error);
            setCreateCompanyError("Failed to create company. Please try again.");
        }

        setCreateCompanyLoading(false);
    };

    const handleChangeCompany = async (company) => {
        try {
            const userData = { company: company };
            await axiosInstance.patch(`/users/${currentUser.username}/profile`, userData);
            setSelectedCompany(company);

            setSnackBarMessage(`Company changed!`);
            setIsSnackbarOpen(true);
        } catch (error) {
            setSnackBarMessage(`Failed to change Company: ${error.message}`);
            setIsSnackbarOpen(true);
        }
    }

    const handleInviteUserPopupOpen = () => {
        setInviteUserPopupOpen(true);
    };

    const handleInviteUserPopupClose = () => {
        setInviteUserPopupOpen(false);
    };

    const handleInvite = async (email, role) => {
        setInviteLoading(true);
        setInviteError("");

        try {
            await axiosInstance.post(`/users/invitations`, {
                email: email,
                role: role
            });

            handleInviteUserPopupClose();
        } catch (error) {
            console.log(error);
            setInviteError("Failed to invite the user. Please try again.");
        }

        setInviteLoading(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsSnackbarOpen(false);
    };

    if (currentUser == null) {
        return;
    }

    return (
        <Box sx={{ display: "flex" }}>
            <AppDrawer></AppDrawer>
            {currentUser?.role == "Root" && (
                <Box sx={{ overflow: "auto", bgcolor: '#f2f5f9', minHeight: 'calc(100vh - 64px)', padding: 2 }}>
                    <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                        <Typography variant="h4" color='text.primary' sx={{ mb: 2 }}>Root Settings</Typography>
                        <Box>
                            <Paper elevation={1} sx={{ padding: 2, marginBottom: 2, borderRadius: 2 }}>
                                <Typography variant="h6" sx={{ mb: 2 }}>Companies</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                    <FormControl sx={{}}>
                                        <InputLabel id="company-select-label">Active Company</InputLabel>
                                        <Select
                                            labelId="company-select-label"
                                            id="company-select"
                                            value={selectedCompany}
                                            onChange={(event) => handleChangeCompany(event.target.value)}
                                            label="Company"
                                            sx={{ maxWidth: '320px' }}
                                        >
                                            {loadingCompanies ? (
                                                <MenuItem value="" key="loading">
                                                    <CircularProgress size={24} />
                                                </MenuItem>
                                            ) : (
                                                companies.map(company => (
                                                    <MenuItem key={company.name} value={company.SK?.split('#')[1]}>
                                                        {company.name}
                                                    </MenuItem>
                                                ))
                                            )}
                                        </Select>
                                    </FormControl>
                                    <Box sx={{ display: 'flex' }}>
                                        <Button variant="contained" color="primary" onClick={handleCreateCompanyPopupOpen}>
                                            Create New Company
                                        </Button>
                                    </Box>
                                </Box>
                            </Paper>

                            <Dialog open={createCompanyPopupOpen} onClose={handleCreateCompanyPopupClose}>
                                <DialogTitle variant="h5" color="text.primary" fontWeight={500}>Create new company</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        The user will receive an email allowing him to create an account.
                                    </DialogContentText>
                                    <TextField
                                        sx={{ mt: 2 }}
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Company Id"
                                        type="text"
                                        fullWidth
                                        value={companyId}
                                        onChange={e => setCompanyId(e.target.value)}
                                    />
                                    <TextField
                                        sx={{ mt: 2 }}
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Company Name"
                                        type="text"
                                        fullWidth
                                        value={companyName}
                                        onChange={e => setCompanyName(e.target.value)}
                                    />
                                    <TextField
                                        sx={{ mt: 2 }}
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Email Regex"
                                        type="text"
                                        fullWidth
                                        value={companyRegex}
                                        onChange={e => setCompanyRegex(e.target.value)}
                                    />
                                    <TextField
                                        sx={{ mt: 2 }}
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Image Url"
                                        type="text"
                                        fullWidth
                                        value={companyImage}
                                        onChange={e => setCompanyImage(e.target.value)}
                                    />
                                    {companyImage && (
                                        <div style={{ width: '100px', height: '100px', marginTop: 2, overflow: 'hidden' }}>
                                            <img src={companyImage} alt="Company" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                        </div>
                                    )}
                                    {createCompanyError && (
                                        <Typography color="error" variant="body2" style={{ margin: '0 0 10px' }}>
                                            {createCompanyError}
                                        </Typography>
                                    )}
                                    {createCompanyLoading && (
                                        <CircularProgress size={24} style={{ margin: '10px' }} />
                                    )}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCreateCompanyPopupClose}>Cancel</Button>
                                    <Button onClick={() => handleCreateCompany(companyId, companyName, companyRegex, companyImage)}>Create</Button>
                                </DialogActions>
                            </Dialog>
                        </Box>
                        <Box>
                            <Paper elevation={1} sx={{ padding: 2, marginBottom: 2, borderRadius: 2 }}>
                                <Typography variant="h6" sx={{ mb: 2 }}>Users</Typography>
                                <Button variant="contained" color="primary" onClick={handleInviteUserPopupOpen}>
                                    Create New User
                                </Button>
                            </Paper>

                            <Dialog open={inviteUserPopupOpen} onClose={handleInviteUserPopupClose}>
                                <DialogTitle variant="h5" color="text.primary" fontWeight={500}>Create new user</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        The user will receive an email allowing him to create an account.
                                    </DialogContentText>
                                    <TextField
                                        sx={{ mt: 2 }}
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Email Address"
                                        type="email"
                                        fullWidth
                                        variant="standard"
                                    />
                                    <FormControl variant="standard" fullWidth margin="dense" sx={{ mt: 2 }}>
                                        <InputLabel id="role-label">Role</InputLabel>
                                        <Select
                                            labelId="role-label"
                                            id="role"
                                            value={selectedRole}
                                            onChange={(event) => setSelectedRole(event.target.value)}>
                                            <MenuItem value="User">User</MenuItem>
                                            <MenuItem value="Admin">Admin</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {inviteError && (
                                        <Typography color="error" variant="body2" style={{ margin: '0 0 10px' }}>
                                            {inviteError}
                                        </Typography>
                                    )}
                                    {inviteLoading && (
                                        <CircularProgress size={24} style={{ margin: '10px' }} />
                                    )}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleInviteUserPopupClose}>Cancel</Button>
                                    <Button onClick={() => handleInvite(document.querySelector('input[type="email"]').value, selectedRole)}>Invite</Button>
                                </DialogActions>
                            </Dialog>
                        </Box>

                        <Box>
                            <Paper elevation={1} sx={{ padding: 2, marginBottom: 2, borderRadius: 2 }}>
                                <Typography variant="h6" sx={{ mb: 2 }}>Visualization</Typography>
                                <Button variant="contained" color="primary" onClick={() => navigate(`dashboard/usage`)}>
                                    Usage dashboard
                                </Button>
                            </Paper>
                        </Box>
                    </Box>
                </Box>
            )}
            <Snackbar
                open={isSnackbarOpen}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                message={snackBarMessage}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}>
                <SnackbarContent
                    message={snackBarMessage}
                    style={{ backgroundColor: theme.palette.secondary.main }}
                    action={
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleSnackbarClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    } />
            </Snackbar>
        </Box >
    );
}