import React from 'react';
import './Footer.css';
import { Link, useLocation } from 'react-router-dom';
import { default as AppLogo } from '../images/logo/Ingeloop-light.svg';
import '../styles/SocialButtons.css'
import { useTranslation } from 'react-i18next';
import { getLanguagePrefix } from '../translation/LanguageUrlHandler';

function Footer() {
  const { t } = useTranslation();
  const location = useLocation();
  const languagePrefix = getLanguagePrefix(location.pathname);
  const currentYear = new Date().getFullYear();

  return (
    <div className='footer-container'>
      <div className='footer-content'>
        <div className='footer-logo'>
          <Link to={`${languagePrefix}/`}>
            <img src={AppLogo} alt="Ingeloop Logo" height="40px"></img>
          </Link>
        </div>

        <div className='footer-links'>     
          <div className="footer-links-items-bottom">
            <a
              href={`${languagePrefix}/contact`}
              className="footer-nav-links">
                {t("Footer.Contact")}
            </a>
    
            <a
              href={`${languagePrefix}/legalnotice`}
              className="footer-nav-links">
                {t("Footer.LegalNotice")}
            </a>
          </div>
        </div>

        <div className='social-icons'>
            <div className="wrapper">
              <a href="https://www.youtube.com/channel/UC8PBJJ8y8o_74Thqn7r2HKA" target="_blank" rel="noopener noreferrer" className="icon youtube">
               <div className="tooltip">Youtube</div>
                  <span>
                    <i className="fab fa-youtube"></i>
                  </span>
              </a>
              <a href="https://www.linkedin.com/company/ingeloop/" target="_blank" rel="noopener noreferrer" className="icon linkedin">
               <div className="tooltip">Linkedin</div>
                  <span>
                    <i className="fab fa-linkedin"></i>
                  </span>
              </a>
            </div>
        </div>
      </div>

      <div className='footer-rights'>
        <small
          className='website-rights'>Ingeloop © {currentYear}
        </small>
      </div>

    </div>
  );
}

export default Footer;
