import React from 'react';
import { Helmet } from "react-helmet";
import { useEffect } from 'react';
import { useNavigationType } from 'react-router-dom';
import { Container, Grid, Typography, Paper, Button, Tooltip, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Footer from '../../footer/Footer';
import LSImage from '../../images/profile/LS-circle.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function About() {
  const { t } = useTranslation();
  const navigationType = useNavigationType();

  useEffect(() => {
    if (navigationType === "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [navigationType]);

  return (
    <>
      <Helmet>
        <title>{t("Meta.About.Title")}</title>
        <meta name="description" content={t("Meta.About.Description")} />
      </Helmet>

      <Box display="flex" flexDirection="column" minHeight="calc(100vh - 54px)">
        <Container maxWidth="lg">
          <Grid container spacing={3} sx={{ mt: 4, mb: 5 }}>
            <Grid item xs={12} md={4} container alignItems="center" style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={LSImage} alt="Léo Salvador" style={{ width: '100%', height: 'auto', maxWidth: "200px" }} />
            </Grid>
            <Grid item xs={12} md={8} sx={{ px: { xs: 4, md: 0 } }}>
              <Typography variant="h2" color="text.primary" >Léo SALVADOR</Typography>
              <Typography variant="h4" color="text.secondary">{t("About.JobTitle")}</Typography>
              <Typography sx={{ mt: 4, whiteSpace: 'pre-line' }} display="block" variant="body1">{t("About.Description")}</Typography>

              <Button
                sx={{ mt: 2 }}
                variant="contained"
                startIcon={<LinkedInIcon />}
                color="primary"
                href="https://www.linkedin.com/in/leo-salvador/"
                target="_blank" rel="noopener noreferrer">
                {t("About.SeeLinkedInProfile")}
              </Button>
            </Grid>
          </Grid>
        </Container>

        <Box flexGrow={1}></Box>
        <Footer />
      </Box>
    </>
  );
}
