import React from 'react';
import { Container, Typography, Link, Paper } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { ReactComponent as AppLogo } from '../../images/logo/Ingeloop.svg';

export default function LegalNotice() {
  const { t } = useTranslation();

  return (
    <Container component={Paper} sx={{ padding: 3, mt: 5, mb: 5 }}>
      <Typography variant="h1" color='text.primary'>{t("LegalNotice.Title")}</Typography>

      <Typography component="h2" variant="h3" color='text.primary' sx={{ my: 2 }}>{t("LegalNotice.EditorTitle")}</Typography>

      <a href="https://www.ingeloop.com" aria-label="Home" target="_blank" rel="noopener noreferrer" >
        <AppLogo width="160px"></AppLogo>
      </a>

      <Typography variant="body1" sx={{ mt: 1, whiteSpace:'pre-line' }}>{t("LegalNotice.EditorContact")}</Typography>

      <Typography component="h2" variant="h3" color='text.primary' sx={{ mt: 2, whiteSpace:'pre-line' }}>{t("LegalNotice.EditionResponsibleTitle")}</Typography>
      <Typography variant="body1">Léo SALVADOR</Typography>

      <Typography component="h2" variant="h3" color='text.primary' sx={{ mt: 2, whiteSpace:'pre-line' }}>{t("LegalNotice.HostTitle")}</Typography>
      <Typography variant="body1">
        {t("LegalNotice.DomainName")}: <Link href="https://www.domains.google/" target="_blank" rel="noopener noreferrer">Google Domains</Link>
      </Typography>
      <Typography variant="body1">
        {t("LegalNotice.HostName")}: <Link href="https://aws.amazon.com/" target="_blank" rel="noopener noreferrer">Amazon Web Services</Link>
      </Typography>

      <Typography component="h2" variant="h3" color='text.primary' sx={{ mt: 2, whiteSpace:'pre-line' }}>{t("LegalNotice.TermsTitle")}</Typography>

      <Typography component="h3" variant="h4" color='text.primary' sx={{ mt: 1 }}>{t("LegalNotice.AcessConditionsTitle")}</Typography>
      <Typography variant="body1" sx={{ mt: 1, whiteSpace:'pre-line' }}><Trans i18nKey="LegalNotice.AcessConditionsContent"> <Link href="https://www.ingeloop.com" target="_blank" rel="noopener noreferrer">www.ingeloop.com</Link></Trans></Typography>

      <Typography component="h3" variant="h4" color='text.primary' sx={{ mt: 1 }}>{t("LegalNotice.ResponsabilityTitle")}</Typography>
      <Typography variant="body1" sx={{ mt: 1, whiteSpace:'pre-line' }}><Trans i18nKey="LegalNotice.ResponsabilityContent"> <Link href="https://www.ingeloop.com" target="_blank" rel="noopener noreferrer">www.ingeloop.com</Link></Trans></Typography>

      <Typography component="h3" variant="h4" color='text.primary' sx={{ mt: 1 }}>{t("LegalNotice.IntellectualPropertyTitle")}</Typography>
      <Typography variant="body1" sx={{ mt: 1, whiteSpace:'pre-line' }}>{t("LegalNotice.IntellectualPropertyContent")}</Typography>

      <Typography component="h3" variant="h4" color='text.primary' sx={{ mt: 1 }}>{t("LegalNotice.HypertextLinksTitle")}</Typography>
      <Typography variant="body1" sx={{ mt: 1, whiteSpace:'pre-line' }}><Trans i18nKey="LegalNotice.HypertextLinksContent"> <Link href="https://www.ingeloop.com" target="_blank" rel="noopener noreferrer">www.ingeloop.com</Link></Trans></Typography>
    </Container>
  );
}
