import * as React from 'react';
import { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { json, useNavigate, useSearchParams } from "react-router-dom";
import { UseUser } from '../../../services/user/UserContext';
import AppDrawer from '../../drawer/AppDrawer';
import { UseAxiosInstance } from '../../../services/aws/api/AxiosInstance';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ProductsData from '../products/Products.json'
import { Container } from 'react-bootstrap';
import { base64ToByteArray } from '../../../utilities/TypeConversion';

export default function ProductPage() {
    const theme = useTheme();
    const { t } = useTranslation();
    const { currentUser } = UseUser();
    const axiosInstance = UseAxiosInstance(currentUser);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [productData, setProductData] = useState([]);
    const [productMeta, setProductMeta] = useState([]);
    const [productVersions, setProductVersions] = useState([]);
    const [changeLog, setChangeLog] = useState([]);
    const [productMetaLoading, setProductMetaLoading] = useState(true);
    const [productVersionsLoading, setProductVersionsLoading] = useState(true);
    const [productChangelogLoading, setProductChangelogLoading] = useState(true);

    const productId = searchParams.get("id");

    useEffect(() => {
        setProductMetaLoading(true);
        setProductVersionsLoading(true);
        setProductChangelogLoading(true);
        if (!currentUser || !axiosInstance) return;

        const fetchProductMeta = async () => {
            try {
                const response = await axiosInstance.get(`/products/${productId}`);
                const productMeta = JSON.parse(response.data);
                if (productMeta.length > 0) {
                    setProductMeta(productMeta[0]);
                    fetchProductChangelog(productMeta[0]);
                }
            } catch (err) {
                console.error("Failed to fetch product metadata:", err);
            } finally {
                setProductMetaLoading(false);
            }
        };

        const fetchProductVersions = async () => {
            try {
                const versionsResponse = await axiosInstance.get(`/products/${productId}/versions`);
                const productVersions = JSON.parse(versionsResponse.data);
                setProductVersions(productVersions);

            } catch (err) {
                console.error("Failed to fetch product verions:", err);
            } finally {
                setProductVersionsLoading(false);
            }
        };

        const fetchProductChangelog = async (meta) => {
            try {
                const changeLogS3Key = `${meta.name}/ChangeLog.json`
                const changeLogResponse = await axiosInstance.get(`/s3signedurl?key=${encodeURIComponent(changeLogS3Key)}`);
                const s3SignedUrl = JSON.parse(changeLogResponse.data);

                const contentUrlResponse = await axiosInstance.get(`/fetch?url=${encodeURIComponent(s3SignedUrl)}`);
                const changeLogContent = contentUrlResponse.data;
                const byteArray = base64ToByteArray(changeLogContent.data);

                const changelogBlob = new Blob([byteArray], { type: changeLogContent.contentType });
                const localChangelogUrl = URL.createObjectURL(changelogBlob);

                const changeLogContentResponse = await axios.get(localChangelogUrl);
                const contentType = changeLogContentResponse.headers['content-type'];
                if (contentType && contentType.includes('application/json')) {
                    setChangeLog(changeLogContentResponse.data);
                }

            } catch (err) {
                console.error("Failed to fetch product changelog:", err);
            } finally {
                setProductChangelogLoading(false);
            }
        };

        const productData = ProductsData.find(product => product.key.toLowerCase() === productId.toLowerCase());
        setProductData(productData);

        fetchProductMeta();
        fetchProductVersions();

    }, [currentUser, axiosInstance]);

    const handleDownload = async (s3InstallerKey, openInNewTab = false) => {
        if (s3InstallerKey) {
            try {
                const response = await axiosInstance.get(`/s3signedurl?key=${encodeURIComponent(s3InstallerKey)}`);
                const s3SignedUrl = JSON.parse(response.data);

                if (s3SignedUrl) {
                    if (openInNewTab) {
                        window.open(s3SignedUrl, '_blank');
                    } 
                    else {
                        window.location.href = s3SignedUrl;
                    }
                } else {
                    console.error("Signed URL is not available");
                }

            } catch (err) {
                console.error("Failed to download product:", err);
            }
        }
    }

    const getChipStyles = (changeType) => {
        switch (changeType) {
            case 'New':
                return { background: '#c9f9d9', color: '#287e46' };
            case 'Fix':
                return { background: '#fed2d2', color: '#aa2c28' };
            case 'Improvement':
                return { background: '#cdd7fc', color: '#423cb1' };
            default:
                return {};
        }
    };

    if (currentUser == null) {
        return;
    }

    return (
        <Box sx={{ display: "flex" }}>
            <AppDrawer></AppDrawer>
            <Box sx={{ overflow: "auto", bgcolor: '#f2f5f9', minHeight: 'calc(100vh - 64px)', padding: 2 }}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <Container>
                        <Card sx={{ borderRadius: 2 }}>
                            <CardHeader
                                title={<Typography variant="h3" color='text.primary'>{t(productData.title)}</Typography>}
                                subheader={<Typography variant="h6" color='text.primary' sx={{ mt: 1 }}>{t(productData.description)}</Typography>}
                            />
                            <CardContent>
                                {productMetaLoading ? (
                                    <>
                                        <Skeleton variant="text" width="80%" height={40} />
                                        <Skeleton variant="text" width="80%" height={40} />
                                        <Skeleton variant="text" width="80%" height={40} />
                                    </>
                                ) : (
                                    <>
                                        <Grid container spacing={2} alignItems="center" mb={2}>
                                            {productData.webpageUrl && (
                                                <Grid item>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        startIcon={<OpenInBrowserIcon />}
                                                        href={productData.webpageUrl}
                                                        target="_blank">
                                                        Visit Webpage
                                                    </Button>
                                                </Grid>
                                            )}
                                            {productData.youtubePublicUrl && (
                                                <Grid item>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        startIcon={<OndemandVideoIcon />}
                                                        href={productData.youtubePublicUrl}
                                                        target="_blank">
                                                        Watch Video
                                                    </Button>
                                                </Grid>
                                            )}
                                            {productData.hasDocumentation && (
                                                <Grid item>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        startIcon={<MenuBookIcon />}
                                                        onClick={() => handleDownload(`${productMeta?.name}/${productMeta?.name}-Documentation.pdf`, true)}>
                                                        Documentation
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item>
                                                <Typography variant="body2" color='text.primary'>Version</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Chip
                                                    label={productMeta.lastVersion}
                                                    color="default"
                                                    variant="contained"
                                                    sx={{ backgroundColor: '#ebebeb ', fontWeight: '500' }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" color='text.primary'>Release Date</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Chip
                                                    label={new Date(productMeta.lastReleaseDate).toLocaleDateString()}
                                                    color="default"
                                                    variant="contained"
                                                    sx={{ backgroundColor: '#ebebeb ', fontWeight: '500' }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Button
                                            sx={{ mt: 2 }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleDownload(productMeta?.s3InstallerKey)}>
                                            Download
                                        </Button>
                                    </>
                                )}
                            </CardContent>
                        </Card>

                        <Accordion defaultExpanded={true} sx={{ mt: 4, borderRadius: 2 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="change-log-content"
                                id="change-log-header"
                            >
                                <Typography variant="h4" color='text.primary'>Change log</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {productChangelogLoading ? (
                                    <>
                                        <Skeleton variant="text" width="100%" height={40} />
                                        <Skeleton variant="text" width="100%" height={40} />
                                        <Skeleton variant="text" width="100%" height={40} />
                                    </>
                                ) : (
                                    <Timeline sx={{
                                        [`& .${timelineOppositeContentClasses.root}`]: {
                                            flex: 0
                                        },
                                    }}>
                                        {Object.keys(changeLog).map((version, index) => (
                                            <TimelineItem key={version}>
                                                <TimelineOppositeContent>
                                                    <Typography variant="h5" color='text.primary'>
                                                        {version}
                                                    </Typography>
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineDot />
                                                    {index !== Object.keys(changeLog).length - 1 && <TimelineConnector style={{ backgroundColor: '#ebebeb' }} />}
                                                </TimelineSeparator>
                                                <TimelineContent sx={{ ml: 1, mt: 0 }}>
                                                    {changeLog[version].map((change, changeIndex) => {
                                                        const chipStyles = getChipStyles(change.ChangeType);
                                                        return (
                                                            <Box key={changeIndex} sx={{ my: 2, display: 'flex', alignItems: 'center', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                                <Chip
                                                                    label={change.ChangeType}
                                                                    size='small'
                                                                    sx={{
                                                                        backgroundColor: chipStyles.background,
                                                                        color: chipStyles.color
                                                                    }}
                                                                />
                                                                <Typography variant="body2" sx={{ mt: 1, whiteSpace: 'pre-line' }}>
                                                                    {change.Detail}
                                                                </Typography>
                                                            </Box>
                                                        );
                                                    })}
                                                </TimelineContent>
                                            </TimelineItem>
                                        ))}
                                    </Timeline>
                                )}
                            </AccordionDetails>
                        </Accordion>


                        <Accordion defaultExpanded={true} sx={{ mt: 2, borderRadius: 2 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="versions-content"
                                id="versions-header"
                            >
                                <Typography variant="h4" color='text.primary'>Versions</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer>
                                    <Table sx={{ minWidth: 400 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Version</TableCell>
                                                <TableCell>Release Date</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {productVersionsLoading ? (
                                                <>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Skeleton variant="text" width="80%" height={40} />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Skeleton variant="text" width="60%" height={40} />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Skeleton variant="text" width="80%" height={40} />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Skeleton variant="text" width="60%" height={40} />
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            ) : (
                                                productVersions && productVersions.length > 0 && (
                                                    productVersions
                                                        .sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate))
                                                        .map((productVersion) => (
                                                            <TableRow key={productVersion.version}>
                                                                <TableCell>{productVersion.version}</TableCell>
                                                                <TableCell>{new Date(productVersion.releaseDate).toLocaleDateString()}</TableCell>
                                                                <TableCell>
                                                                    <Button onClick={() => handleDownload(productVersion.s3InstallerKey)}>Download</Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    </Container>
                </Box>
            </Box>
        </Box>
    );
}