const PmlCodeSample = [
  "using namespace 'BCFNode.Aveva.API'",
  "!bcfNode = OBJECT BCFNODE()",
  "!currentDrawlist = !!gphDrawlists.drawlist(!currentView)",
  "!currentDrawlist.zoomOnAdd(FALSE)",
  "!drawlistMembers = !currentDrawlist.members()",
  "!elementsToCheck = !!collectAllFor('PIPE', '', WORLD )",
  "!clasher = OBJECT AVACLASHER()",
  "!clashCounter = 0",
  "Do !i index !elementsToCheck",
  "  !!fmsys.setProgress(!i / !elementsToCheck.size()) * 100)",
  "  DESCLASH",
  "    OVERRIDE ON",
  "    REM OBST ALL",
  "    REM EXCL ALL",
  "    LIMITS NONE",
  "    OBST $!obstruction",
  "    CHECK $!elementsToCheck[$!i]",
  "  EXIT",
  "  !clashCount = !clasher.clashCountGet()",
  "  Do !clashIndex from 1 to !clashCount",
  "    !clash = OBJECT AVACLASH(!clashIndex)",
  "    !clashCounter = !clashCounter + 1",
  "    !checkedElement = !clash.clasher",
  "    !foreignElement = !clash.clashee",
  "    !bcfNode.createTopic(!checkedElement, !foreignElement)",
  "  Enddo",
  "Enddo",
  "!currentDrawlist.zoomOnAdd(TRUE)",
  "!!fmsys.setProgress(0)",
];

export default PmlCodeSample;