import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import googleConfig from './googleConfig';

export function GoogleAnalyticsHandler() {
    const location = useLocation();

    useEffect(() => {
        if (window.gtag) {
            window.gtag('config', googleConfig.analytics.trackingId, {
                'page_path': location.pathname + location.search
            });
        }
    }, [location]);

    return null;
}