import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Copyright from './Copyright'
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { InitiateForgotPassword } from '../../../services/aws/cognito/Authentication';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLanguagePrefix } from '../../translation/LanguageUrlHandler';

export default function LostPassword() {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [backendError, setBackendError] = React.useState("");
    const location = useLocation();
    const languagePrefix = getLanguagePrefix(location.pathname);

    const handleSubmit = (event) => {
        if (loading) return;

        setLoading(true);

        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const email = data.get('email');

        InitiateForgotPassword(email)
            .then((data) => {
                navigate(`${languagePrefix}/auth/verify-account`, { state: { email: email, otpLength: 6, mode: 'LostPassword' } });
            })
            .catch(err => {
                setBackendError(err.message);
            }).finally(() =>
                setLoading(false))
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="md">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>

                    <Typography component="h1" variant="h4" color="secondary.main">
                        {t("Forms.ForgotPassword")}
                    </Typography>

                    <Typography component="body" variant="body" sx={{ mt: 2, whiteSpace: 'pre-line' }} align="center">
                        {t("Forms.ForgotPasswordDetail")}
                    </Typography>

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t("Forms.Email")}
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />

                        {backendError && (
                            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                                {backendError}
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {t("Forms.ResetPassword")}
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={36}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}

                        <Grid container>
                            <Grid item>
                                <Link href={`${languagePrefix}/auth/login`} variant="body2">
                                    {t("Forms.RememberPassword")}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}