import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';
import awsConfig from '../awsConfig';
import axios from 'axios';

export const UserPool = new CognitoUserPool({
    UserPoolId: awsConfig.cognito.userPoolId,
    ClientId: awsConfig.cognito.userPoolClientId
});

export const RegisterUser = (firstName, lastName, email, password, customAttributes = {}) => {
    return new Promise((resolve, reject) => {

        const attributesList = [];
        attributesList.push(new CognitoUserAttribute(({ Name: 'given_name', Value: firstName })))
        attributesList.push(new CognitoUserAttribute(({ Name: 'family_name', Value: lastName })))
        attributesList.push(new CognitoUserAttribute(({ Name: 'email', Value: email })))
        for (let key in customAttributes) {
            if (customAttributes.hasOwnProperty(key)) {
                attributesList.push(new CognitoUserAttribute({ Name: `custom:${key}`, Value: customAttributes[key] }));
            }
        }

        UserPool.signUp(email, password, attributesList, null,
            function signUpCallback(err, result) {
                if (!err) {
                    resolve(result);
                } else {
                    reject(err);
                }
            }
        );
    });
};

export const VerifyUser = (email, code) => {
    return new Promise((resolve, reject) => {
        CreateCognitoUser(email).confirmRegistration(code, true, function confirmCallback(err, result) {
            if (!err) {
                resolve(result);
            } else {
                reject(err);
            }
        });
    });
};

export const AuthenticateUser = (email, password) => {
    return new Promise((resolve, reject) => {
        const user = CreateCognitoUser(email);

        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password
        });

        user.authenticateUser(authDetails, {
            onSuccess: (result) => {
                resolve(result);
            },
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                reject({ code: "NewPasswordRequired", message: "Password change required.", session: user.Session, username: user.username });
            },
            onFailure: (err) => {
                console.log("login failed", err);
                reject(err);
            }
        });
    });
};

export const ResendOTP = (email) => {
    return new Promise((resolve, reject) => {
        const user = CreateCognitoUser(email);

        user.resendConfirmationCode(function (err, result) {
            if (!err) {
                resolve(result);
            } else {
                reject(err);
            }
        });
    });
};

export const InitiateForgotPassword = (email) => {
    return new Promise((resolve, reject) => {
        const cognitoUser = CreateCognitoUser(email);

        cognitoUser.forgotPassword({
            onSuccess: () => {
                resolve("Confirmation code sent to email.");
            },
            onFailure: (err) => {
                reject(err);
            },
        });
    });
}

export const ConfirmPassword = (email, verificationCode, newPassword) => {
    return new Promise((resolve, reject) => {
        const cognitoUser = CreateCognitoUser(email);

        cognitoUser.confirmPassword(verificationCode, newPassword, {
            onSuccess: () => {
                resolve("Password changed successfully!");
            },
            onFailure: (err) => {
                reject(err);
            }
        });
    });
}

export const CompletePasswordChallenge = (email, newPassword, session, attributesData) => {
    return new Promise((resolve, reject) => {
        const cognitoUser = CreateCognitoUser(email);
        cognitoUser.Session = session;

        cognitoUser.completeNewPasswordChallenge(newPassword, attributesData, {
            onSuccess: (session) => {
                resolve("Password changed successfully!");
            },
            onFailure: (err) => {
                reject(err);
            }
        });
    });
}

export const CreateCognitoUser = (email) => {
    return new CognitoUser({
        Username: email,
        Pool: UserPool
    });
}

export const LogoutCurrentUser = () => {
    const user = UserPool.getCurrentUser();
    if (user) {
        user.signOut();
    }
};

export const GetCurrentValidUser = () => {
    return new Promise((resolve, reject) => {
        const currentUser = UserPool.getCurrentUser();

        if (currentUser == null) {
            resolve(null);
        }
        else {
            currentUser.getSession(async (err, session) => {
                if (err) {
                    resolve(null);
                }
                else {
                    try {
                        const sessionData = session.idToken.payload;
                        const username = sessionData['cognito:username'];
                        const userData = await fetchUserData(username, session.idToken.jwtToken);
                        resolve({
                            username: username,
                            email: sessionData.email,
                            firstName: sessionData.given_name,
                            lastName: sessionData.family_name,
                            fullName: (sessionData.given_name && sessionData.family_name) ? sessionData.given_name + ' ' + sessionData.family_name : sessionData.email,
                            idToken: session.idToken.jwtToken,
                            role: userData.role,
                            company: userData.company
                        });
                    }
                    catch
                    {
                        resolve(null);
                    }
                }
            });
        }
    });
}

async function fetchUserData(username, token) {
    try {
        const axiosInstance = axios.create({
            baseURL: awsConfig.api.invokeUrl,
            timeout: 5000,
            headers: {'Authorization': token}
        });

        const response = await axiosInstance.get(`/users/${username}/profile`);
        return JSON.parse(response.data);

    } catch (error) {
        console.error("Error getting user data:", error);
        return null;
    }
}