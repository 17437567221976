import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Footer from '../../../footer/Footer';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigationType, useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import { getLanguagePrefix } from '../../../translation/LanguageUrlHandler';
import { addUserAction } from '../../../../services/user/UserActions';

import { default as SendSyncCEGif } from '../../../images/products/NavisworksConnector/Send-Sync-CE.gif';
import { default as SendLimitsGif } from '../../../images/products/NavisworksConnector/Send-Limits.gif';
import { default as SyncCEGif } from '../../../images/products/NavisworksConnector/Sync-CE.gif';

import { default as BCFImage } from '../../../images/products/BCFNode/BCFicon128.png';

function NavisworksConnector() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigationType = useNavigationType();
  const location = useLocation();
  const navigate = useNavigate()
  const languagePrefix = getLanguagePrefix(location.pathname);
  const targetOrigin = window.location.origin.replace("localhost", "app.localhost").replace("ingeloop", "app.ingeloop");
  const targetPage = targetOrigin + "";
  const [isYearly, setIsYearly] = useState(true);

  useEffect(() => {
    if (navigationType === "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [navigationType]);

  const handleSwitchChange = () => {
    setIsYearly(!isYearly);
  };

  const tiers = [
    {
      title: t("NavisworksConnector.Tiers.Pro.Title"),
      monthlyPrice: '39€',
      yearlyPrice: '29€',
      description: [
        t("NavisworksConnector.Tiers.Pro.Feature1"),
        t("NavisworksConnector.Tiers.Pro.Feature2"),
        t("NavisworksConnector.Tiers.Pro.Feature3"),
        t("NavisworksConnector.Tiers.Pro.Feature4"),
      ],
      buttonText: t("NavisworksConnector.TiersContact"),
      buttonVariant: 'contained',
      link: '/contact'
    },
    {
      title: t("NavisworksConnector.Tiers.Enterprise.Title"),
      monthlyPrice: '',
      yearlyPrice: '',
      description: [
        t("NavisworksConnector.Tiers.Enterprise.Feature1"),
        t("NavisworksConnector.Tiers.Enterprise.Feature2"),
        t("NavisworksConnector.Tiers.Enterprise.Feature3"),
        t("NavisworksConnector.Tiers.Enterprise.Feature4"),
      ],
      buttonText: t("NavisworksConnector.TiersContact"),
      buttonVariant: 'contained',
      createTrial: false,
      link: '/contact'
    },
  ];

  return (
    <main>
      <Helmet>
        <title>{t("Meta.Products.NavisworksConnector.Title")}</title>
        <meta name="description" content={t("Meta.Products.NavisworksConnector.Description")} />
      </Helmet>

      <Grid container justifyContent="center">
        <Grid container justifyContent="center">
          <Box
            color="secondary.main"
            sx={{ backgroundColor: 'white', height: 'calc(100vh - 54px)', maxWidth: "xl", display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, justifyContent: 'center' }}>
            <Box sx={{
              maxWidth: { xs: '100%', lg: '50%' },
              flexShrink: 0, p: 2, m: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: { xs: 16, md: 0 } }}>
                <Typography
                  variant="h1"
                  color="text.primary"
                  align="center"
                  gutterBottom
                  sx={{ textAlign: 'center', margin: 0, verticalAlign: 'middle' }}>
                  {t("NavisworksConnector.Title")}
                </Typography>
              </Box>
              <Typography component="h2" variant="h5" align="center" color="text.secondary" paragraph sx={{ lineHeight: 1.4 }}>
                {t("NavisworksConnector.Subtitle")}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, p: 2, m: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
              <Card
                sx={{
                  maxWidth: '100%', maxHeight: '100%', objectFit: 'cover',
                  m: { xs: 2, lg: 10 },
                  opacity: 1,
                  transition: 'transform 0.5s ease',
                  boxShadow: '3'
                }}>
                <Box>
                  <img
                    src={SyncCEGif}
                    alt="NavisworksConnector UI"
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
                  />
                </Box>
              </Card>
            </Box>
          </Box>

          {/* Send elements to Navisworks */}
          <Grid item xs={12} sx={{ mt: 2, bgcolor: '#fafafc' }}>
            <Container maxWidth="xl" sx={{ mt: 6 }}>
              <Grid container>
                <Grid item xs={12} md={6} sx={{ p: [2, 6], pl: [2, 0], pt: [2, 0], order: { xs: 1, md: 1 } }}>
                  <Box sx={{ flexGrow: 1, p: 2, m: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
                    <Card
                      sx={{
                        maxWidth: '100%', maxHeight: '100%', objectFit: 'cover',
                        m: { xs: 0, lg: 0 },
                        opacity: 1,
                        transition: 'transform 0.5s ease',
                        boxShadow: '3'
                      }}>
                      <Box>
                        <img
                          src={SendSyncCEGif}
                          alt="NavisworksConnector.SendSyncCE"
                          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
                        />
                      </Box>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 2 } }}>
                  <Box textAlign="left">
                    <Typography variant="h2" color="text.primary" sx={{ mb: [2, 3] }}>
                      {t("NavisworksConnector.Presentation1Title")}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }} style={{ whiteSpace: 'pre-line' }}>
                      {t("NavisworksConnector.Presentation1Content")}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>

          {/* Send Limits */}
          <Grid item xs={12} sx={{ mt: 2, bgcolor: '#ffffff' }}>
            <Container maxWidth="xl" sx={{ mt: 3 }}>
              <Grid container>
                <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
                  <Box sx={{ flexGrow: 1, p: 2, m: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
                    <Card
                      sx={{
                        maxWidth: '100%', maxHeight: '100%', objectFit: 'cover',
                        m: { xs: 0, lg: 0 },
                        opacity: 1,
                        transition: 'transform 0.5s ease',
                        boxShadow: '3'
                      }}>
                      <Box>
                        <img
                          src={SendLimitsGif}
                          alt="NavisworksConnector.SendLimits"
                          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
                        />
                      </Box>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
                  <Box textAlign="left">
                    <Typography variant="h2" color="text.primary" sx={{ mb: [2, 3] }}>
                      {t("NavisworksConnector.Presentation2Title")}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }} style={{ whiteSpace: 'pre-line' }}>
                      {t("NavisworksConnector.Presentation2Content")}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>

          {/* Pricing */}
          <Grid item xs={12} sx={{ pb: {xs: 4, md: 6}, bgcolor: '#fafafc' }}>
            <Container disableGutters maxWidth="md" component="main" sx={{ pt: 4, pb: 6 }}>
              <Typography variant="h2" color="text.primary" sx={{ mb: 2, textAlign: "center" }}>{t("NavisworksConnector.TiersTitle")}</Typography>
              <Typography variant="body1" align="center" sx={{ whiteSpace: 'pre-line' }}>{t("NavisworksConnector.TiersSubtitle")}</Typography>

              {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
                <Typography variant="body1" color="text.primary">{t("Products.Monthly")}</Typography>
                <Switch
                  checked={isYearly}
                  onChange={handleSwitchChange}
                />
                <Typography variant="body1" color="text.primary">{t("Products.Yearly")}</Typography>
              </Box> */}
            </Container>

            <Container maxWidth="md" component="main">
              <Grid container spacing={5} alignItems="flex-end">
                {tiers.map((tier) => (
                  <Grid
                    item
                    key={tier.title}
                    xs={12}
                    md={6}
                  >
                    <Card>
                      <CardHeader
                        title={tier.title}
                        subheader={tier.subheader}
                        titleTypographyProps={{ align: 'center', variant: "h3", color: "text.primary" }}
                        subheaderTypographyProps={{
                          align: 'center',
                        }}
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                              ? theme.palette.grey[200]
                              : theme.palette.grey[700],
                        }}
                      />
                      <CardContent>
                        {/* <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: 3,
                          }}
                        >
                          {tier.monthlyPrice ? (
                            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                              <Typography component="h2" variant="h3" color="text.primary">
                                {isYearly ? tier.yearlyPrice : tier.monthlyPrice}
                              </Typography>
                              <Typography component="h3" variant="h6" color="text.secondary">
                                {t("Products.MonthlyPrice")}
                              </Typography>
                            </Box>
                          ) :
                            <Typography component="h2" variant="h3" color="text.primary">
                              {t("Products.OnDemandPrice")}
                            </Typography>
                          }
                        </Box> */}
                        <ul style={{ listStyleType: 'none', paddingInlineStart: '0px', textAlign: 'left', width: '100%' }}>
                          {tier.description.map((line) => (
                            <li key={line} >
                              <Typography variant="subtitle1" align="left" sx={{ display: 'flex', alignItems: 'center' }}>
                                <CheckIcon fontSize='small' sx={{ mr: 2 }} />
                                {line}
                              </Typography>
                            </li>
                          ))}
                        </ul>
                      </CardContent>
                      <CardActions>
                        <Button
                          href={tier.link}
                          fullWidth
                          variant={tier.buttonVariant}
                          component="button"
                          sx={{ mx: 2, mb: 2, mt: 1 }}>
                          {tier.buttonText}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </main>
  );
}

export default NavisworksConnector;
