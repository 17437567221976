import React, { useState } from 'react'
import { ReactComponent as AppLogo } from '../images/logo/Ingeloop.svg';
import { UseUser } from '../../services/user/UserContext';
import { stringAvatar } from '../../utilities/AvatarUtilities';
import { useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useIsAppPage } from '../../App';
import LanguageSwitcher from './LanguageSwitcher';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLanguagePrefix } from '../translation/LanguageUrlHandler';

function AppNavbar() {
  const { t } = useTranslation();
  const { currentUser, logout } = UseUser();
  const theme = useTheme();
  const navigate = useNavigate();
  const desktopMode = useMediaQuery(theme.breakpoints.up('sm'));
  const settings = ['Account', 'Logout'];
  const isAppPage = useIsAppPage();
  const location = useLocation();
  const languagePrefix = getLanguagePrefix(location.pathname);

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItemClicked = (clickedItem) => {
    handleCloseUserMenu();
    if (clickedItem === 'Logout') {
      logout();
    }
    else if (clickedItem === 'Account') {
      navigate(`${languagePrefix}/userprofile`);
    }
  }

  if (!isAppPage || currentUser == null) {
    return;
  }

  return (
    <AppBar position="sticky" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#ffffff' }}>
      <Box>
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>

          {/* Logo for desktop */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, ml: 3 }}>
            <a href="https://www.ingeloop.com" target="_blank" rel="noopener noreferrer">
              <AppLogo width="200"></AppLogo>
            </a>
          </Box>

          {/* Logo for mobile */}
          <Container sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, ml: 0 }}>
            <a href="https://www.ingeloop.com" target="_blank" rel="noopener noreferrer">
              <AppLogo width="200"></AppLogo>
            </a>
          </Container>

          {/* User Settings */}
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 3 }}>

            {desktopMode &&
              <Box sx={{ mr: 2 }}>
                <LanguageSwitcher />
              </Box>
            }

            <Tooltip title={
              <>
                {currentUser.fullName}
                <br />
                {currentUser.email}
              </>
            }>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  {...stringAvatar(currentUser.fullName)}
                  sx={{
                    width: 42,
                    height: 42,
                    fontSize: "1rem",
                    ...stringAvatar(currentUser.fullName).sx
                  }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleMenuItemClicked(setting)}>
                  <Typography sx={{ color: 'black' }} textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
}
export default AppNavbar;
