import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from './Copyright'
import { useLocation } from 'react-router-dom';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { ReactComponent as IngeloopLogo } from '../../images/logo/Logo.svg';
import { AuthenticateUser, ResendOTP } from '../../../services/aws/cognito/Authentication';
import { useTranslation } from 'react-i18next';
import { getLanguagePrefix } from '../../translation/LanguageUrlHandler';

export default function Login() {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [backendError, setBackendError] = React.useState("");
    const location = useLocation();
    const languagePrefix = getLanguagePrefix(location.pathname);

    const handleSubmit = (event) => {
        if (loading) return;

        setLoading(true);

        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const email = data.get('email');
        const password = data.get('password');

        AuthenticateUser(email, password).then((data) => {
            navigate(`${languagePrefix}/`);
        }).catch(err => {
            if (err.code === "UserNotConfirmedException") {
                // Send OTP code, then navigate to OTP verification page
                ResendOTP(email)
                    .then(() => {
                        navigate(`${languagePrefix}/auth/verify-account`, { state: { email: email, otpLength: 6, mode: 'Register' } });
                    })
                    .catch(otpErr => {
                        setBackendError(otpErr.message);
                    });
            } else if (err.code === "NewPasswordRequired") {
                // Navigate to change password screen or display a prompt
                navigate(`${languagePrefix}/auth/change-password`, { state: { email: email, changePasswordMode: 'NewPasswordChallenge', session: err.session, username: err.username } });
            } else {
                setBackendError(err.message);
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>

                    <IngeloopLogo width={100}></IngeloopLogo>

                    <Typography component="h1" variant="h4" color="secondary.main" sx={{ mt: 2 }}>
                        {t("Forms.Login")}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t("Forms.Email")}
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t("Forms.Password")}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />

                        {backendError && (
                            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                                {backendError}
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={loading}
                            sx={{ mt: 2, mb: 2 }}>
                            {t("Forms.Signin")}
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={36}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}

                        <Grid container>
                            <Grid item xs>
                                <Link href={`${languagePrefix}/auth/lost-password`} variant="body2">
                                    {t("Forms.ForgotPassword")}
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href={`${languagePrefix}/auth/register`} variant="body2">
                                    {t("Forms.GotoRegister")}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}