const DotNetCodeSample = [
  "[PMLNetCallable]",
  "public Hashtable Topics()",
  "{",
  "    List<TopicData> topicsData = BCFNodeViewModel.GetTopicData();",
  "    Hashtable topics = new Hashtable();",
  "    for (int i = 0; i < topicsData.Count; i++)",
  "    {",
  "        var topicData = topicsData[i].Markup.Topic;",
  "        topics.Add(Convert.ToDouble(i + 1), new PMLEntity(topicData));",
  "    }",
  "    return topics;",
  "}",
  "",
  "[PMLNetCallable]",
  "public void CreateBCF(string bcfFile, string version)",
  "{",
  "    if (Enum.TryParse(version, true, out BCF.BCFVersion bcfVersion))",
  "    {",
  "        BCFNodeViewModel.CreateBCF(bcfFile, bcfVersion);",
  "        BCFNodeViewModel.LoadBCF(bcfFile, false);",
  "    }",
  "    else",
  "    {",
  "        throw new PMLNetException(1000, 1, \"Unvalid BCF version\");",
  "    }",
  "}",
  "",
  "[PMLNetCallable]",
  "public void LoadBCF(string bcfFile)",
  "{",
  "    BCFNodeViewModel.LoadBCF(bcfFile, false);",
  "}",
  "",
  "[PMLNetCallable]",
  "public void Save()",
  "{",
  "    BCFNodeViewModel.SaveBCF(false);",
  "}",
  "",
  "[PMLNetCallable]",
  "public void Save(string bcfFile)",
  "{",
  "    BCFNodeViewModel.SaveBCF(bcfFile, false);",
  "}",
];

export default DotNetCodeSample;