import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './components/translation/i18n';
import ReactLoading from 'react-loading';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Suspense fallback={(
    <ReactLoading type={'spinningBubbles'} height={300} width={300} />
    )}>
      <App />
  </Suspense>
);