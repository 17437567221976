import React, { useState, useEffect } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { ReactComponent as AppLogo } from '../images/logo/Ingeloop.svg';
import { ReactComponent as AppLogoLight } from '../images/logo/Ingeloop-light.svg';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import { getLanguagePrefix } from '../translation/LanguageUrlHandler';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { isPathHomePage } from '../translation/LanguageUrlHandler';

function Navbar() {
  const { t } = useTranslation();
  const location = useLocation();
  const languagePrefix = getLanguagePrefix(location.pathname);

  const isHomePage = isPathHomePage(location.pathname);
  const shouldStartDark = isHomePage && window.scrollY <= window.innerHeight - 54;
  const [darkMode, setDarkMode] = useState(shouldStartDark);

  const pages = [
    { text: t("Navbar.Home"), route: `${languagePrefix}/` },
    { 
      text: t("Navbar.Services"), 
      route: `${languagePrefix}/services`,
      subMenu: [
        { text: t("Services.Service1"), route: `${languagePrefix}/service1` },
        { text: t("Services.Service2"), route: `${languagePrefix}/service2` },
        { text: t("Services.Service3"), route: `${languagePrefix}/service3` }
      ]
    },
    { text: t("Navbar.Products"), route: `${languagePrefix}/products` },
    { text: t("Navbar.Training"), route: `${languagePrefix}/training` },
    { text: t("Navbar.About"), route: `${languagePrefix}/about` },
    { text: t("Navbar.Contact"), route: `${languagePrefix}/contact` }
  ];

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleScroll = () => {
    setDarkMode(isHomePage && window.scrollY <= window.innerHeight - 54);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {  // Cleanup function
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  return (
    <AppBar position="sticky" sx={{ backgroundColor: darkMode ? "black" : "white" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Logo for desktop */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
            <NavLink to={`${languagePrefix}/`} >
              {darkMode ? <AppLogoLight width="200" /> : <AppLogo width="200" />}
            </NavLink>
          </Box>

          {/* Mobile Menu */}
          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              sx={{ color: darkMode ? "white" : "black" }}
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.text} onClick={handleCloseNavMenu}>
                  <NavLink to={page.route} style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}>
                    <Typography sx={{ color: 'black' }} textAlign="center">{page.text}</Typography>
                  </NavLink>
                </MenuItem>
              ))}

              <Box sx={{ px: 1 }}>
                <LanguageSwitcher />
              </Box>
            </Menu>
          </Box>

          {/* Desktop Navigation */}
          <Container sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
            {pages.map((page) => (
              <Button
                component={Link}
                to={page.route}
                key={page.text}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: darkMode ? "white" : "black", display: 'block' }}>
                {page.text}
              </Button>
            ))}
          </Container>

          {/* Desktop Language Switcher*/}
          <Box sx={{ display: { xs: 'none', md: 'flex'}, width: '200px', alignItems: 'center', justifyContent: 'center' }}>
            <LanguageSwitcher darkMode={darkMode}></LanguageSwitcher>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
