import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Button, Box, Container, Typography, Grid } from '@mui/material';
import { Img } from 'react-image';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Footer from '../../footer/Footer';
import { useNavigationType, useLocation } from 'react-router-dom';
import { getLanguagePrefix } from '../../translation/LanguageUrlHandler';

import { default as ServicesImageAvif } from '../../images/home/offers/services.avif';
import { default as ServicesImage } from '../../images/home/offers/services.png';

import DataVisualizationImage from '../../images/services/DataVisualization.png';
import BIMConnectorImage from '../../images/services/BIMConnector.png';
import InteroperabilityImage from '../../images/services/Interoperability.png';
import ToolsImage from '../../images/services/Tools.png';
import AutomationGif from '../../images/services/Automation.gif';
import AutomationImage from '../../images/services/Automation.png';
import AvevaImage from '../../images/services/Aveva_logo.svg';

function Services() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigationType = useNavigationType();
  const location = useLocation();
  const languagePrefix = getLanguagePrefix(location.pathname);
  const [isButtonClicked, setButtonClicked] = useState(false);

  const userActionMinWidth = 960;

  useEffect(() => {
    if (navigationType === "PUSH") {
      window.scrollTo(0, 0);
    }
    if (window.innerWidth <= userActionMinWidth) {
      runAutomation();
    }
  }, [navigationType]);

  function runAutomation() {
    setButtonClicked(true);
  }

  return (
    <main>
      <Helmet>
        <title>{t("Meta.Services.Title")}</title>
        <meta name="description" content={t("Meta.Services.Description")} />
      </Helmet>

      <Grid container justifyContent="center">
        <Grid container justifyContent="center">

          {/* Hero section */}
          <Box
            color="secondary.main"
            sx={{ backgroundColor: 'white', height: 'calc(100vh - 54px)', maxWidth: "xl", display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, justifyContent: 'center' }}>
            <Box sx={{
              maxWidth: { xs: '100%', lg: '50%' },
              flexShrink: 0, p: 2, m: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography variant="h1" color="text.primary" sx={{ mb: 2, p: [2, 0], ml: [0, 6], mt: 1, textAlign: 'center' }}>
                {t("Services.Title")}
              </Typography>
              <Typography component="h2" variant="h4" color="text.secondary" sx={{ mb: 2, px: [2, 0], ml: [0, 6], textAlign: 'center' }} style={{ whiteSpace: 'pre-line' }}>
                {t("Services.Subtitle")}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, p: 2, m: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
              <Img src={[ServicesImageAvif, ServicesImage]} alt="Digital Plant" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }} />
            </Box>
          </Box>

          {/* Automation */}
          <Grid item xs={12} sx={{ mt: 2,  bgcolor: '#fafafc' }}>
            <Container maxWidth="xl" sx={{ mt: 3 }}>
              <Grid container>
                <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 1 } }}>
                  <Box display="flex" justifyContent="center" alignItems="center" sx={{ p: [2, 5] }}>
                    {isButtonClicked ? (
                      <img src={AutomationGif} alt="Tools" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    ) : (
                      <img src={AutomationImage} alt="Tools" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 2 }, px: 4  }}>
                  <Box textAlign="left">
                    <Typography variant="h2" color="text.primary" sx={{ mb: 2 }}>{t("Services.AutomationDescription")}</Typography>
                    <Typography variant="body1" sx={{ mb: 2 }} style={{ whiteSpace: 'pre-line' }}>{t("Services.AutomationContent")}</Typography>
                    {window.innerWidth > userActionMinWidth ? (
                      <Button variant="contained" color="primary" sx={{ mb: 2 }} onClick={runAutomation}>
                        {t("Services.AutomationButtonText")}
                      </Button>
                    ) : null}
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>

          {/* Interoperability */}
          <Grid item xs={12} sx={{ mt: 2, bgcolor: '#fffff' }}>
            <Container maxWidth="xl" sx={{ mt: 3 }}>
              <Grid container>
                <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
                  <Box display="flex" justifyContent="center" alignItems="center" sx={{ p: [2, 5] }}>
                    <img src={InteroperabilityImage} alt="Interoperability" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 }, px: 4 }}>
                  <Box textAlign="left">
                    <Typography variant="h2" color="text.primary" sx={{ mb: 2 }}>{t("Services.InteroperabilityDescription")}</Typography>
                    <Typography variant="body1" sx={{ mb: 2 }} style={{ whiteSpace: 'pre-line' }}>{t("Services.InteroperabilityContent")}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>

          {/* RealTime & Data visualization */}
          <Grid item xs={12} sx={{ mt: 2, bgcolor: '#fafafc' }}>
            <Container maxWidth="xl" sx={{ mt: 3 }}>
              <Grid container>
                <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 1 } }}>
                  <Box display="flex" justifyContent="center" alignItems="center" sx={{ p: [2, 8] }}>
                    <img src={DataVisualizationImage} alt="Data Visualization" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 2 }, px: 4  }}>
                  <Box textAlign="left">
                    <Typography variant="h2" color="text.primary" sx={{ mb: 2 }}>{t("Services.RealTimeVisualisationDescription")}</Typography>
                    <Typography variant="body1" sx={{ mb: 2 }} style={{ whiteSpace: 'pre-line' }}>{t("Services.RealTimeVisualisationContent")}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>

          {/* BIM Development */}
          <Grid item xs={12} sx={{ mt: 2, bgcolor: '#fffff' }}>
            <Container maxWidth="xl" sx={{ mt: 3 }}>
              <Grid container>
                <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
                  <Box display="flex" justifyContent="center" alignItems="center" sx={{ p: [2, 8] }}>
                    <img src={BIMConnectorImage} alt="BIMConnector" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 }, px: 4  }}>
                  <Box textAlign="left">
                    <Typography variant="h2" color="text.primary" sx={{ mb: 2 }}>{t("Services.BimDevelopmentDescription")}</Typography>
                    <Typography variant="body1" sx={{ mb: 2 }} style={{ whiteSpace: 'pre-line' }}>{t("Services.BimDevelopmentContent")}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>

          {/* Tools & Addins */}
          <Grid item xs={12} sx={{ mt: 2, bgcolor: '#fafafc' }}>
            <Container maxWidth="xl" sx={{ mt: 3 }}>
              <Grid container>
                <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 1 } }}>
                  <Box display="flex" justifyContent="center" alignItems="center" sx={{ p: [2, 8] }}>
                    <img src={ToolsImage} alt="Tools" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 2 }, px: 4  }}>
                  <Box textAlign="left">
                    <Typography variant="h2" color="text.primary" sx={{ mb: 2 }}>{t("Services.MacrosAddinsDescription")}</Typography>
                    <Typography variant="body1" sx={{ mb: 2 }} style={{ whiteSpace: 'pre-line' }}>{t("Services.MacrosAddinsContent")}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>

          {/* Aveva expertise  */}
          <Grid item xs={12} sx={{ mt: 2, bgcolor: '#fffff' }}>
            <Container maxWidth="xl" sx={{ mt: 3 }}>
              <Grid container>
                <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
                  <Box display="flex" justifyContent="center" alignItems="center" sx={{ p: [5, 15] }}>
                    <img src={AvevaImage} alt="Aveva Logo" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 }, px: 4 }}>
                  <Box textAlign="left">
                    <Typography variant="h2" color="text.primary" sx={{ mb: 2 }}>{t("Services.AvevaExpertiseDescription")}</Typography>
                    <Typography variant="body1" sx={{ mb: 2 }} style={{ whiteSpace: 'pre-line' }}>{t("Services.AvevaExpertiseContent")}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>

        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={4} mb={4}>
          <Typography variant="h2" color="text.primary">{t("Services.ConclusionTitle")}</Typography>
          <Box mt={2} display="inline-flex" justifyContent="center">
            <Box mx={1}>
              <Button variant="contained" color="primary" href="https://www.youtube.com/channel/UC8PBJJ8y8o_74Thqn7r2HKA" target="_blank" rel="noopener noreferrer">
                {t("Services.SeeVideos")}
              </Button>
            </Box>
            <Box mx={1}>
              <Button variant="contained" color="primary" href={`${languagePrefix}/contact`}>
                {t("Services.Contact")}
              </Button>
            </Box>
          </Box>
        </Box>

      </Grid>
      <Footer />
    </main>
  );
}

export default Services;
