export default async function fetchScopeLicenses(axiosInstance, currentUser) {
    
    const response = await axiosInstance.get(`/licenses`);
    const licensesRawData = JSON.parse(response.data);

    let licenses;
    if (currentUser.role == "User") {
        licenses = licensesRawData.map(licenseRawData => {
            return {
                ...licenseRawData,
                userLicenses: [],
                isCompanyLicense: licenseRawData.SK.startsWith("COMPANYLICENSE#")
            };
        });
    }
    else {
        // Filter out LICENSEMETA entries
        licenses = licensesRawData.filter(item => item.SK.startsWith("LICENSEMETA#"));

        // Add User licenses as member
        licenses.forEach(license => {

            license.userLicenses = licensesRawData.filter(userLicense => {
                return userLicense.SK.startsWith("USERLICENSE#") && userLicense.PK === license.PK;
            });

            license.isCompanyLicense = licensesRawData.some(item => item.SK.startsWith("COMPANYLICENSE#") && item.PK === license.PK);
        });
    }

    return licenses;
}