import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Footer from '../../../footer/Footer';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigationType, useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import { getLanguagePrefix } from '../../../translation/LanguageUrlHandler';
import DynamicCodeBackground from '../../../animations/CodeWritting/DynamicCodeBackground';
import DotNetCodeSample from './DotNetCodeSample';

export default function DotNet() {
    const { t } = useTranslation();
    const navigationType = useNavigationType();
    const location = useLocation();
    const languagePrefix = getLanguagePrefix(location.pathname);

    const chapters = t(`Training.DotNet.Program.Chapters`, { returnObjects: true });
    const chaptersArray = Array.isArray(chapters) ? chapters : [];

    const [expanded, setExpanded] = useState(Array(chapters.length).fill(true));
    const handleExpandedChange = (panel) => (event, isExpanded) => {
        setExpanded(expanded.map((item, index) => (index === panel ? isExpanded : item)));
    };
    const listItemPointStyle = { width: '4px', height: '4px', borderRadius: '50%', backgroundColor: 'black', marginRight: '12px', alignSelf: 'center' };

    useEffect(() => {
        if (navigationType === "PUSH") {
            window.scrollTo(0, 0);
        }
    }, [navigationType]);

    return (
        <main>
            <Helmet>
                <title>{t("Meta.Training.DotNet.Title")}</title>
                <meta name="description" content={t("Meta.Training.DotNet.Description")} />
            </Helmet>

            {/* Hero section */}
            <Grid container sx={{ backgroundColor: '#1f1f1f', height: 'calc(100vh - 54px)' }}>
                <Grid container sx={{ maxWidth: 'xl', m: 'auto', width: '100%', height: '100%' }}>
                    <Grid item xs={12} lg={6} color="secondary.main" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h1" color="white" sx={{ mb: 2, p: [2, 0], ml: [0, 6], mt: 1 }}>
                            {t("Training.DotNet.TrainingTitle")}
                        </Typography>
                        <Typography component="h2" variant="h4" color="white" sx={{ mb: 2, px: [2, 0], ml: [0, 6] }} style={{ whiteSpace: 'pre-line' }}>
                            {t("Training.DotNet.TrainingSubTitle")}
                        </Typography>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ width: '200px', alignSelf: 'start' }}
                                onClick={() => {
                                    window.scrollTo({
                                        top: window.innerHeight - 54 + 1,
                                        behavior: 'smooth'
                                    });
                                }}>
                                {t("Training.GetStarted")}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={6} color="secondary.main" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: { lg: 2, xs: 2 }, alignItems: 'start' }}>
                        <DynamicCodeBackground codeLines={DotNetCodeSample}></DynamicCodeBackground>
                    </Grid>
                </Grid>
            </Grid>

            {/* Training Goals */}
            <Box sx={{ maxWidth: "md", margin: 'auto', pt: 2, px: 2 }}>
                <Grid container justifyContent="start">
                    <Box sx={{ width: '100%', pt: 4, pb: 4, px: 2 }}>
                        <Typography component="h2" variant="h3" align="center" color="text.primary" sx={{ whiteSpace: 'pre-line', mb: 4 }}>
                            {t("Training.DotNet.Goals.Title")}
                        </Typography>
                        <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                            {t("Training.DotNet.Goals.Text1")}
                        </Typography>
                        <Typography variant="body1" sx={{ whiteSpace: 'pre-line', my: 2 }}>
                            {t("Training.DotNet.Goals.Text2")}
                        </Typography>
                        <Box display="flex" sx={{ whiteSpace: 'pre-line', my: 2 }}>
                            <CheckIcon />
                            <Typography variant="body1" sx={{ ml: 1 }}>
                                {t("Training.DotNet.Goals.Item1")}
                            </Typography>
                        </Box>
                        <Box display="flex" sx={{ whiteSpace: 'pre-line', my: 2 }}>
                            <CheckIcon />
                            <Typography variant="body1" sx={{ ml: 1 }}>
                                {t("Training.DotNet.Goals.Item2")}
                            </Typography>
                        </Box>
                        <Box display="flex" sx={{ whiteSpace: 'pre-line', my: 2 }}>
                            <CheckIcon />
                            <Typography variant="body1" sx={{ ml: 1 }}>
                                {t("Training.DotNet.Goals.Item3")}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Box>

            {/* Training public */}
            <Box sx={{ maxWidth: "md", margin: 'auto', pt: 0, px: 2 }}>
                <Grid container justifyContent="start">
                    <Box sx={{ width: '100%', pt: 2, pb: 4, px: 2 }}>
                        <Typography component="h2" variant="h3" align="center" color="text.primary" sx={{ whiteSpace: 'pre-line', mb: 4 }}>
                            {t("Training.DotNet.Public.Title")}
                        </Typography>
                        <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                            {t("Training.DotNet.Public.Text1")}
                        </Typography>
                        <Box display="flex" sx={{ whiteSpace: 'pre-line', my: 2 }}>
                            <PersonIcon />
                            <Typography variant="body1" sx={{ ml: 1 }}>
                                {t("Training.DotNet.Public.Item1")}
                            </Typography>
                        </Box>
                        <Box display="flex" sx={{ whiteSpace: 'pre-line', my: 2 }}>
                            <PersonIcon />
                            <Typography variant="body1" sx={{ ml: 1 }}>
                                {t("Training.DotNet.Public.Item2")}
                            </Typography>
                        </Box>
                        <Box display="flex" sx={{ whiteSpace: 'pre-line', my: 2 }}>
                            <PersonIcon />
                            <Typography variant="body1" sx={{ ml: 1 }}>
                                {t("Training.DotNet.Public.Item3")}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Box>

            {/* Training organization */}
            <Box sx={{ maxWidth: "md", margin: 'auto', pt: 0, px: 2 }}>
                <Grid container justifyContent="start">
                    <Box sx={{ width: '100%', pt: 2, pb: 4, px: 2 }}>
                        <Typography component="h2" variant="h3" align="center" color="text.primary" sx={{ whiteSpace: 'pre-line', mb: 4 }}>
                            {t("Training.DotNet.Organization.Title")}
                        </Typography>
                        <Box display="flex" sx={{ whiteSpace: 'pre-line', my: 2 }}>
                            <AccessTimeIcon />
                            <Typography variant="body1" sx={{ ml: 1 }}>
                                {t("Training.DotNet.Organization.Item1")}
                            </Typography>
                        </Box>
                        <Box display="flex" sx={{ whiteSpace: 'pre-line', my: 2 }}>
                            <AccessTimeIcon />
                            <Typography variant="body1" sx={{ ml: 1 }}>
                                {t("Training.DotNet.Organization.Item2")}
                            </Typography>
                        </Box>
                        <Box display="flex" sx={{ whiteSpace: 'pre-line', my: 2 }}>
                            <AccessTimeIcon />
                            <Typography variant="body1" sx={{ ml: 1 }}>
                                {t("Training.DotNet.Organization.Item3")}
                            </Typography>
                        </Box>
                        <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                            {t("Training.DotNet.Organization.Details")}
                        </Typography>
                    </Box>
                </Grid>
            </Box>
            
            {/* Training Program */}
            <Box sx={{ maxWidth: "md", margin: 'auto', pt: 0, px: 2 }}>
                <Grid container justifyContent="start">
                    <Box sx={{ width: '100%', pt: 2, pb: 4, px: 2 }}>
                        <Typography component="h2" variant="h3" align="center" color="text.primary" sx={{ whiteSpace: 'pre-line', mb: 4 }}>
                            {t("Training.DotNet.Program.Title")}
                        </Typography>

                        {chaptersArray.map((chapter, index) => {
                            const chapterContentArray = Array.isArray(chapter.Content) ? chapter.Content : [];
                            return (
                                <Accordion
                                    key={index}
                                    expanded={expanded[index]}
                                    onChange={handleExpandedChange(index)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${index}a-content`}
                                        id={`panel${index}a-header`}>
                                        <Typography component="h2" variant="h4">{chapter.Title}</Typography>
                                    </AccordionSummary>
                                    <Divider></Divider>
                                    <AccordionDetails>
                                        <List>
                                            {chapterContentArray.map((item, itemIndex) => (
                                                <ListItem key={itemIndex} sx={{ py: 0.2 }}>
                                                    <Box component="span" sx={listItemPointStyle} />
                                                    <ListItemText primary={item} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                    </Box>
                </Grid>
            </Box>

            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={2} mb={4}>
                <Typography component="h2" variant="h3" color="text.primary">{t("Training.KnowMore")}</Typography>
                <Box mt={2} display="inline-flex" justifyContent="center">
                    <Box mx={1}>
                        <Button variant="contained" color="primary" href={`${languagePrefix}/contact`}>
                            {t("Training.Contact")}
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Footer />
        </main>
    );
}
