import React from 'react';
import './LevitatingImage.css';

const LevitatingImage = ({ imageUrl, alt, ratio, delay, yTranslation, duration }) => {
  const animationStyle = {
    animationDelay: delay,
    animationDuration: duration,
    maxWidth: ratio,
    maxHeight: ratio,
  };

  const keyframesStyle = `
    @keyframes levitate {
      0% { transform: translateY(${yTranslation * -1}px); }
      50% { transform: translateY(${yTranslation}px); }
      100% { transform: translateY(${yTranslation * -1}px); }
    }
  `;

  return (
    <>
      <style>
        {keyframesStyle}
      </style>
      <img
        src={imageUrl}
        alt={alt}
        style={animationStyle}
        className="levitate"
      />
    </>
  );
};

export default LevitatingImage;
