import * as React from 'react';
import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogContent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { json, useNavigate } from "react-router-dom";
import { UseUser } from '../../../services/user/UserContext';
import AppDrawer from '../../drawer/AppDrawer';
import { UseAxiosInstance } from '../../../services/aws/api/AxiosInstance';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLanguagePrefix } from '../../translation/LanguageUrlHandler';
import { base64ToByteArray } from '../../../utilities/TypeConversion';

export default function CompanyPage() {
    const theme = useTheme();
    const { t } = useTranslation();
    const { currentUser } = UseUser();
    const axiosInstance = UseAxiosInstance(currentUser);
    const navigate = useNavigate();
    const [company, setCompany] = useState([]);
    const [companyUsers, setCompanyUsers] = useState([]);
    const [inviteUserPopupOpen, setInviteUserPopupOpen] = useState(false);
    const [companyMetaLoading, setCompanyMetaLoading] = useState(true);
    const [companyUsersLoading, setCompanyUsersLoading] = useState(true);
    const [actionLoading, setActionLoading] = useState(false);
    const [actionError, setActionError] = useState("");
    const [companyImageSrc, setCompanyImageSrc] = useState(null);
    const [selectedRole, setSelectedRole] = React.useState('User');
    const [anchorEl, setAnchorEl] = useState(null);
    const [changeRoleDialogOpen, setChangeRoleDialogOpen] = useState(false);
    const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);
    const [confirmationText, setConfirmationText] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const isDeletionDisabled = selectedUser ? confirmationText !== selectedUser.email : true;
    const [newRole, setNewRole] = useState('User');
    const location = useLocation();
    const languagePrefix = getLanguagePrefix(location.pathname);

    useEffect(() => {
        setCompanyMetaLoading(true);
        setCompanyUsersLoading(true);
        if (!currentUser || !axiosInstance) return;

        const fetchCompanyMeta = async () => {
            try {
                const companyResponse = await axiosInstance.get(`/companies/${currentUser.company}`);
                const companyMeta = JSON.parse(companyResponse.data);
                if (companyMeta) {
                    setCompany(companyMeta);

                    if (companyMeta.imageUrl) {
                        const imageUrlResponse = await axiosInstance.get(`/fetch?url=${encodeURIComponent(companyMeta.imageUrl)}`);
                        const responseData = imageUrlResponse.data;
                        const byteArray = base64ToByteArray(responseData.data);

                        const imageBlob = new Blob([byteArray], { type: responseData.contentType });
                        const localImageUrl = URL.createObjectURL(imageBlob);
                        setCompanyImageSrc(localImageUrl);
                    }
                }
            } catch (err) {
                console.error("Failed to fetch company metadata:", err);
            } finally {
                setCompanyMetaLoading(false);
            }
        };

        if (currentUser.company) {
            fetchCompanyMeta();
            if (currentUser.role !== 'User') {
                fetchCompanyUsers();
            }
        }
        else {
            setCompanyMetaLoading(false);
            setCompanyUsersLoading(false);
        }
    }, [currentUser, axiosInstance]);

    const fetchCompanyUsers = async () => {
        try {
            const companyUsersResponse = await axiosInstance.get(`/companies/${currentUser.company}/users`);
            const companyUsersData = JSON.parse(companyUsersResponse.data);
            const filteredUsers = currentUser.role === "Root" ? companyUsersData : companyUsersData.filter(user => user.role !== "Root");
            setCompanyUsers(filteredUsers)
        } catch (err) {
            console.error("Failed to fetch company users:", err);
        }

        setCompanyUsersLoading(false);
    };

    const handleInviteUserPopupOpen = () => {
        setInviteUserPopupOpen(true);
    };

    const handleInviteUserPopupClose = () => {
        setInviteUserPopupOpen(false);
    };

    const handleInvite = async (email, role) => {
        setActionLoading(true);
        setActionError("");

        if (company?.validationRegex) {
            const validationRegex = new RegExp(company.validationRegex);
            if (!validationRegex.test(email)) {
                setActionError("Invalid email format for this company.");
                setActionLoading(false);
                return;
            }
        }

        try {
            await axiosInstance.post(`/companies/${currentUser.company}/invitations`, {
                email: email,
                role: role
            });

            fetchCompanyUsers();
            handleInviteUserPopupClose();
        } catch (error) {
            console.log(error);
            setActionError("Failed to invite the user. Please try again.");
        }

        setActionLoading(false);
    };

    const handleMenuOpen = (event, user) => {
        setAnchorEl(event.currentTarget);
        setSelectedUser(user);
        setNewRole(user.role);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleChangeRole = async () => {
        setActionLoading(true);
        setActionError("");

        try {
            const username = selectedUser.SK.split('#')[1];
            await axiosInstance.patch(`/users/${username}/profile`, {
                role: newRole
            });

            fetchCompanyUsers();
            setChangeRoleDialogOpen(false);
        } catch (error) {
            console.log(error);
            setActionError("Failed to invite the user. Please try again.");
        }

        setActionLoading(false);
    };

    const handleConfirmationTextChange = (event) => {
        setConfirmationText(event.target.value);
    };

    const handleDeleteDialogClose = () => {
        setConfirmationText("");
        setDeleteUserDialogOpen(false);
    };

    const handleDeleteUser = async () => {
        setActionLoading(true);
        setActionError("");
        try {
            const username = selectedUser.SK.split('#')[1];
            await axiosInstance.delete(`/users/${username}`);

            fetchCompanyUsers();
            handleDeleteDialogClose();
        } catch (error) {
            console.log(error);
            setActionError("Failed to delete the user. Please try again.");
        }
        setActionLoading(false);
    };

    const getChipStyle = (status) => {
        switch (status) {
            case 'Active':
                return { background: '#c9f9d9', color: '#287e46' };
            case 'Invited':
                return { background: '#cdd7fc', color: '#423cb1' };
            case 'Banned':
                return { background: '#fed2d2', color: '#aa2c28' };
            default:
                return {};
        }
    };

    if (currentUser == null) {
        return;
    }

    return (
        <Box sx={{ display: "flex" }}>
            <AppDrawer></AppDrawer>
            <Box sx={{ overflow: "auto", bgcolor: '#f2f5f9', minHeight: 'calc(100vh - 64px)', padding: 2 }}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <Typography variant="h4" color='text.primary' sx={{ mb: 2 }}>Company</Typography>
                    <Box >
                        <Paper elevation={1} sx={{ padding: 2, marginBottom: 2, display: 'flex', alignItems: 'center', borderRadius: 2 }}>
                            {companyMetaLoading ? (
                                <>
                                    <Skeleton variant="circular" width={96} height={96} sx={{ marginRight: '16px' }} />
                                    <Skeleton variant="text" sx={{ width: { xs: 100, md: 200 } }} height={40} />
                                </>
                            ) : (
                                <>
                                    <img
                                        src={companyImageSrc}
                                        alt={company.name}
                                        style={{
                                            maxHeight: '96px',
                                            maxWidth: '600px',
                                            marginRight: '16px'
                                        }}
                                    />
                                    <Typography variant="h4" color="text.primary" fontWeight={400}>{company.name}</Typography>
                                </>
                            )}
                        </Paper>

                        {currentUser.role !== 'User' &&
                            <Paper elevation={1} sx={{ padding: 2, marginBottom: 2, borderRadius: 2 }}>
                                <Button variant="contained" color="primary" onClick={handleInviteUserPopupOpen}>
                                    Invite New User
                                </Button>

                                <Box sx={{ overflow: "auto" }}>
                                    <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>User email</TableCell>
                                                    <TableCell>First name</TableCell>
                                                    <TableCell>Last name</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Role</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {companyUsersLoading ? (
                                                    <>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Skeleton variant="text" width="80%" height={40} />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton variant="text" width="60%" height={40} />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton variant="text" width="60%" height={40} />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton variant="text" width="60%" height={40} />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton variant="text" width="60%" height={40} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Skeleton variant="text" width="80%" height={40} />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton variant="text" width="60%" height={40} />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton variant="text" width="60%" height={40} />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton variant="text" width="60%" height={40} />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton variant="text" width="60%" height={40} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                ) : (
                                                    companyUsers.map(user => (
                                                        <TableRow key={user.SK} >
                                                            <TableCell component="th" scope="row">{user.email}</TableCell>
                                                            <TableCell>{user.firstName ?? '-'}</TableCell>
                                                            <TableCell>{user.lastName ?? '-'}</TableCell>
                                                            <TableCell>
                                                                <Chip
                                                                    label={user.status}
                                                                    style={getChipStyle(user.status)}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                {user.role}
                                                            </TableCell>

                                                            <TableCell>
                                                                <IconButton
                                                                    aria-label="more"
                                                                    aria-controls="long-menu"
                                                                    aria-haspopup="true"
                                                                    onClick={(event) => handleMenuOpen(event, user)}>
                                                                    <MoreVertIcon />
                                                                </IconButton>
                                                                <Menu
                                                                    id="long-menu"
                                                                    anchorEl={anchorEl}
                                                                    keepMounted
                                                                    open={Boolean(anchorEl)}
                                                                    onClose={handleMenuClose}>
                                                                    <MenuItem onClick={() => {
                                                                        handleMenuClose();
                                                                        setChangeRoleDialogOpen(true);
                                                                    }}>
                                                                        Change Role
                                                                    </MenuItem>
                                                                    <MenuItem onClick={() => {
                                                                        handleMenuClose();
                                                                        setDeleteUserDialogOpen(true);
                                                                    }}>
                                                                        Delete User
                                                                    </MenuItem>
                                                                </Menu>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </Box>
                            </Paper>
                        }

                        <Dialog open={inviteUserPopupOpen} onClose={handleInviteUserPopupClose}>
                            <DialogTitle variant="h5" color="text.primary" fontWeight={500}>Invite new user</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    The user will receive an email allowing him to create an account and join {company.name} company.
                                </DialogContentText>
                                <TextField
                                    sx={{ mt: 2 }}
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                    variant="standard"
                                />
                                <FormControl variant="standard" fullWidth margin="dense" sx={{ mt: 2 }}>
                                    <InputLabel id="role-label">Role</InputLabel>
                                    <Select
                                        labelId="role-label"
                                        id="role"
                                        value={selectedRole}
                                        onChange={(event) => setSelectedRole(event.target.value)}>
                                        <MenuItem value="User">User</MenuItem>
                                        <MenuItem value="Admin">Admin</MenuItem>
                                    </Select>
                                </FormControl>
                                {actionError && (
                                    <Typography color="error" variant="body2" style={{ margin: '0 0 10px' }}>
                                        {actionError}
                                    </Typography>
                                )}
                                {actionLoading && (
                                    <CircularProgress size={24} style={{ margin: '10px' }} />
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleInviteUserPopupClose}>Cancel</Button>
                                <Button onClick={() => handleInvite(document.querySelector('input[type="email"]').value, selectedRole)}>Invite</Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={changeRoleDialogOpen} onClose={() => setChangeRoleDialogOpen(false)}>
                            <DialogTitle color='text.primary' sx={{ mt: 1, mr: 3, mb: 2 }} >Change role of {selectedUser?.email}</DialogTitle>
                            <DialogContent>
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="change-role-label">Role</InputLabel>
                                    <Select
                                        labelId="change-role-label"
                                        value={newRole}
                                        onChange={(event) => setNewRole(event.target.value)}>
                                        <MenuItem value="User">User</MenuItem>
                                        <MenuItem value="Admin">Admin</MenuItem>
                                    </Select>
                                </FormControl>
                                {actionError && (
                                    <Typography color="error" variant="body2" style={{ margin: '0 0 10px' }}>
                                        {actionError}
                                    </Typography>
                                )}
                                {actionLoading && (
                                    <CircularProgress size={24} style={{ margin: '10px' }} />
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setChangeRoleDialogOpen(false)}>Cancel</Button>
                                <Button onClick={handleChangeRole}>Apply</Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={deleteUserDialogOpen} onClose={() => handleDeleteDialogClose()}>
                            <DialogTitle color='text.primary' sx={{ mt: 1, mr: 3, mb: 2 }} >Delete User ? </DialogTitle>
                            <DialogContent>
                                <FormControl>
                                    <DialogContentText sx={{ mb: 0, color: 'black' }}>
                                        Delete user <strong>{selectedUser?.email}</strong> permanently?<br />
                                        This action cannot be undone.<br /><br />
                                        To confirm deletion, type the user email in the field below:
                                    </DialogContentText>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        fullWidth
                                        variant="standard"
                                        value={confirmationText}
                                        autoComplete="off"
                                        onChange={handleConfirmationTextChange}
                                        sx={{ mb: 1 }}
                                    />
                                    {actionError && (
                                        <Typography color="error" variant="body2" style={{ margin: '0 0 10px' }}>
                                            {actionError}
                                        </Typography>
                                    )}
                                    {actionLoading && (
                                        <CircularProgress size={24} style={{ margin: '10px' }} />
                                    )}
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleDeleteDialogClose()}>Cancel</Button>
                                <Button onClick={handleDeleteUser} disabled={isDeletionDisabled}>Delete</Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                </Box>
            </Box>
        </Box >
    );
}