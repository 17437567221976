import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Img } from 'react-image';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Footer from '../../footer/Footer';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CertificateIcon from '@mui/icons-material/WorkspacePremium';
import MemoryIcon from '@mui/icons-material/Memory';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { useNavigationType, useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import { getLanguagePrefix } from '../../translation/LanguageUrlHandler';

import { default as TrainingImageAvif } from '../../images/home/offers/training.avif';
import { default as TrainingImage } from '../../images/home/offers/training.png';

import { default as CSharpImage } from '../../images/training/c-sharp-logo.svg';
import { default as AvevaImage } from '../../images/training/Aveva.svg';
import { default as SpecificImage } from '../../images/training/Fine-Tuning.svg';

export default function Training() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigationType = useNavigationType();
  const location = useLocation();
  const navigate = useNavigate()
  const languagePrefix = getLanguagePrefix(location.pathname);
  const targetOrigin = window.location.origin.replace("localhost", "app.localhost").replace("ingeloop", "app.ingeloop");
  const targetPage = targetOrigin + "";
  const [isYearly, setIsYearly] = useState(true);

  useEffect(() => {
    if (navigationType === "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [navigationType]);

  const trainingData = [
    {
      "key": "DotNet",
      "image": CSharpImage,
      "title": t("Training.DotNet.Title"),
      "description": t("Training.DotNet.Description"),
      "duration": t("Training.DotNet.Duration"),
      "content": t("Training.DotNet.Content"),
      "link": 'dotnet'
    },
    {
      "key": "PML",
      "image": AvevaImage,
      "title": t("Training.PML.Title"),
      "description": t("Training.PML.Description"),
      "duration": t("Training.PML.Duration"),
      "content": t("Training.PML.Content"),
      "link": 'pml'
    },
    {
      "key": "Specific",
      "image": SpecificImage,
      "title": t("Training.Specific.Title"),
      "description": t("Training.Specific.Description"),
      "duration": t("Training.Specific.Duration"),
      "content": t("Training.Specific.Content"),
      "link": ''
    },
  ];

  const trainingConcepts = [
    {
      "key": "AppliedExercises",
      "icon": ViewInArIcon,
      "title": t("Training.AppliedExercises.Title"),
      "description": t("Training.AppliedExercises.Description")
    },
    {
      "key": "CompleteVision",
      "icon": MemoryIcon,
      "title": t("Training.CompleteVision.Title"),
      "description": t("Training.CompleteVision.Description")
    },
    {
      "key": "Expertise",
      "icon": WorkspacePremiumIcon,
      "title": t("Training.Expertise.Title"),
      "description": t("Training.Expertise.Description")
    },
  ];

  return (
    <main>
      <Helmet>
        <title>{t("Meta.Training.Title")}</title>
        <meta name="description" content={t("Meta.Training.Description")} />
      </Helmet>

      <Grid container justifyContent="center">
        <Grid container justifyContent="center">

          {/* Hero section */}
          <Box
            color="secondary.main"
            sx={{ backgroundColor: 'white', height: 'calc(100vh - 54px)', maxWidth: "xl", display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, justifyContent: 'center' }}>
            <Box sx={{
              maxWidth: { xs: '100%', lg: '50%' },
              flexShrink: 0, p: 2, m: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Typography variant="h1" color="text.primary" sx={{ mb: 2, p: [2, 0], ml: [0, 6], mt: 1, textAlign: 'center' }}>
                {t("Training.Title")}
              </Typography>
              <Typography component="h2" variant="h4" color="text.secondary" sx={{ mb: 2, px: [2, 0], ml: [0, 6], textAlign: 'center' }} style={{ whiteSpace: 'pre-line' }}>
                {t("Training.Subtitle")}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, p: 2, m: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
              <Img src={[TrainingImageAvif, TrainingImage]} alt="Digital Plant" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }} />
            </Box>
          </Box>

          {/* Training Description */}
          <Box sx={{ backgroundColor: "#fafafa", width: '100%' }}>
            <Grid container justifyContent="center">
              <Box sx={{ maxWidth: "lg", pt: 4, pb: 4, px: 5 }}>
                <Typography variant="h2" align="center" color="text.primary" sx={{ whiteSpace: 'pre-line', mb: 4 }}>
                  {t("Training.Presentation1Title")}
                </Typography>
                <Typography variant="body1" align="justify" sx={{ whiteSpace: 'pre-line' }}>
                  {t("Training.Presentation1Content")}
                </Typography>
                <Typography variant="body1" align="justify" sx={{ whiteSpace: 'pre-line', my: 2 }}>
                  {t("Training.Presentation2Content")}
                </Typography>
                <Box display="flex" alignItems="center" sx={{ whiteSpace: 'pre-line', my: 1 }}>
                  <ArrowRightAltIcon color="secondary" />
                  <Typography variant="body1" align="justify" sx={{ ml: 1 }}>
                    {t("Training.PresentationList1")}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" sx={{ whiteSpace: 'pre-line', my: 1 }}>
                  <ArrowRightAltIcon color="secondary" />
                  <Typography variant="body1" align="justify" sx={{ ml: 1 }}>
                    {t("Training.PresentationList2")}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" sx={{ whiteSpace: 'pre-line', my: 1 }}>
                  <ArrowRightAltIcon color="secondary" />
                  <Typography variant="body1" align="justify" sx={{ ml: 1 }}>
                    {t("Training.PresentationList3")}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>

          {/* Trainings concepts */}
          <Box sx={{ width: '100%' }}>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Typography variant="h2" align="center" color="text.primary" sx={{ mt: 4, mb: 4 }} gutterBottom>
                {t("Training.ConceptTitle")}
              </Typography>
              <Grid container spacing={4}>
                {trainingConcepts.map((trainingConcept) => (
                  <Grid item key={trainingConcept.key} xs={12} sm={6} md={4}>
                    <Card
                      sx={{
                        height: '100%',
                        position: 'relative'
                      }}>
                      <CardMedia
                        component="div"
                        sx={{
                          p: 4,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '160px',
                        }}
                      >
                        <trainingConcept.icon style={{ fontSize: 96, color: '#e0691f' }} />
                      </CardMedia>

                      <CardContent sx={{ flexGrow: 1, paddingBottom: '30px' }}>
                        <Typography gutterBottom variant="h4" component="h2" color="text.primary">
                          {trainingConcept.title}
                        </Typography>
                        <Typography gutterBottom variant="h6" component="h3">
                          {trainingConcept.description}
                        </Typography>
                      </CardContent>

                      <Box sx={{
                        position: 'absolute',
                        bottom: '0px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        padding: '8px'
                      }}>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>

          {/* Trainings Section */}
          <Box sx={{ backgroundColor: "#fafafa", width: '100%' }}>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 2 }}>
              <Typography variant="h2" align="center" color="text.primary" sx={{ mt: 4, mb: 4 }} gutterBottom>
                {t("Training.TrainingListTitle")}
              </Typography>
              <Grid container spacing={4}>
                {trainingData.map((training) => (
                  <Grid item key={training.key} xs={12} sm={6} md={4}>
                    <Card
                      sx={{
                        height: '100%',
                        position: 'relative',
                        '&:hover': {
                          boxShadow: '3',
                        },
                      }}>
                      <CardActionArea
                        sx={{ position: 'relative', height: '100%' }}
                        component={Link}
                        href={training.link ? `${languagePrefix}/training/${training.link}` : `${languagePrefix}/contact`}
                        style={{ textDecoration: 'none' }}>
                        <CardMedia
                          component="div"
                          sx={{
                            p: 4,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '160px',
                          }}
                        >
                          <img
                            src={training.image}
                            alt={training.title}
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'contain',
                            }}
                          />
                        </CardMedia>

                        <CardContent sx={{ flexGrow: 1, paddingBottom: '50px' }}>
                          <Typography gutterBottom variant="h4" component="h2" color="text.primary">
                            {training.title}
                          </Typography>
                          <Typography gutterBottom variant="h6" component="h3">
                            {training.description}
                          </Typography>

                          <Chip
                            label={training.duration}
                            variant='Filled'
                            sx={{ background: theme.palette.primary.main, color: 'white', mt: 1 }}
                          />

                          <Chip
                            label={t("Training.BusinessesOnly")}
                            variant='Filled'
                            sx={{ color: 'black', ml: 1, mt: 1 }}
                          />

                          <Chip
                            label={t("Training.OnSiteOnly")}
                            variant='Filled'
                            sx={{ color: 'black', ml: 1, mt: 1 }}
                          />

                          <Typography align="left" paragraph sx={{ whiteSpace: 'pre-line', mt: 2 }}>
                            {training.content}
                          </Typography>
                        </CardContent>

                        <Box sx={{
                          position: 'absolute',
                          bottom: '0px',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          padding: '8px'
                        }}>
                          <Button size="small">{training.link ? t("Services.FindOutMore") : t("Services.Contact")}</Button>
                        </Box>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <Box textAlign="left">
                <Chip
                  icon={<CertificateIcon style={{ color: 'black' }} />}
                  label={t("Training.Organization.Title")}
                  variant='Filled'
                  component={Link}
                  href="https://annuaire-entreprises.data.gouv.fr/labels-certificats/908783400#organisme-de-formation"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ mt: 3, color: 'black' }}
                />
              </Box>
            </Container>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </main>
  );
}
