import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { NavLink, useNavigationType, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { getLanguagePrefix } from '../../translation/LanguageUrlHandler';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Footer from '../../footer/Footer';
import ProductsData from './Products.json'

import NavisworksConnector from '../../images/products/NavisworksConnector.gif'
import DBComparator from '../../images/products/DBComparator.gif'
import BCFNode from '../../images/products/BCFNode.gif'
import SupportManager from '../../images/products/SupportManager.gif'
import ModelSwap from '../../images/products/ModelSwap.gif'

export default function Products() {
  const { t } = useTranslation();
  const navigationType = useNavigationType();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const location = useLocation();
  const languagePrefix = getLanguagePrefix(location.pathname);
  const targetOrigin = window.location.origin.replace("www.", "").replace("localhost", "app.localhost").replace("ingeloop", "app.ingeloop");
  const appUrl = targetOrigin + languagePrefix;

  useEffect(() => {
    if (navigationType === "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [navigationType]);

  const images = {
    NavisworksConnector,
    DBComparator,
    BCFNode,
    SupportManager,
    ModelSwap
  };

  return (
    <main>
      <Helmet>
        <title>{t("Meta.Products.Title")}</title>
        <meta name="description" content={t("Meta.Products.Description")} />
      </Helmet>

      {/* Hero unit */}
      <Box sx={{ pt: 8, pb: 6, px: 4 }}>
        <Container maxWidth="md">
          <Typography
            variant="h1"
            color="text.primary"
            align="center"
            gutterBottom>
            {t("Products.Title")}
          </Typography>
          <Typography component="h2" variant="h5" align="center" color="text.secondary" paragraph sx={{ lineHeight: 1.4 }}>
            {t("Products.Subtitle")}
          </Typography>
          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center">
            <Button
              disabled={false}
              href={appUrl} target="_blank"
              variant="contained">
              {t("Products.Button")}
            </Button>
          </Stack>
        </Container>
      </Box>
      <Container sx={{ pt: 2, pb: 12 }} maxWidth="lg">
        <Grid container spacing={4}>
          {ProductsData.filter(product => product.isVisible).map((product) => (
            <Grid item key={product.title} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  height: '100%',
                  position: 'relative',
                  '&:hover': {
                    boxShadow: '3',
                  },
                }}>
                <CardActionArea
                  sx={{ position: 'relative', height: '100%' }}
                  component={Link}
                  href={`${languagePrefix}/products/${product.link}`}
                  style={{ textDecoration: 'none' }}
                  onClick={(e) => {
                    if (!product.isAvailable) {
                      e.preventDefault();
                    }
                  }}>
                  <CardMedia
                    component="div"
                    sx={{
                      // 16:9
                      pt: '56.25%',
                    }}
                    image={images[product.image]}
                  />
                  <CardContent sx={{ flexGrow: 1, paddingBottom: '50px' }}>
                    <Typography gutterBottom variant="h5" component="h2" color="text.primary">
                      {t(product.title)}
                    </Typography>
                    <Typography>
                      {t(product.description)}
                    </Typography>
                  </CardContent>

                  <Box sx={{
                    position: 'absolute',
                    bottom: '0px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    padding: '8px'
                  }}>
                    {product.isAvailable ? (
                      <Button size="small">{t("Products.ViewButton")}</Button>
                    ) : (
                      <Chip label={t("Products.ComingSoonButton")} color="primary" />
                    )}
                  </Box>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Footer></Footer>
    </main>
  );
}