import axios from 'axios';
import awsConfig from '../awsConfig';
import { useMemo } from 'react';

export const UseAxiosInstance = (user = null) => {
    const axiosInstance = useMemo(() => {
        return axios.create({
            baseURL: awsConfig.api.invokeUrl,
            timeout: 5000,
            headers: user?.idToken ? {'Authorization': user.idToken} : {}
        });
    }, [user?.idToken]);

    return axiosInstance;
};
