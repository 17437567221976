import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { json, useNavigate } from "react-router-dom";
import { UseUser } from '../../../services/user/UserContext';
import AppDrawer from '../../drawer/AppDrawer';
import { UseAxiosInstance } from '../../../services/aws/api/AxiosInstance';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLanguagePrefix } from '../../translation/LanguageUrlHandler';

export default function ProductsPage() {
    const theme = useTheme();
    const { t } = useTranslation();
    const { currentUser } = UseUser();
    const axiosInstance = UseAxiosInstance(currentUser);
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const languagePrefix = getLanguagePrefix(location.pathname);

    useEffect(() => {
        setLoading(true);
        if (!currentUser || !axiosInstance) return;
        const fetchProducts = async () => {
            try {
                const response = await axiosInstance.get(`/products`);
                const products = JSON.parse(response.data);
                setProducts(products);
                setLoading(false);
            } catch (err) {
                console.error("Failed to fetch products:", err);
                setLoading(false);
            }
        };
        fetchProducts();
    }, [currentUser, axiosInstance]);

    const handleRowClick = (productId) => {
        navigate(`${languagePrefix}/products/product?id=${productId}`);
    };

    if (currentUser == null) {
        return;
    }

    return (
        <Box sx={{ display: "flex" }}>
            <AppDrawer></AppDrawer>
            <Box sx={{ overflow: "auto", bgcolor: '#f2f5f9', minHeight: 'calc(100vh - 64px)', padding: 2, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <Typography variant="h4" color='text.primary' sx={{ mb: 2 }}>My Products</Typography>
                    <Box>
                        {loading ? (
                            <CircularProgress
                                size={36}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        ) : (
                            <>
                                {products?.length > 0 &&
                                    <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                                        <Paper elevation={1} sx={{ padding: 2, marginBottom: 2, borderRadius: 2 }}>
                                            <Box sx={{ overflow: "auto" }}>
                                                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Product</TableCell>
                                                                <TableCell>Last Version</TableCell>
                                                                <TableCell>Last Release</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {products.map((product) => {
                                                                const productId = product.SK?.split('#')[1];
                                                                const productName = product.name;
                                                                const lastVersion = product.lastVersion;
                                                                const lastRelease = product.lastReleaseDate == null ? "-" : new Date(product.lastReleaseDate).toLocaleDateString();
                                                                return (
                                                                    <TableRow
                                                                        key={productId}
                                                                        onClick={() => handleRowClick(productId)}
                                                                        hover
                                                                        sx={{ cursor: 'pointer' }}
                                                                    >
                                                                        <TableCell component="th" scope="row">{productName}</TableCell>
                                                                        <TableCell>{lastVersion}</TableCell>
                                                                        <TableCell>{lastRelease}</TableCell>
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </Box>
                                }

                                <Paper elevation={1} sx={{ padding: 3, mt: 2, borderRadius: 2, textAlign: 'center' }}>
                                    <Typography variant="h4" color='text.primary' gutterBottom>
                                        Discover all Products!
                                    </Typography>
                                    <Typography variant="body1" color='text.primary' sx={{ mb: 2 }}>
                                        Explore the full range of products and find the perfect fit for you.
                                    </Typography>
                                    <Button variant="contained" color="primary" size="large" href={`https://www.ingeloop.com${languagePrefix}/products`} target="_blank">
                                        Explore Now
                                    </Button>
                                </Paper>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}