import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from './Copyright'
import { UseAxiosInstance } from '../../../services/aws/api/AxiosInstance';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useLocation } from "react-router-dom";
import { ConfirmPassword, CompletePasswordChallenge, AuthenticateUser } from '../../../services/aws/cognito/Authentication';
import { validateFirstName, validateLastName, validatePassword, validateConfirmPassword } from '../../../utilities/InputCheckUtilities';
import { useTranslation } from 'react-i18next';
import { getLanguagePrefix } from '../../translation/LanguageUrlHandler';

export default function ChangePassword() {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const axiosInstance = UseAxiosInstance();
    const location = useLocation();
    const mode = location.state.changePasswordMode;
    const session = location.state.session;
    const username = location.state.username;
    const email = location.state.email;
    const otp = location.state.otp;
    const [loading, setLoading] = React.useState(false);
    const [backendError, setBackendError] = React.useState("");
    const languagePrefix = getLanguagePrefix(location.pathname);

    const [errors, setErrors] = React.useState({
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: ""
    });

    const handleInputChange = (field, value) => {
        let fieldError = '';

        switch (field) {
            case 'firstName':
                fieldError = validateFirstName(value);
                break;
            case 'lastName':
                fieldError = validateLastName(value);
                break;
            case 'password':
                fieldError = validatePassword(value);
                break;
            case 'confirmPassword':
                const passwordValue = document.getElementById("password").value;
                fieldError = validateConfirmPassword(value, passwordValue);
                break;
            default:
                break;
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            [field]: fieldError
        }));
    }

    const validate = (data) => {
        let tempErrors = {
            password: validatePassword(data.password),
            confirmPassword: validateConfirmPassword(data.confirmPassword, data.password),
        };

        if (mode === "NewPasswordChallenge") {
            tempErrors.firstName = validateFirstName(data.firstName);
            tempErrors.lastName = validateLastName(data.lastName);
        }

        setErrors(tempErrors);

        return Object.values(tempErrors).every(error => !error); // return true if no errors
    };

    const handleSubmit = async (event) => {
        if (loading) return;

        setLoading(true);

        event.preventDefault();
        const data = new FormData(event.currentTarget);

        var formData = {
            firstName: data.get('firstName'),
            lastName: data.get('lastName'),
            password: data.get('password'),
            confirmPassword: data.get('confirmPassword'),
        };

        if (!validate(formData)) {
            setLoading(false);
            return;
        }

        try {
            if (mode === "NewPasswordChallenge") {

                const attributesData = {
                    'given_name': formData.firstName,
                    'family_name': formData.lastName,
                };

                await CompletePasswordChallenge(email, formData.password, session, attributesData);

                // Authenticate the user to get a JWT
                const userAuthDetails = await AuthenticateUser(email, formData.password);
                axiosInstance.defaults.headers['Authorization'] = userAuthDetails?.idToken?.jwtToken;

                const userData = { firstName: formData.firstName, lastName: formData.lastName, status: 'Active' };
                await axiosInstance.patch(`/users/${username}/profile`, userData);

            } else if (mode === "UserRequired") {

                await ConfirmPassword(email, otp, formData.password);

                // Authenticate the user
                await AuthenticateUser(email, formData.password);

            } else {
                return;
            }

            // Navigate to Home after successful authentication
            navigate(`${languagePrefix}/`);
        } catch (err) {
            if (err.code === "CodeMismatchException") {
                navigate(`${languagePrefix}/auth/verify-account`, { state: { email: email, otpLength: 6, mode: 'LostPassword' } });
            } else {
                setBackendError(err.message);
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5" color="secondary.main" sx={{ mt: 2 }}>
                        {mode === "NewPasswordChallenge" ? t("Forms.CompleteRegistration") : t("Forms.ChangePassword")}
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            {mode === "NewPasswordChallenge" &&
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"
                                            required
                                            fullWidth
                                            id="firstName"
                                            label={t("Forms.FirstName")}
                                            autoFocus
                                            onChange={(e) => handleInputChange('firstName', e.target.value)}
                                            error={Boolean(errors.firstName)}
                                            helperText={errors.firstName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="lastName"
                                            label={t("Forms.LastName")}
                                            name="lastName"
                                            autoComplete="family-name"
                                            onChange={(e) => handleInputChange('lastName', e.target.value)}
                                            error={Boolean(errors.lastName)}
                                            helperText={errors.lastName}
                                        />
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label={t("Forms.NewPassword")}
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    onChange={(e) => handleInputChange('password', e.target.value)}
                                    error={Boolean(errors.password)}
                                    helperText={errors.password}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="confirmPassword"
                                    label={t("Forms.ConfirmPassword")}
                                    type="password"
                                    id="confirmPassword"
                                    autoComplete="new-password"
                                    onChange={(e) => handleInputChange('confirmPassword', e.target.value)}
                                    error={Boolean(errors.confirmPassword)}
                                    helperText={errors.confirmPassword}
                                />
                            </Grid>
                        </Grid>

                        {backendError && (
                            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                                {backendError}
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={loading}
                            sx={{ mt: 2, mb: 2 }}
                        >
                            {t("Forms.Submit")}
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={36}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </ThemeProvider>
    );
}